import React, { useEffect, useState } from 'react';
import './index.scss';
import SliderTabs from '@/components/slideeTabs';
import { useNavigate } from 'react-router-dom';
import api from '@/api/job';
import useInfiniteScroll from '@/hook/useInfiniteScroll';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

function MyApplications() {
    const navigate = useNavigate();
    const [selectedType, setSelectedType] = useState(1);
    const { dataBaseInfo } = useSelector((state: any) => state.state);
    const tabList = [
        { val: 'all', label: 'All', key: 1 },
        { val: 'applied', label: 'Applied', key: 2 },
        { val: 'inReview', label: 'In Review', key: 3 },
        { val: 'interviewScheduled', label: 'Interview Scheduled', key: 4 },
        { val: 'offerReceived', label: 'Offer Received', key: 5 },
        { val: 'rejected', label: 'Rejected', key: 6 },
    ];
    const chageStatus = (key: number) => {
        setSelectedType(key);
        setState({ ...state, pageNo: 1 });
        setJobList([]);
        getList({ ...state, status: tabList[key - 1].val, pageNo: 1 });
    };
    // list
    const [state, setState] = useState({
        search: '',
        pageSize: 20,
        pageNo: 1,
    });
    const [jobList, setJobList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const loadMoreItems = () => {
        if (loading || state.pageNo >= total) return;
        setLoading(true);
        getList({ ...state, pageNo: state.pageNo + 1 });
        setState({ ...state, pageNo: state.pageNo + 1 });
    };
    const loaderRef = useInfiniteScroll(loadMoreItems, loading);
    const [noMore, setNoMore] = useState(false);
    useEffect(() => {
        setNoMore(state.pageNo >= total);
    }, [state.pageNo]);
    const [total, setTotal] = useState(0);
    const [listLoading, setListLoading] = useState(false);
    const statusMap = {
        offerReceived: 'Offer Received',
        rejected: 'Rejected',
        interviewScheduled: 'Interview Scheduled',
        inReview: 'In Review',
        screening: 'In Review',
    };
    const getList = val => {
        setListLoading(true);
        const pat = {
            pageNo: val.pageNo,
            pageSize: val.pageSize,
            status: val.status || 'all',
        };
        api.getApplicationJobList(pat, res => {
            setListLoading(false);
            const { code, data, message } = res;
            if (code === 200) {
                setLoading(false);
                setTotal(res.data.totalPage);
                if (res.data.list) {
                    setJobList(prevItems => [
                        ...prevItems,
                        ...res.data.list.map(item => {
                            return Object.assign(item, {
                                pushTimeShow: item.jobsPOJO.createTime
                                    ? window.utils.foramtTimeDMY(item.jobsPOJO.createTime * 1000)
                                    : '-',
                                appliedTimeShow: item.applicationsPOJO.appliedTime
                                    ? window.utils.foramtTimeDMY(item.applicationsPOJO.appliedTime * 1000)
                                    : '-',
                                jobTypeShow:
                                    item.jobsPOJO.jobType === '1'
                                        ? 'Full-Time'
                                        : item.jobsPOJO.jobType === '2'
                                        ? 'Part-Time'
                                        : item.jobsPOJO.jobType === '3'
                                        ? 'Contractor'
                                        : 'Full-Time',
                                statusShow: statusMap[item.applicationsPOJO.applicationsStatus] || 'Applied',
                            });
                        }),
                    ]);
                } else {
                    setJobList([]);
                }
            } else {
                window.utils.alert('error', message);
            }
        });
    };
    useEffect(() => {
        setFirstFetch(true);
        getList({ ...state, status: 'all' });
    }, []);
    const toDetail = (val, i) => {
        window.sessionStorage.setItem('jobInfo', JSON.stringify({ ...val, from: 'application' }));
        navigate('/job-detail');
    };
    const [firstFetch, setFirstFetch] = useState(true);
    useEffect(() => {
        if (dataBaseInfo?.accountId && !firstFetch) {
            setJobList([]);
            getList({ ...state, status: tabList[selectedType - 1].val, pageNo: 1 });
        }
    }, [dataBaseInfo]);
    return (
        <div className="MyApplications-mainBox">
            <SliderTabs
                height={38}
                selectedType={selectedType}
                setSelectedType={(key: number) => {
                    chageStatus(key);
                }}
                tabList={tabList}
            />
            <div className="my-jobList">
                {listLoading ? (
                    <>
                        <div className="r-center no-message-tabBox">
                            <Spin style={{ position: 'static' }}></Spin>
                        </div>
                    </>
                ) : jobList.length ? (
                    <>
                        {jobList.map((val, i) => {
                            return (
                                <div key={`my-jobCard-${i}`} className="my-jobCardBox" onClick={() => toDetail(val, i)}>
                                    <div className="job-tit r-left">
                                        {val.jobsPOJO?.jobTitle || '-'}
                                        <span
                                            className={`status-box marLeft r-center ${
                                                val.applicationsPOJO.applicationsStatus === 'offerReceived'
                                                    ? 'offer-status'
                                                    : val.applicationsPOJO.applicationsStatus === 'rejected'
                                                    ? 'reject-status'
                                                    : ''
                                            }`}
                                        >
                                            {val.statusShow ?? 'Applied'}
                                        </span>
                                    </div>
                                    <div className="r-left mini-tipBox-job">
                                        <i
                                            className="jonInfo-icon"
                                            style={{
                                                backgroundImage: `url(${val.jobsPOJO.employerLogo})`,
                                                backgroundSize: 'cover',
                                            }}
                                        ></i>
                                        <span className="job-info-tip">{val.jobsPOJO.employerName || '-'}</span>
                                        <i className="round-spaceBox">•</i>
                                        <span className="job-info-tip">
                                            {val.jobsPOJO.remoteFlag
                                                ? 'Remote'
                                                : val.jobsPOJO.location
                                                ? val.jobsPOJO.location
                                                : '-'}
                                        </span>
                                        <i className="round-spaceBox">•</i>
                                        <span className="job-info-tip">{val.jobTypeShow || 'Full-Time'}</span>
                                        <i className="round-spaceBox">•</i>
                                        <span className="job-info-tip">{val.pushTimeShow}</span>
                                        <i className="round-spaceBox">•</i>
                                        <span className="job-info-tip primary-color">
                                            Match - {val.matchScore || 0}%
                                        </span>
                                    </div>
                                    <p className="skill-tit">Skills Required</p>
                                    <div className="skill-tip oneLineText">
                                        {val.hardSkillPOJOList && val.hardSkillPOJOList.length
                                            ? val.hardSkillPOJOList.map(v => v.skillName).join(', ')
                                            : '-'}
                                    </div>
                                </div>
                            );
                        })}
                        {loading && (
                            <div className="r-center">
                                <Spin style={{ position: 'static' }}></Spin>
                            </div>
                        )}
                        <div ref={loaderRef} style={{ height: '20px' }} />
                    </>
                ) : (
                    <div className="r-center no-message-tabBox">No data</div>
                )}
            </div>
        </div>
    );
}

export default MyApplications;
