import { Button, Drawer, Form, FormProps, Image, Input, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import UploadImg from '../upload/upload-img';
import DrawDownIcon from '@/assets/img/draw-down.png';
import DrawTopIcon from '@/assets/img/draw-top.png';
import DrawDelIcon from '@/assets/img/draw-del.png';
import DrawEditIcon from '@/assets/img/draw-edit.png';
import ImageUploadIcon from '@/assets/img/Image-add.png';
import VideoUploadIcon from '@/assets/img/File-video.png';
import TextUploadIcon from '@/assets/img/File-text.png';
import { defaultInfo } from '@/typings/allType';
import { useS3Upload } from '@/hook/useS3Upload';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import useLoading from '@/hook/useLoading';
import FormModal from '../modal/form-modal';
import TextArea from 'antd/es/input/TextArea';
function moveElement(arr, index, direction) {
    const newArr = [...arr];
    if (direction === 'up' && index > 0) {
        const temp = newArr[index];
        newArr[index] = newArr[index - 1];
        newArr[index - 1] = temp;
    } else if (direction === 'down' && index < arr.length - 1) {
        const temp = newArr[index];
        newArr[index] = newArr[index + 1];
        newArr[index + 1] = temp;
    }
    return newArr;
}
function OpeationPortFolioDrawer({ open, onClose, setPortFolioInfo, portFolioEditInfo }: any) {
    const navigate = useNavigate();
    const messageApi = useGlobalMessage();
    const { uploadFileToS3, uploadProgress, uploadSuccess, uploadError, picUrl, picUrlShow } = useS3Upload();
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [contentText, setContentText] = useState('');
    const uploadRef = useRef<any>(null);
    const uploadRefVideo = useRef<any>(null);
    const [form] = Form.useForm();
    const formRef = useRef<any>(null);
    const [addType, setAddType] = useState('video');
    const [formInfo, setFormInfo] = useState({
        cover: '',
        picUrlShow: '',
        projectName: '',
        url: '',
        portfolioItemList: [] as defaultInfo[],
    });
    const [editIndex, setEditIndex] = useState(-1);
    const editItemInfo = (val, i) => {
        setEditIndex(i);
        setAddType(val.type);
        if (val.type === 'text') {
            setContentText(val.text);
            setIsFormModalOpen(true);
            return;
        }
        if (val.type === 'video') {
            uploadRefVideo.current?.click();
            return;
        }
        uploadRef.current?.click();
    };
    const onFinish: FormProps['onFinish'] = values => {
        if (!formInfo.cover) {
            messageApi.error('Please upload image');
            return;
        }
        if (!formInfo.portfolioItemList.length) {
            messageApi.error('Please add at least one block');
            return;
        }
        setPortFolioInfo({ ...formInfo });
    };

    const [avatar, setAvatar] = useState({
        url: '',
        key: '',
        base64: '',
        file: null,
        uploading: false,
        dialogVisible: false,
        warncontent: '',
        name: '',
    }) as any;
    const handleFileChange = (e: any) => {
        if (!e) {
            return;
        }
        const file = e.target.files[0].name.split('.');
        const fileVal = e.target.files[0];
        if (
            ((file[file.length - 1].toLowerCase() === 'png' || file[file.length - 1].toLowerCase() === 'jpg') &&
                addType === 'image') ||
            (file[file.length - 1].toLowerCase() === 'mp4' && addType === 'video')
        ) {
            if (e.target.files[0].size > 50 * 1048576 && addType === 'image') {
                window.format.alert('error', 'Image cannot exceed 50MB');
                (document.getElementById('portfolioInput') as any).value = null;
                return;
            }
            if (e.target.files[0].size > 500 * 1048576 && addType === 'video') {
                window.format.alert('error', 'Maximum video file size is 500mb');
                (document.getElementById('portfolioInput') as any).value = null;
                return;
            }
            const reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = function (ev: any) {
                uploadFileToS3({ name: fileVal.name, file: fileVal });
                setAvatar(
                    Object.assign({}, avatar, {
                        url: picUrl,
                        key: window.format.getFilePathName(picUrl),
                        base64: ev.target.result,
                        dialogVisible: false,
                        file: ev.target.result,
                        uploading: true,
                    })
                );
                (document.getElementById('portfolioInput') as any).value = null;
            };
        } else {
            window.format.alert(
                'error',
                addType === 'image' ? 'File format error' : 'Video files only support MP4 format'
            );
            (document.getElementById('portfolioInput') as any).value = null;
        }
    };
    useEffect(() => {
        if (uploadSuccess && open) {
            const obj = {
                type: addType,
                url: picUrl,
                showImg: addType === 'image' ? avatar.base64 : picUrlShow,
            };
            if (editIndex !== -1) {
                formInfo.portfolioItemList.splice(editIndex, 1, obj);
            } else {
                formInfo.portfolioItemList.push(obj);
            }
            setFormInfo({ ...formInfo });
            setAvatar(
                Object.assign({}, avatar, {
                    dialogVisible: false,
                    file: null,
                    uploading: false,
                })
            );
        }
    }, [uploadSuccess]);
    useEffect(() => {
        if (uploadError && open) {
            setAvatar({
                url: '',
                key: '',
                base64: '',
                file: null,
                uploading: false,
                dialogVisible: false,
                warncontent: '',
                name: '',
            });
            messageApi.error(uploadError);
        }
    }, [uploadError]);
    useEffect(() => {
        if (open && portFolioEditInfo?.index !== -1) {
            const item = portFolioEditInfo.info;
            const initialValues = {
                cover: item.picUrlShow ? item.picUrlShow : item.cover ? item.cover : '',
                picUrlShow: item.picUrlShow ? item.picUrlShow : item.cover ? item.cover : '',
                projectName: item.projectName ? item.projectName : '',
                url: item.url ? item.url : '',
                portfolioItemList: item.portfolioItemList ? item.portfolioItemList : [],
            };
            setFormInfo({ ...initialValues });
            form.setFieldsValue(initialValues);
        } else {
            const initialValues = {
                cover: '',
                picUrlShow: '',
                projectName: '',
                url: '',
                portfolioItemList: [],
            };
            setFormInfo({ ...initialValues });
            form.setFieldsValue(initialValues);
        }
    }, [open]);
    return (
        <div>
            <Drawer
                rootClassName="portFolioDrawer-box opeation-drawer"
                placement={'bottom'}
                closable={false}
                keyboard={false}
                maskClosable={false}
                onClose={onClose}
                open={open}
                height="91%"
                key={'bottom'}
            >
                <i className="close-icon-drawer" onClick={() => onClose()}></i>
                <div className="r-left" style={{ marginBottom: 24 }}>
                    <span className="portFolio-titN">
                        {portFolioEditInfo.index === -1 ? 'Create New' : 'Edit'} Project
                    </span>
                </div>
                <div className="port-folioForm">
                    <Form
                        name="basic-portFolio-info"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        form={form}
                        ref={formRef}
                        autoComplete="off"
                        validateTrigger="onBlur"
                    >
                        <Form.Item
                            label="Cover Image"
                            // name="profilePhoto"
                            // rules={[{ required: true, message: 'Please upload image' }]}
                        >
                            <UploadImg
                                imgUrl={
                                    formInfo.picUrlShow ? formInfo.picUrlShow : formInfo.cover ? formInfo.cover : ''
                                }
                                setPicInfo={(val: any) => {
                                    // setFormInfo({ ...formInfo, cover: val.picUrl, picUrlShow: val.picUrlShow });
                                    setFormInfo(prev => ({
                                        ...prev,
                                        cover: val.picUrl,
                                        picUrlShow: val.picUrlShow,
                                    }));
                                    form.setFieldsValue({ profilePhoto: val.picUrl });
                                }}
                            />
                        </Form.Item>
                        <div className="r-left" style={{ width: '100%' }}>
                            <Form.Item
                                className="flex1"
                                label="Project Name"
                                name="projectName"
                                rules={[{ required: true, message: 'Please enter a project name' }]}
                            >
                                <Input
                                    className="r-input"
                                    maxLength={50}
                                    value={formInfo.projectName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const str = e.target.value;
                                        // setFormInfo({ ...formInfo, projectName: str });
                                        setFormInfo(prev => ({
                                            ...prev,
                                            projectName: str,
                                        }));
                                        form.setFieldsValue({ projectName: str });
                                    }}
                                    placeholder=""
                                />
                            </Form.Item>
                            <span className="space20"></span>
                            <Form.Item className="flex1" label="External Project URL">
                                <Input
                                    className="r-input"
                                    placeholder=""
                                    prefix={<span>https://</span>}
                                    suffix={
                                        window.utils.isWebSite(`https://${formInfo.url}`) ? (
                                            <i className="url-succ-icon"></i>
                                        ) : null
                                    }
                                    value={formInfo.url}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const str = e.target.value;
                                        setFormInfo({ ...formInfo, url: str });
                                    }}
                                />
                            </Form.Item>
                        </div>
                        {/* item card list */}
                        <div>
                            {formInfo.portfolioItemList.map((item, i) => {
                                return (
                                    <div className="portItemCard-box" key={`portItemCard-${i}`}>
                                        <div className="opeation-iconBox r-end">
                                            <img
                                                src={DrawTopIcon}
                                                className={`draw-icon ${i === 0 ? 'disaledBox' : ''}`}
                                                alt=""
                                                onClick={() => {
                                                    const newArr = moveElement(formInfo.portfolioItemList, i, 'up');
                                                    setFormInfo({ ...formInfo, portfolioItemList: [...newArr] });
                                                }}
                                            />
                                            <img
                                                src={DrawDownIcon}
                                                className={`draw-icon ${
                                                    i === formInfo.portfolioItemList.length - 1 ? 'disaledBox' : ''
                                                }`}
                                                alt=""
                                                onClick={() => {
                                                    const newArr = moveElement(formInfo.portfolioItemList, i, 'down');
                                                    setFormInfo({ ...formInfo, portfolioItemList: [...newArr] });
                                                }}
                                            />
                                            <img
                                                src={DrawEditIcon}
                                                className="draw-icon"
                                                alt=""
                                                onClick={() => editItemInfo(item, i)}
                                            />
                                            <img
                                                src={DrawDelIcon}
                                                className="draw-icon"
                                                alt=""
                                                onClick={() => {
                                                    formInfo.portfolioItemList.splice(i, 1);
                                                    setFormInfo({ ...formInfo });
                                                }}
                                            />
                                        </div>
                                        {item.type === 'image' ? (
                                            <div className="imgBox marb24 r-center">
                                                <img
                                                    style={{ maxWidth: '100%' }}
                                                    src={item.showImg ? item.showImg : item.url}
                                                    alt=""
                                                />
                                            </div>
                                        ) : null}
                                        {item.type === 'text' ? (
                                            <div className="textBox marb24" style={{ whiteSpace: 'pre-line' }}>
                                                {item.text}
                                            </div>
                                        ) : null}
                                        {item.type === 'video' ? (
                                            <video
                                                className="textBox marb24"
                                                src={item.showImg ? item.showImg : require('@/assets/img/test1.mp4')}
                                            />
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                        {/* add item */}
                        <Spin
                            spinning={avatar.uploading}
                            indicator={<Loading3QuartersOutlined spin style={{ fontSize: 24, color: '#4C47DE' }} />}
                        >
                            <div className="r-center portItem-add">
                                <div
                                    className="add-box"
                                    onClick={() => {
                                        setAddType('video');
                                        setEditIndex(-1);
                                        uploadRefVideo.current?.click();
                                    }}
                                >
                                    <img src={VideoUploadIcon} className="add-icon-draw" alt="" />
                                    <p className="add-tip-draw">Add Video Block</p>
                                </div>
                                <div
                                    className="add-box"
                                    onClick={() => {
                                        setAddType('image');
                                        setEditIndex(-1);
                                        uploadRef.current?.click();
                                    }}
                                >
                                    <img src={ImageUploadIcon} className="add-icon-draw" alt="" />
                                    <p className="add-tip-draw">Add Image Block</p>
                                </div>
                                <div
                                    className="add-box"
                                    onClick={() => {
                                        setEditIndex(-1);
                                        setContentText('');
                                        setAddType('text');
                                        setIsFormModalOpen(true);
                                    }}
                                >
                                    <img src={TextUploadIcon} className="add-icon-draw" alt="" />
                                    <p className="add-tip-draw">Add Text Block</p>
                                </div>
                                <input
                                    ref={uploadRef}
                                    type="file"
                                    id="portfolioInput"
                                    onChange={e => handleFileChange(e)}
                                    accept="image/*"
                                    title=""
                                />
                                <input
                                    ref={uploadRefVideo}
                                    type="file"
                                    id="portfolioInput"
                                    onChange={e => handleFileChange(e)}
                                    accept="video/*"
                                    title=""
                                />
                            </div>
                        </Spin>
                        <Form.Item style={{ margin: '40px 0 0 0' }}>
                            <Button
                                className="r-primary_btn r-primary_largeBtn"
                                style={{
                                    width: 208,
                                    opacity:
                                        !formInfo.cover || !formInfo.projectName || !formInfo.portfolioItemList.length
                                            ? 0.5
                                            : 1,
                                }}
                                htmlType="submit"
                            >
                                Continue
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Drawer>

            <FormModal
                title={`Add Text`}
                width={512}
                marBtm={10}
                isModalOpen={isFormModalOpen}
                handleOk={() => setIsFormModalOpen(false)}
                handleCancel={() => setIsFormModalOpen(false)}
                children={
                    <div className="curModal-box" key={'curModal-box'}>
                        <p className="form-tip">Text content</p>
                        <div className={`textArea-box`}>
                            <TextArea
                                className={`r-inputTextArea r-input`}
                                maxLength={5000}
                                value={contentText}
                                onInput={(e: any) => setContentText(e.target.value)}
                                autoComplete="off"
                                autoSize={false}
                                placeholder=" "
                            />
                            <span className="text-count">{contentText.length || 0}/5000</span>
                        </div>
                        <div className="r-center">
                            <Button
                                style={{ width: 106, marginTop: 40 }}
                                className="r-primary_btn r-primary_largeBtn"
                                onClick={() => {
                                    const obj = {
                                        type: addType,
                                        url: '',
                                        showImg: '',
                                        text: contentText,
                                    };
                                    if (editIndex !== -1) {
                                        formInfo.portfolioItemList.splice(editIndex, 1, obj);
                                    } else {
                                        formInfo.portfolioItemList.push(obj);
                                    }
                                    setFormInfo({ ...formInfo });
                                    setIsFormModalOpen(false);
                                }}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default OpeationPortFolioDrawer;
