import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { AutoComplete, Select, Spin } from 'antd';

function JobSkillAutoComplate({
    setInfo,
    classStr,
    suffixIcon,
    isFinshClear,
    placeholder,
    height,
    disabled,
    skillOptions,
    value,
    selectOptions,
    index,
}: any) {
    const messageApi = useGlobalMessage();
    const [autoLoading, setAutoLoading] = useState(false);
    const [inpValue, setInpValue] = useState('');
    const [options, setOptions] = useState<any[]>([]);
    const selectedSkill = val => {
        const info = options.find(v => v.value === val);
        const isHave = JSON.parse(JSON.stringify(selectOptions)).findIndex(
            (v, i) => v.name && v.name === val && i !== index
        );
        if (isHave !== -1) {
            messageApi.error('You have added this skill.');
            setInpValue('');
            return;
        }
        if (isFinshClear) {
            setInpValue('');
            setOptions([]);
        } else {
            setInpValue(info.label);
        }
        setInfo(info.label);
    };
    useEffect(() => {
        setOptions([...skillOptions]);
    }, [skillOptions]);
    useLayoutEffect(() => {
        console.log(value);
        setInpValue(value);
    }, [value]);
    return (
        <>
            <Select
                options={options}
                className={classStr ? classStr : 'r-autoComplate'}
                style={{ width: '100%', height: height || 30 }}
                value={inpValue}
                disabled={disabled}
                onChange={setInpValue}
                suffixIcon={suffixIcon ? suffixIcon : <i className="down-right-icon"></i>}
                onSelect={val => selectedSkill(val)}
                showSearch
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                placeholder={placeholder || 'Any'}
                notFoundContent={
                    <>
                        {autoLoading && (
                            <div className="r-center">
                                <Spin />
                            </div>
                        )}
                        {!autoLoading && <div>No data</div>}
                    </>
                }
            ></Select>
        </>
    );
}

export default JobSkillAutoComplate;
