import uploadApi from '@/api/upload';
import React, { useEffect, useState } from 'react';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { useS3Upload } from '@/hook/useS3Upload';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { Button, Spin } from 'antd';
function UploadFile({ setFileInfo, fileInfo }: any) {
    const { uploadFileToS3, uploadProgress, uploadSuccess, uploadError, picUrl } = useS3Upload();
    const messageApi = useGlobalMessage();
    const [avatar, setAvatar] = useState({
        name: '',
        url: '',
        key: '',
        base64: '',
        file: null,
        uploading: false,
    }) as any;
    const handleFileChange = (e: any) => {
        if (!e || avatar.uploading) {
            return;
        }
        const file = e.target.files[0].name.split('.');
        if (
            file[file.length - 1].toLowerCase() === 'pdf' ||
            file[file.length - 1].toLowerCase() === 'doc' ||
            file[file.length - 1].toLowerCase() === 'docx'
        ) {
            if (e.target.files[0].size > 50 * 1048576) {
                window.format.alert('error', 'File cannot exceed 50MB');
                (document.getElementById('fileInput') as any).value = null;
                return;
            }
            uploadFileToS3({ name: e.target.files[0].name, file: e.target.files[0] });
            setAvatar(
                Object.assign({}, avatar, {
                    url: '',
                    base64: '',
                    file: e.target.files[0],
                    uploading: true,
                    name: e.target.files[0].name,
                })
            );
            (document.getElementById('fileInput') as any).value = null;
        } else {
            window.format.alert('error', 'File format is incorrect');
            (document.getElementById('fileInput') as any).value = null;
        }
    };
    const delFile = () => {
        setFileInfo({ url: '', name: '' });
        setAvatar({
            url: '',
            key: '',
            base64: '',
            file: null,
            uploading: false,
            name: '',
        });
    };
    useEffect(() => {
        if (uploadSuccess) {
            setAvatar(
                Object.assign({}, avatar, {
                    url: picUrl,
                    file: null,
                    base64: '',
                    uploading: false,
                })
            );
            setFileInfo({ url: picUrl, name: avatar.name });
        }
    }, [uploadSuccess]);
    useEffect(() => {
        if (uploadError) {
            setAvatar({
                url: '',
                key: '',
                base64: '',
                file: null,
                uploading: false,
                name: '',
            });
            messageApi.error(uploadError);
        }
    }, [uploadError]);
    useEffect(() => {
        if (fileInfo) {
            setAvatar({
                url: fileInfo.url ?? '',
                key: '',
                base64: '',
                file: null,
                uploading: false,
                name: fileInfo.name ?? '',
            });
        }
    }, [fileInfo]);
    return (
        <>
            <div className="r-left uploadMain_box">
                {avatar.url ? (
                    <div className="uploadfile-info">
                        <i className="file-icon"></i>
                        <div style={{ width: 163 }} className="oneLineText">
                            {avatar.name ? avatar.name : '-'}
                        </div>
                        <i className="file-delIcon" onClick={() => delFile()}></i>
                    </div>
                ) : (
                    <label className="upload_fileBox" htmlFor="fileInput">
                        <div
                            className="uploadfile-btn"
                            style={{
                                width: 120,
                                opacity: avatar.uploading ? 0.5 : 1,
                                cursor: avatar.uploading ? 'no-drop' : 'pointer',
                            }}
                        >
                            Update
                            <span style={{ marginLeft: 5 }}>
                                {avatar.uploading ? <LoadingOutlined style={{ fontSize: 16 }} rev={undefined} /> : null}
                            </span>
                        </div>
                        <input
                            type={avatar.uploading ? 'text' : 'file'}
                            id="fileInput"
                            style={{ display: avatar.uploading ? 'none' : 'initial' }}
                            onChange={e => handleFileChange(e)}
                            accept=""
                            title=""
                        />
                    </label>
                )}
            </div>
        </>
    );
}

export default UploadFile;
