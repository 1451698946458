import React, { useState } from 'react';
import TalenautLogoShare from '@/assets/img/talenaut_logo-invert.png';
import FormModal from '@/components/modal/form-modal';
import { Button } from 'antd';
import config from '@/config/config';
const fallbackCopyToClipboard = (text: string) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed'; // 避免影响页面布局
    textarea.style.left = '-9999px';
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    try {
        document.execCommand('copy');
        window.utils.alert('success', 'Copy success.');
    } catch (err) {
        window.utils.alert('error', 'Replication failure.');
    }
    document.body.removeChild(textarea);
};
function ShareModal({ isFormModalOpen, setIsFormModalOpen, val }: any) {
    console.log('====================================');
    console.log(val);
    console.log('====================================');
    const [textToCopy, setTextToCopy] = useState('');
    const copyToClipboard = () => {
        const text = `${config.localUrl}/jobinformation?id=${val.jobId}`;
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    window.utils.alert('success', 'Copy success.');
                })
                .catch(err => {
                    window.utils.alert('error', 'Replication failure.');
                });
        } else {
            fallbackCopyToClipboard(text); // 使用备用方案
        }
    };
    return (
        <FormModal
            title="Share Job"
            width={512}
            marBtm={10}
            isModalOpen={isFormModalOpen}
            handleOk={() => setIsFormModalOpen(false)}
            handleCancel={() => setIsFormModalOpen(false)}
            children={
                <div className="shareModal-box">
                    <div className="share-cardBox">
                        <div className="topShare-iconBox r-center">
                            <img src={TalenautLogoShare} className="TalenautLogoShare-img" alt="" />
                        </div>
                        <div className="share-content">
                            <p className="share-tit">{val.jobsPOJO.jobTitle || '-'}</p>
                            <p className="share-14">{val.jobsPOJO.employerName || '-'}</p>
                            <p>{val.jobsPOJO.jobDescription || '-'}</p>
                            <a
                                href={`${config.localUrl}/jobinformation?id=${val.jobId}`}
                                target="_blank"
                                className="share-link"
                                style={{ wordBreak: 'break-all' }}
                            >
                                {`${config.localUrl}/jobinformation?id=${val.jobId}`}
                            </a>
                        </div>
                    </div>
                    <div className="r-center">
                        <Button
                            style={{ width: 85, marginTop: 30 }}
                            className="r-primary_btn r-primary_largeBtn"
                            onClick={() => {
                                copyToClipboard();
                                setIsFormModalOpen(false);
                            }}
                        >
                            Copy
                        </Button>
                    </div>
                </div>
            }
        />
    );
}

export default ShareModal;
