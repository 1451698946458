import React, { useEffect, useState } from 'react';
import api from '@/api/login';
import { message } from 'antd';
import useLoading from './useLoading';
import config from '@/config/config';
// linkein auth2
function UseLinkeinAuth() {
    const { mainLoading, setLoadingStatus } = useLoading();
    const [code, setCode] = useState('');
    const clientId = config.linkinedAuthKey;
    const redirectUri = `${config.localUrl}/auth-linkedin-callback`;
    const scope = 'profile,email,openid';
    const loginWithThird = pat => {
        setLoadingStatus(true);
        api.loginWithThirdParty(pat, res => {
            if (res.code === 200) {
                console.log(res);
            } else {
                message.error(res.message);
            }
            setLoadingStatus(false);
        });
    };

    const handleLogin = () => {
        const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=DCEeFWf45A53sdfKef424`;
        const popup: any = window.open(url, 'linkedin-login', 'width=600,height=600');
        const interval = setInterval(() => {
            if (popup.closed) {
                clearInterval(interval);
            }
        }, 1000);
    };
    useEffect(() => {
        setCode('');
        const handleMessage = (event: MessageEvent) => {
            if (event.origin !== window.location.origin) return;
            const { code, error, type } = event.data;
            if (type !== 'linkedin') return;
            if (error) {
                console.error('Authorization error:', error);
            } else if (code) {
                const type = window.sessionStorage.getItem('linkedinType') ?? '0';
                console.log(type);
                setCode(code);
                // if (type === '1') {
                //     setCode(code);
                //     return;
                // }
                // loginWithThird({ thirdPartyId: code, thirdPartyType: 'linkedIn' });
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return { handleLogin, code, setCode };
}

export default UseLinkeinAuth;
