import React, { useState } from 'react';
import './index.scss';
import RCheckBox from '@/components/checkbox';
import { Button } from 'antd';
import RTabs from './tabs';
import LocationInp from '@/components/autoComplate-inp/locationInp';
function CandidateFilter({ pageNow, getFilterList }: any) {
    const [city, setCity] = useState('');
    const [state, setState] = useState<any>({
        isRemote: false,
        isCity: false,
        cityList: [],
        hardSkill: [],
        softSkill: [],
    });
    const [yearSel, setYearSel] = useState([
        { label: '0-2 years', isChecked: false },
        { label: '3-5 years', isChecked: false },
        { label: '6-10 years', isChecked: false },
        { label: '10+ years', isChecked: false },
    ]);

    const clearAll = () => {
        setState({
            isRemote: false,
            isCity: false,
            cityList: [],
            hardSkill: [],
            softSkill: [],
        });
        setYearSel([
            { label: '0-2 years', isChecked: false },
            { label: '3-5 years', isChecked: false },
            { label: '6-10 years', isChecked: false },
            { label: '10+ years', isChecked: false },
        ]);
        const params = {
            gmtId: '',
            location: [],
            remoteFlag: null,
            yearsOfExperience: [],
        };
        getFilterList(params);
    };
    const filterList = () => {
        const yearAll: any = [];
        yearSel.forEach(item => {
            if (item.isChecked) {
                yearAll.push(item.label);
            }
        });
        const params = {
            gmtId: '',
            location: state.isCity ? state.cityList : [],
            remoteFlag: state.isRemote ? 1 : null,
            yearsOfExperience: yearAll,
        };
        getFilterList(params);
    };
    return (
        <div className="candidateFilter-box">
            <div className="filter-tit">Filter</div>
            <p className="filter-tip">Location</p>
            <div className="filter-marb8">
                <RCheckBox
                    checkInfo={{
                        isChecked: state.isRemote,
                        label: 'Remote',
                    }}
                    setCheckInfo={() => setState({ ...state, isRemote: !state.isRemote })}
                />
            </div>
            <div className="filter-marb8">
                <RCheckBox
                    checkInfo={{
                        isChecked: state.isCity,
                        label: 'City',
                    }}
                    setCheckInfo={() => setState({ ...state, isCity: !state.isCity, cityList: [] })}
                />
                {state.isCity ? (
                    <div>
                        <div style={{ marginTop: 8 }}>
                            <LocationInp
                                setLocation={val => {
                                    if (state.cityList.findIndex(v => v === val.address) !== -1) {
                                        return;
                                    }
                                    state.cityList.push(val.address);
                                    setState({ ...state });
                                }}
                                type="search"
                                placeholder="Start typing..."
                                isFinshClear={true}
                                value={city}
                                height={36}
                                disabled={state.cityList.length >= 5}
                                suffixIcon={<i></i>}
                                classStr="r-autoComplate r-autoComplate-search"
                            />
                        </div>
                        <div>
                            <RTabs
                                tabLists={state.cityList}
                                onDelTab={(i: number) => {
                                    state.cityList.splice(i, 1);
                                    setState({ ...state });
                                }}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
            <p className="filter-tip" style={{ marginTop: state.cityList.length ? 5 : 15 }}>
                Years of Experience
            </p>
            {yearSel.map((item, i) => {
                return (
                    <div className="filter-marb8" key={`yearSel-${i}`}>
                        <RCheckBox
                            checkInfo={item}
                            index={i}
                            setCheckInfo={i => {
                                // yearSel.forEach(val => {
                                //     val.isChecked = false;
                                // });
                                yearSel[i].isChecked = !yearSel[i].isChecked;
                                setYearSel([...yearSel]);
                            }}
                        />
                    </div>
                );
            })}
            <div className="r-end" style={{ marginTop: 20 }}>
                <Button className="r-primary_btn r-primaryNoBg_btn filter-clear_btn" onClick={() => clearAll()}>
                    Clear
                </Button>
                <Button className="r-primary_btn" style={{ width: 118 }} onClick={() => filterList()}>
                    Apply Filter
                </Button>
            </div>
        </div>
    );
}

export default CandidateFilter;
