import { useState, useEffect, useCallback } from 'react';
import { message } from 'antd';
import JSEncrypt from 'jsencrypt';
import api from '@/api/login'; // 替换为你的实际 API 请求模块

const usePasswordEncrypt = () => {
    const [publicKey, setPublicKey] = useState<string | null>(null);
    const [messageApi, contextHolder] = message.useMessage();

    useEffect(() => {
        const storedKey = window.sessionStorage.getItem('publickey');
        if (storedKey) {
            setPublicKey(storedKey);
        } else {
            fetchPublicKey();
        }
    }, []);

    const fetchPublicKey = useCallback(() => {
        api.getPublicKey((res: any) => {
            const { code, data } = res;
            if (code === 200 && data) {
                setPublicKey(data);
                window.sessionStorage.setItem('publickey', data);
            } else {
                messageApi.error(res.message);
            }
        });
    }, [messageApi]);

    const encryptPassword = (password: string): any => {
        if (!publicKey) {
            fetchPublicKey();
            messageApi.error('Public key is not available. Please try again.');
            return null;
        }

        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);
        return encrypt.encrypt(password);
    };

    return { encryptPassword, contextHolder };
};

export default usePasswordEncrypt;
