import { Button, Form, FormProps, Input, message } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import JSEncrypt from 'jsencrypt';
import api from '@/api/login';
function ResetPassword() {
    const passwordEncrypt = new JSEncrypt();
    const [loading, setLoading] = useState(false);
    const [eyeOpen, setEyeOpen] = useState(false);
    const [eyeOpen1, setEyeOpen1] = useState(false);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const back = () => {
        navigate('/login');
    };
    const [state, setState] = useState({
        password: '',
        rePassword: '',
    });
    const onFinish: FormProps['onFinish'] = () => {
        if (state.password !== state.rePassword) {
            message.error('The 2 passwords entered are not the same');
            setState({ password: '', rePassword: '' });
            form.setFieldsValue({ password: '', rePassword: '' });
            return;
        }
        setLoading(true);
        api.getPublicKey(res => {
            const { code, data } = res;
            if (code === 200 && data) {
                passwordEncrypt.setPublicKey(data);
                api.resetPassword(
                    {
                        code: window.sessionStorage.getItem('code'),
                        email: window.sessionStorage.getItem('email'),
                        password: passwordEncrypt.encrypt(state.password),
                    },
                    res => {
                        setLoading(false);
                        const { code, data } = res;
                        if (code === 200) {
                            window.sessionStorage.setItem('password', state.password);
                            navigate('/reset-success');
                        } else {
                            window.utils.alert('error', res.message);
                        }
                    }
                );
            } else {
                setLoading(false);
                message.error(res.message);
            }
        });
    };
    const validatePassword = (_: any, value: string) => {
        const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z\d]{8,12}$/;
        if (!value || !passwordPattern.test(value)) {
            return Promise.reject(
                new Error(
                    'Please enter a password of 8-12, which must contain one uppercase letter, one lowercase letter, and one number.'
                )
            );
        }
        return Promise.resolve();
    };
    return (
        <div className="login-main">
            <div className="nav-top r-left">
                <img className="left-logo" src={require('@/assets/img/talenaut_logo-color.png')} alt="" />
            </div>
            <div className="login-formMin r-center">
                <div className="center-box">
                    <div className="login-formBox">
                        <h2>Reset Password</h2>
                        <Form
                            form={form}
                            name="basic-resetPassword"
                            layout="vertical"
                            style={{ maxWidth: 380 }}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            validateTrigger="onBlur"
                        >
                            <Form.Item label="New Password" name="password" rules={[{ validator: validatePassword }]}>
                                <Input
                                    className={`r-input `}
                                    maxLength={12}
                                    autoComplete="off"
                                    placeholder={'Enter 8-12 digit password'}
                                    value={state.password}
                                    onInput={(e: any) => setState({ ...state, password: e.target.value })}
                                    type={eyeOpen ? 'text' : 'password'}
                                    suffix={
                                        eyeOpen ? (
                                            <i className="eye-open" onClick={() => setEyeOpen(false)}></i>
                                        ) : (
                                            <i className="eye-close" onClick={() => setEyeOpen(true)}></i>
                                        )
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                style={{ marginBottom: 30 }}
                                label="Confirm New Password"
                                name="rePassword"
                                rules={[{ validator: validatePassword }]}
                            >
                                <Input
                                    className={`r-input `}
                                    maxLength={12}
                                    autoComplete="off"
                                    placeholder={'Re-enter the 8-12 digit password'}
                                    value={state.rePassword}
                                    onChange={(e: any) => setState({ ...state, rePassword: e.target.value })}
                                    type={eyeOpen1 ? 'text' : 'password'}
                                    suffix={
                                        eyeOpen1 ? (
                                            <i className="eye-open" onClick={() => setEyeOpen1(false)}></i>
                                        ) : (
                                            <i className="eye-close" onClick={() => setEyeOpen1(true)}></i>
                                        )
                                    }
                                />
                            </Form.Item>
                            <Form.Item style={{ margin: 0 }}>
                                <Button
                                    className="r-primary_btn"
                                    htmlType="submit"
                                    disabled={!state.rePassword || !state.password}
                                    loading={loading}
                                >
                                    Save New Password
                                </Button>
                                <Button
                                    className="r-primaryNoBg_btn"
                                    style={{ marginTop: 15 }}
                                    onClick={() => {
                                        back();
                                    }}
                                >
                                    Back To Login
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
