import React, { useEffect, useState } from 'react';
import './index.scss';
import { cloneDeep } from 'lodash';
import { Button, Divider, Image, Input, QRCode, Spin, Tooltip } from 'antd';
import UserShareImg from '@/assets/img/user-share.png';
import UserImg1 from '@/assets/img/user1.png';
import UserImg2 from '@/assets/img/user2.png';
import UserImg3 from '@/assets/img/user3.png';
import UserImg4 from '@/assets/img/user4.png';
import UserImg5 from '@/assets/img/user5.png';
import UserImg6 from '@/assets/img/user6.png';
import UserCanImg from '@/assets/img/user-can.png';
import UserInImg from '@/assets/img/user-in.png';
import Check from '@/assets/img/check-succ.png';
import UserFileImg from '@/assets/img/user-file.png';
import LicenseIconImg from '@/assets/img/user-lisce.png';
import LinkIconImg from '@/assets/img/user-link.png';
import OutlookImg from '@/assets/img/Outlook.png';
import GoogleImg from '@/assets/img/Google.png';
import PortFolioDrawer from '@/components/portfolio-drawer/index';
import VideoPlayModal from '@/components/modal/video-play-modal';
import EditAccount from './components/editAccount';
import EditProfessionalInformation from './components/editProfessionalInformation';
import FormModal from '@/components/modal/form-modal';
import useOutlookAuth from '@/hook/useOutlookAuth';
import useGoogleAuth from '@/hook/useGoogleAuth';
import api from '@/api/profile';
import apiLogin from '@/api/login';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import useLoading from '@/hook/useLoading';
import dayjs from 'dayjs';
import UseLinkeinAuth from '@/hook/useLinkeinAuth';
import config from '@/config/config';
import AlertModal from '@/components/modal/alert-modal';
const downloadCanvasQRCode = () => {
    const canvas = document.getElementById('myqrcode')?.querySelector<HTMLCanvasElement>('canvas');
    if (canvas) {
        const url = canvas.toDataURL();
        window.utils.doDownload(url, 'Personal QR Code.png');
    }
};
const fallbackCopyToClipboard = (text: string) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.style.position = 'fixed'; // 避免影响页面布局
    textarea.style.left = '-9999px';
    document.body.appendChild(textarea);
    textarea.focus();
    textarea.select();
    try {
        document.execCommand('copy');
        window.utils.alert('success', 'Copy success.');
    } catch (err) {
        window.utils.alert('error', 'Replication failure.');
    }
    document.body.removeChild(textarea);
};

function Profile() {
    const messageApi = useGlobalMessage();
    const { mainLoading, setLoadingStatus } = useLoading();
    const { handleLogin, code, setCode } = UseLinkeinAuth();
    const { handleOutLookLogin, outlookCode, setOutlookCode } = useOutlookAuth();
    const { handleGoogleLogin, googleCode, setGoogleCode } = useGoogleAuth();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [calendarFlag, setCalendarFlag] = useState(false);
    const [linkedinFlag, setLinkedinFlag] = useState(false);
    const [calendarOutlook, setCalendarOutlook] = useState(false);
    const [calendarGoogle, setCalendarGoogle] = useState(false);
    const [GMTList, setGMTList] = useState([]);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [isFormModalOpen1, setIsFormModalOpen1] = useState(false);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
    const [isProfessionalFormModalOpen, setIsProfessionalFormModalOpen] = useState(false);
    const [state, setState] = useState({
        lininCheck: false,
        videoList: [
            { tit: 'Self Introduction', url: '', cover: '' },
            { tit: 'Key Skills and Experience ', url: '', cover: '' },
            { tit: 'Key Personal Strength', url: '', cover: '' },
        ],
        profilePhoto: '',
        email: '',
        emailTwo: '',
        mobileNumber: '',
        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        birth: '',
        ethnicity: '',
        location: '',
        remoteFlag: 1,
        preferredPronounsFlag: 1,
        preferredPronounsListFlag: 1,
        preferredPronounsList: 'He/They',
        preferredPronounsCustomFlag: 0,
        preferredPronounsCustom: '',
        gmt: '',
        gmtId: '',
        personalName: '',
        personalQrcode: '',
        jobRole: '',
        languageProficientIn: '',
        aboutMe: '',
        selfIntroductionUrl: '',
        keySkillsExperiencesUrl: '',
        keyPersonalStrengthUrl: '',
        selfIntroductionUrlImage: '',
        keySkillsExperiencesUrlImage: '',
        keyPersonalStrengthUrlImage: '',
        cvFileSize: '',
        cvFileUrl: '',
        cvFileName: '',
        graduation: '',
        highestDegree: '',
        major: '',
        yearsOfExperience: 0,
        registerStepNumber: 3,
    });
    const [userLink, setUserLink] = useState('');
    const [userLinkEdit, setUserLinkEdit] = useState('');
    const [showLinkError, setShowLinkError] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const [showMoreProject, setShowMoreProject] = useState(false);
    const [accountProjectPOJOList, setAccountProjectPOJOList] = useState<any>([]);
    const [projectInfo, setProjectInfo] = useState<any>({});
    const [accountCertificatePOJOList, setAccountCertificatePOJOList] = useState<any>([]);
    const [relevantExperience, setRelevantExperience] = useState<any>([]);
    const [relevantExperienceTime, setRelevantExperienceTime] = useState<string>('');
    const [skillList, setSkillList] = useState<any>([]);
    const [softSkillList, setSoftSkillList] = useState<any>([]);
    const [tableData, setTableData] = useState<any>([]);
    const [showMoreLog, setShowMoreLog] = useState(false);
    const copyToClipboard = () => {
        const text = `${config.localUrl}/userprofile?id=${userLink}`;
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard
                .writeText(text)
                .then(() => {
                    window.utils.alert('success', 'Copy success.');
                })
                .catch(err => {
                    window.utils.alert('error', 'Replication failure.');
                });
        } else {
            fallbackCopyToClipboard(text); // 使用备用方案
        }
    };
    const editUserLink = () => {
        setLoading(true);
        const url = `${config.localUrl}/userprofile?id=${userLinkEdit}`;
        api.setProfileLink({ personalName: url }, res => {
            setLoading(false);
            if (res.code === 200) {
                setIsFormModalOpen1(false);
                setUserLink(userLinkEdit);
            } else if (res.code === 50008) {
                setShowLinkError(true);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const getUserInfo = () => {
        api.getProfileInfo({}, res => {
            setPageLoading(false);
            if (res.code === 200) {
                const data = res.data;
                setLinkedinFlag(data.linkedinFlag);
                setCalendarFlag(data.calendarFlag);
                setCalendarOutlook(data.calendarOutlook);
                setCalendarGoogle(data.calendarGoogle);
                const userIdName = data.accountInformationPOJO.personalName.split('/userprofile/')[1];
                setUserLink(userIdName);
                setUserLinkEdit(userIdName);
                setAccountLoginType(data.accountLoginType || 'eamil');
                if (data.accountInformationPOJO && data.accountInformationPOJO !== null) {
                    const val = cloneDeep(data.accountInformationPOJO);
                    state.videoList[0].url = val.selfIntroductionUrl;
                    state.videoList[0].cover = val.selfIntroductionUrlImage;
                    state.videoList[1].url = val.keySkillsExperiencesUrl;
                    state.videoList[1].cover = val.keySkillsExperiencesUrlImage;
                    state.videoList[2].url = val.keyPersonalStrengthUrl;
                    state.videoList[2].cover = val.keyPersonalStrengthUrlImage;
                    setState({ ...state, ...val });
                }
                if (data.accountProjectPOJOList && data.accountProjectPOJOList !== null) {
                    setAccountProjectPOJOList([...data.accountProjectPOJOList]);
                }
                if (data.accountSkillPOJOList && data.accountSkillPOJOList !== null) {
                    const skillList: any = [];
                    const softSkillList: any = [];
                    data.accountSkillPOJOList.forEach(item => {
                        if (item.skillType === 'softSkills') {
                            softSkillList.push(item);
                        } else {
                            skillList.push(item);
                        }
                        setSkillList([...skillList]);
                        setSoftSkillList([...softSkillList]);
                    });
                }
                if (data.accountExperiencePOJO && data.accountExperiencePOJO !== null) {
                    const year = data.accountExperiencePOJO.yearsOfExperience;
                    const month = data.accountExperiencePOJO.monthOfExperience;
                    const time = `${year ? `${year}${year > 1 ? 'Years' : 'Year'}` : ''}${
                        month ? ` ${month}${month > 1 ? ' Months' : ' Month'}` : ''
                    }`;
                    console.log('====================================');
                    console.log(data.accountExperiencePOJO.accountExperienceInfoPOJOList);
                    console.log('====================================');
                    setRelevantExperienceTime(time);
                    setRelevantExperience([...data.accountExperiencePOJO.accountExperienceInfoPOJOList]);
                }
                if (data.accountCertificatePOJOList && data.accountCertificatePOJOList !== null) {
                    const val = cloneDeep(data.accountCertificatePOJOList);
                    setAccountCertificatePOJOList(val);
                }
                // console.log(res.data);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const getLogList = () => {
        api.getProfileActionlog({ pageNo: 1, pageSize: 1000 }, res => {
            const { code, data, message } = res;
            if (code === 200) {
                console.log(data);
                const arr = data.list.map(item => {
                    return Object.assign(item, {
                        timeShow: item.createTime
                            ? dayjs(new Date(item.createTime * 1000)).format("D MMM 'YY hh:mm A")
                            : '-',
                    });
                });
                setTableData([...arr]);
            } else {
                messageApi.error(message);
            }
        });
    };
    const [pageloading, setPageLoading] = useState(false);
    useEffect(() => {
        setPageLoading(true);
        getUserInfo();
        getLogList();
        apiLogin.getGmtList(res => {
            if (res.code === 200) {
                const arr = res.data.map(item => Object.assign(item, { label: item.gmt, value: item.id }));
                setGMTList(arr);
            } else {
                messageApi.error(res.message);
            }
        });
    }, []);
    const [linkedInLoading, setLinkedInLoading] = useState(false);
    const [outlookLoading, setOutlookLoading] = useState(false);
    const [googleLoading, setGoogleLoading] = useState(false);
    const linkThired = (type: string, code: string) => {
        console.log('====================================');
        console.log(type);
        console.log('====================================');
        if (type === 'linkedin') {
            setLinkedInLoading(true);
            api.connectLinkedin({ keyWord: code }, res => {
                setLinkedInLoading(false);
                if (res.code === 200) {
                    setLinkedinFlag(true);
                    setCode('');
                    setIsModalOpen(true);
                } else {
                    messageApi.error(res.message);
                }
            });
            window.sessionStorage.removeItem('linkedinType');
            return;
        }
        if (type === 'outlook') {
            setOutlookLoading(true);
            api.connectCalendarOutlook({ keyWord: code }, res => {
                setOutlookLoading(false);
                if (res.code === 200) {
                    setCalendarOutlook(true);
                    setOutlookCode('');
                } else {
                    messageApi.error(res.message);
                }
            });
            return;
        }
        setGoogleLoading(true);
        api.connectCalendarGoogle({ keyWord: code }, res => {
            setGoogleLoading(false);
            if (res.code === 200) {
                setCalendarGoogle(true);
                setGoogleCode('');
            } else {
                messageApi.error(res.message);
            }
        });
    };
    useEffect(() => {
        if (code) {
            linkThired('linkedin', code);
        }
    }, [code]);
    useEffect(() => {
        if (outlookCode) {
            linkThired('outlook', outlookCode);
        }
    }, [outlookCode]);
    useEffect(() => {
        if (googleCode) {
            linkThired('google', googleCode);
        }
    }, [googleCode]);
    const unlink = (type: string) => {
        if (type === 'linkedin') {
            setLinkedInLoading(true);
            api.disconnectLinkedin({}, res => {
                setLinkedInLoading(false);
                if (res.code === 200) {
                    setLinkedinFlag(false);
                } else {
                    messageApi.error(res.message);
                }
            });
            return;
        }
        if (type === 'outlook') {
            setOutlookLoading(true);
            api.disconnectCalendarOutlook({}, res => {
                setOutlookLoading(false);
                if (res.code === 200) {
                    setCalendarOutlook(false);
                } else {
                    messageApi.error(res.message);
                }
            });
            return;
        }
        setGoogleLoading(true);
        api.disconnectCalendarGoogle({}, res => {
            setGoogleLoading(false);
            if (res.code === 200) {
                setCalendarGoogle(false);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [accountLoginType, setAccountLoginType] = useState('eamil');

    return (
        <div className="userprofile-main">
            {pageloading ? (
                <div className="r-center">
                    <Spin style={{ position: 'static' }} />
                </div>
            ) : (
                <div>
                    <div className="top-infoBox">
                        Public Profile
                        <p className="userProfile-tip">
                            Your profile is visible to companies and those who have access to your QR Code and Custom
                            URL.
                        </p>
                    </div>
                    <div>
                        <div className="userprofile-content">
                            <div className="profile-card pmarb20">
                                <div className="r-top">
                                    <div>
                                        <Image
                                            className="user-img"
                                            preview={false}
                                            src={state?.profilePhoto ?? ''}
                                            fallback={require('@/assets/img/default-min.png')}
                                        />
                                    </div>
                                    <div className="user-infoName">
                                        {state.firstName ?? '-'} {state.middleName} {state.lastName}
                                        {state.preferredPronounsFlag
                                            ? `(${
                                                  state.preferredPronounsCustomFlag
                                                      ? state.preferredPronounsCustom
                                                      : state.preferredPronounsList
                                              })`
                                            : ''}
                                        <p className="fz14" style={{ marginTop: 4 }}>
                                            {state.jobRole ? state.jobRole : '-'}
                                        </p>
                                    </div>
                                    <img
                                        src={UserShareImg}
                                        className="user-shareIcon"
                                        onClick={() => copyToClipboard()}
                                        alt=""
                                    />
                                    <Button
                                        className="r-primary_btn r-primaryNoBg_btn medium_btn"
                                        onClick={() => setIsFormModalOpen(true)}
                                        style={{ width: 93 }}
                                    >
                                        Edit Account
                                    </Button>
                                </div>
                                <div className="profile-iconInfo r-top">
                                    <div style={{ width: '35%' }}>
                                        <div className="r-left img-fbox">
                                            <img src={UserImg1} className="userImg-box" alt="" />
                                            {state.remoteFlag ? `Remote + ${state.gmt}` : state.location}
                                        </div>
                                        <div className="r-left img-fbox">
                                            <img src={UserImg2} className="userImg-box" alt="" />
                                            {state.email ? state.email : state.emailTwo || '-'}
                                        </div>
                                        <div className="r-left img-fbox">
                                            <img src={UserImg3} className="userImg-box" alt="" />
                                            {state.gender ? state.gender : '-'}
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: 72 }}>
                                        <div className="r-left img-fbox">
                                            <img src={UserImg4} className="userImg-box" alt="" />
                                            {state.languageProficientIn ? state.languageProficientIn : '-'}
                                        </div>
                                        <div className="r-left img-fbox">
                                            <img src={UserImg5} className="userImg-box" alt="" />
                                            {state.ethnicity ? state.ethnicity : '-'}
                                        </div>
                                        <div className="r-left img-fbox">
                                            <img src={UserImg6} className="userImg-box" alt="" />
                                            {state.birth ? dayjs(state.birth).format('MMM YYYY') : '-'}
                                        </div>
                                    </div>
                                </div>
                                <div className="profile-urlBox">
                                    <div>
                                        <p className="p-urlTit">Personal URL</p>
                                        <a
                                            href={`${config.localUrl}/userprofile?id=${userLink}`}
                                            target="_blank"
                                            className="p-url-link"
                                        >
                                            {/* {state.personalName ? state.personalName : 'wwww.baidu.com'} */}
                                            {`${config.localUrl}/userprofile?id=${userLink}`}
                                        </a>
                                    </div>
                                    <div className="marLeft">
                                        <Button
                                            className="r-primary_btn r-primaryNoBg_btn medium_btn"
                                            style={{ width: 45 }}
                                            onClick={() => {
                                                const userIdName = state.personalName.split('/userprofile/')[1];
                                                setUserLinkEdit(userIdName);
                                                setIsFormModalOpen1(true);
                                            }}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            onClick={() => copyToClipboard()}
                                            className="r-primary_btn r-primaryNoBg_btn medium_btn"
                                            style={{ width: 52, marginLeft: 10 }}
                                        >
                                            Copy
                                        </Button>
                                    </div>
                                </div>
                                <div className="profile-urlBox" style={{ marginTop: 16 }}>
                                    <div id="myqrcode">
                                        <p className="p-urlTit">Personal QR Code</p>
                                        <QRCode
                                            value={`${config.localUrl}/userprofile?id=${userLink}`}
                                            size={54}
                                            type="canvas"
                                            bordered={false}
                                        />
                                    </div>
                                    <div className="marLeft">
                                        <Button
                                            className="r-primary_btn r-primaryNoBg_btn medium_btn"
                                            style={{ width: 78 }}
                                            onClick={() => downloadCanvasQRCode()}
                                        >
                                            Download
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="r-left pmarb20">
                                <div className="profile-card" style={{ width: 280, minWidth: 280 }}>
                                    <div className="r-left" style={{ marginBottom: 16 }}>
                                        <img src={UserCanImg} className="user-threeLinkImg" alt="" />
                                        {calendarFlag ? (
                                            <span className="connected-box r-center marLeft">Connected</span>
                                        ) : null}
                                    </div>
                                    <p className="p-urlTit">Calendar Integration</p>
                                    <p className="profile-info-pri">Seamlessly Schedule Your Interviews</p>
                                    <div style={{ marginTop: 12 }}>
                                        <Button
                                            className={`r-primary_btn medium_btn ${
                                                calendarFlag ? 'r-primaryNoBg_btn' : ''
                                            }`}
                                            style={{ width: 120 }}
                                            onClick={() => setIsCalendarModalOpen(true)}
                                        >
                                            {calendarFlag ? 'Disconnect' : 'Connect'}
                                        </Button>
                                    </div>
                                </div>
                                <div
                                    className="profile-card pmarl20"
                                    style={{
                                        display: accountLoginType === 'email' ? 'block' : 'none',
                                        width: 280,
                                        minWidth: 280,
                                    }}
                                >
                                    <div className="r-left" style={{ marginBottom: 16 }}>
                                        <img src={UserInImg} className="user-threeLinkImg" alt="" />
                                        {linkedinFlag ? (
                                            <span className="connected-box r-center marLeft">Connected</span>
                                        ) : null}
                                    </div>
                                    <p className="p-urlTit">Connect with LinkedIn</p>
                                    <p className="profile-info-pri">Sync Your Professional Details from LinkedIn</p>
                                    <div style={{ marginTop: 12 }}>
                                        {!linkedinFlag ? (
                                            <Button
                                                className="r-primary_btn medium_btn"
                                                style={{ width: 120 }}
                                                loading={linkedInLoading}
                                                onClick={() => {
                                                    window.sessionStorage.setItem('linkedinType', '1');
                                                    handleLogin();
                                                }}
                                            >
                                                Connect
                                            </Button>
                                        ) : (
                                            <div className="r-left">
                                                <p className="profile-info-pri op5">-</p>
                                                <Button
                                                    className="r-primary_btn r-primaryNoBg_btn medium_btn marLeft"
                                                    style={{ width: 85 }}
                                                    onClick={() => unlink('linkedin')}
                                                    loading={linkedInLoading}
                                                >
                                                    Disconnect
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="profile-card pmarb20">
                                <div className="r-left">
                                    <p className="p-urlTit" style={{ fontSize: 24 }}>
                                        Professional information
                                    </p>
                                    <Button
                                        className="r-primary_btn r-primaryNoBg_btn medium_btn marLeft"
                                        style={{ width: 45 }}
                                        onClick={() => setIsProfessionalFormModalOpen(true)}
                                    >
                                        Edit
                                    </Button>
                                </div>
                                <div style={{ marginTop: 16 }}>
                                    <p className="p-urlTit">About Me</p>
                                    <div className="profile-info-pri break-text">
                                        {state.aboutMe ? state.aboutMe : '-'}
                                    </div>
                                    <div className="line-box"></div>
                                    <p className="p-urlTit">Education</p>
                                    <div className="profile-info-pri">
                                        {state.graduation} - {state.major}
                                    </div>
                                    <div className="line-box"></div>
                                    <p className="p-urlTit">Skills</p>
                                    <div className="profile-info-pri">
                                        <div className="r-top" style={{ marginBottom: 5 }}>
                                            <p
                                                className="p-urlTit"
                                                style={{
                                                    fontSize: 12,
                                                    margin: '0 5px 0 0',
                                                    minWidth: 'max-content',
                                                    lineHeight: '14px',
                                                }}
                                            >
                                                Hard Skills:
                                            </p>
                                            <div className="profile-info-pri">
                                                {skillList.length
                                                    ? skillList.map((val, i) => (
                                                          <span>{`${val.skillName}${
                                                              i !== skillList.length - 1 ? ', ' : ''
                                                          }`}</span>
                                                      ))
                                                    : '-'}
                                            </div>
                                        </div>
                                        <div className="r-top">
                                            <p
                                                className="p-urlTit"
                                                style={{
                                                    fontSize: 12,
                                                    margin: '0 5px 0 0',
                                                    minWidth: 'max-content',
                                                    lineHeight: '14px',
                                                }}
                                            >
                                                Soft Skills:
                                            </p>
                                            <div className="profile-info-pri">
                                                {softSkillList.length
                                                    ? softSkillList.map((val, i) => (
                                                          <span>{`${val.skillName}${
                                                              i !== softSkillList.length - 1 ? ', ' : ''
                                                          }`}</span>
                                                      ))
                                                    : '-'}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="line-box"></div>
                                        <p className="p-urlTit">Relevant Experience</p>
                                        {relevantExperience.length ? (
                                            <>
                                                <p className="profile-info-pri" style={{ marginBottom: 16 }}>
                                                    {relevantExperienceTime ? relevantExperienceTime : '-'}
                                                </p>
                                                <div className="r-left relevantExperienceList">
                                                    {relevantExperience.map((item, i) => {
                                                        return (
                                                            <div
                                                                key={`relevantExperience-profile-item-${i}`}
                                                                className={`relevantExperience-item ${
                                                                    item.occupationalType !== 'Freelancer'
                                                                        ? 'greenBg'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <div className="experie-tit r-left">
                                                                    <div className="oneLineText">
                                                                        {item.occupationalType !== 'Freelancer'
                                                                            ? item.companyName
                                                                            : 'Freelancer'}
                                                                    </div>
                                                                    {item.occupationalType !== 'Freelancer' ? (
                                                                        <img
                                                                            src={Check}
                                                                            className="jobCheck-icon"
                                                                            alt=""
                                                                        />
                                                                    ) : null}
                                                                </div>
                                                                <div className="experie-job oneLineText">
                                                                    {item.jobRoles ? item.jobRoles : '-'}
                                                                </div>
                                                                <div className="experie-jobTime">
                                                                    {item.currentEmployedHereFlag === 1 ? (
                                                                        'Currently Employed Here'
                                                                    ) : (
                                                                        <>
                                                                            {item.yearsOfExperience
                                                                                ? `${item.yearsOfExperience} ${
                                                                                      item.yearsOfExperience > 1
                                                                                          ? ' Years '
                                                                                          : ' Year '
                                                                                  }`
                                                                                : ''}
                                                                            {item.monthOfExperience
                                                                                ? `${item.monthOfExperience} ${
                                                                                      item.monthOfExperience > 1
                                                                                          ? ' Months'
                                                                                          : ' month'
                                                                                  }`
                                                                                : ''}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        ) : (
                                            <div className="profile-info-pri op5 r-center">Not yet</div>
                                        )}
                                    </div>
                                    <div className="line-box" style={{ margin: '10px 0 20px 0' }}></div>
                                    <div className="r-left">
                                        <img src={UserFileImg} className="user-fileImg" alt="" />
                                        <div>
                                            <p className="p-urlTit">My CV</p>
                                            <div className="profile-info-pri op5">{state.cvFileSize}kb</div>
                                        </div>
                                        <Button
                                            className="r-primary_btn r-primaryNoBg_btn medium_btn marLeft"
                                            style={{ width: 55 }}
                                            onClick={() => window.open(state.cvFileUrl, '_target')}
                                        >
                                            View
                                        </Button>
                                    </div>
                                    <div className="line-box"></div>
                                    <div>
                                        <p className="p-urlTit">Portfolio Files</p>
                                        <div className="profile-info-pri">
                                            {accountProjectPOJOList.length}{' '}
                                            {accountProjectPOJOList.length > 1 ? 'files' : 'file'}
                                        </div>
                                        <div>
                                            <div className="r-left portList-box">
                                                {accountProjectPOJOList.map((item, i) => {
                                                    return (
                                                        <div
                                                            className="portImg-box"
                                                            key={item.projectId}
                                                            style={{
                                                                display: i > 5 && !showMoreProject ? 'none' : 'initial',
                                                                border: '1px solid #ccc',
                                                                backgroundImage: `url(${item.coverImageUrl})`,
                                                                backgroundPosition: 'center center',
                                                            }}
                                                            onClick={() => {
                                                                setProjectInfo(item);
                                                                setOpenDrawer(true);
                                                            }}
                                                        ></div>
                                                    );
                                                })}
                                            </div>
                                            {accountProjectPOJOList.length < 7 || showMoreProject ? null : (
                                                <div
                                                    className="viewMore-btn r-center"
                                                    onClick={() => setShowMoreProject(true)}
                                                >
                                                    View More
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="line-box"></div>
                                    <div>
                                        <p className="p-urlTit" style={{ marginBottom: 16 }}>
                                            Videos
                                        </p>
                                        <div>
                                            <div className="r-left">
                                                {state.videoList.map((item, i) => {
                                                    return (
                                                        <div
                                                            key={`candidateVideo-profile-item$_${i}`}
                                                            className="videoCard-box"
                                                        >
                                                            <p className="video-tip">{item.tit}</p>
                                                            <div
                                                                className="videoCard-bg"
                                                                style={{
                                                                    backgroundImage: `url(${
                                                                        item.cover ? item.cover : ''
                                                                    })`,
                                                                    backgroundPosition: 'center center',
                                                                    backgroundSize: 'cover',
                                                                    backgroundColor: '#ccc',
                                                                }}
                                                                onClick={() => {
                                                                    setVideoUrl(item.url);
                                                                    setIsVideoModalOpen(true);
                                                                }}
                                                            ></div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="line-box"></div>
                                    <div>
                                        <p className="p-urlTit">Licenses, Certifications and External Assessments</p>
                                        <div className="profile-info-pri">
                                            {accountCertificatePOJOList.length}{' '}
                                            {accountCertificatePOJOList.length > 1 ? 'files' : 'file'}
                                        </div>
                                        <div style={{ marginTop: 12 }}>
                                            {accountCertificatePOJOList.length ? (
                                                accountCertificatePOJOList.map((item, i) => {
                                                    return (
                                                        <div key={`licenseBox-msg_${i}`} className="r-top roleList-box">
                                                            <img src={LicenseIconImg} className="roleImg" alt="" />
                                                            <div>
                                                                <p className="msg-tit">
                                                                    {item.certificateName ? item.certificateName : '-'}
                                                                </p>
                                                                <p className="msg-tip" style={{ marginTop: 8 }}>
                                                                    {item.validPeriodFrom} - {item.validPeriodTo}
                                                                </p>
                                                            </div>
                                                            {item.certificateUrl ? (
                                                                <img
                                                                    src={LinkIconImg}
                                                                    className="roleImg marLeft"
                                                                    style={{
                                                                        width: 28,
                                                                        height: 28,
                                                                        marginRight: 0,
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() =>
                                                                        window.open(
                                                                            `https://${item.certificateUrl}`,
                                                                            '_target'
                                                                        )
                                                                    }
                                                                    alt=""
                                                                />
                                                            ) : null}
                                                        </div>
                                                    );
                                                })
                                            ) : (
                                                <div className="profile-info-pri op5 r-center">Not yet</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="top-infoBox" style={{ padding: 0 }}>
                            Activity History
                            <div className="user-table-box">
                                <div className="team-table-th r-left">
                                    <div className="thade-cell">Title</div>
                                    <div className="thade-cell">Date</div>
                                    <div className="thade-cell">Description</div>
                                </div>
                                {tableData.length ? (
                                    <div className="team-table-body">
                                        {tableData.map((val, i) => {
                                            return (
                                                <div
                                                    style={{
                                                        display: i > 2 && !showMoreLog ? 'none' : 'flex',
                                                    }}
                                                    className="team-table-tr r-left"
                                                    key={`team-table-tr-${i}`}
                                                >
                                                    <div className="team-tb-cell">
                                                        <Tooltip placement="topLeft" title={val.title}>
                                                            <div className="oneLineText">{val.title ?? '-'}</div>
                                                        </Tooltip>
                                                    </div>
                                                    <div className="team-tb-cell">{val.timeShow}</div>
                                                    <div className="team-tb-cell oneLineText">
                                                        <Tooltip placement="topLeft" title={val.actionLog}>
                                                            <div className="oneLineText">{val.actionLog ?? '-'}</div>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                        {tableData.length < 3 || showMoreLog ? null : (
                                            <div
                                                className="viewMore-btn r-center"
                                                style={{ marginTop: 20 }}
                                                onClick={() => setShowMoreLog(true)}
                                            >
                                                View More
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className="no-data">Not yet</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <EditProfessionalInformation
                isProfessionalFormModalOpen={isProfessionalFormModalOpen}
                setIsProfessionalFormModalOpen={setIsProfessionalFormModalOpen}
                profileInfo={state}
                accountProjectPOJOList={accountProjectPOJOList}
                accountCertificatePOJOList={accountCertificatePOJOList}
                relevantExperienceList={relevantExperience}
                skillList={skillList}
                softSkillList={softSkillList}
                submitInfo={() => {
                    setIsProfessionalFormModalOpen(false);
                    getUserInfo();
                }}
            />
            <EditAccount
                isFormModalOpen={isFormModalOpen}
                setIsFormModalOpen={setIsFormModalOpen}
                GMTList={GMTList}
                profileInfo={state}
                submitInfo={() => {
                    setIsFormModalOpen(false);
                    getUserInfo();
                }}
            />
            <PortFolioDrawer open={openDrawer} onClose={() => setOpenDrawer(false)} projectInfo={projectInfo} />
            <VideoPlayModal
                isModalOpen={isVideoModalOpen}
                videoUrl={videoUrl}
                handleOk={() => setIsVideoModalOpen(false)}
                handleCancel={() => setIsVideoModalOpen(false)}
            />
            <FormModal
                title="Custom Profile URL"
                width={380}
                marBtm={10}
                isModalOpen={isFormModalOpen1}
                handleOk={() => setIsFormModalOpen1(false)}
                handleCancel={() => {
                    setIsFormModalOpen1(false);
                    setShowLinkError(false);
                }}
                children={
                    <div className="curModal-box" key={'curModal-box'}>
                        <p className="form-tip">Standout from the rest by creating a custom URL.</p>
                        <Input
                            className="r-input"
                            prefix={'www.talenaut.com/userprofile?id='}
                            value={userLinkEdit}
                            onInput={(e: any) => {
                                const inputValue = e.target.value;
                                const alphanumericValue = inputValue.replace(/[^a-zA-Z0-9]/g, '');
                                setUserLinkEdit(alphanumericValue);
                            }}
                            placeholder=""
                        />
                        {showLinkError ? (
                            <p className="form-tip" style={{ color: '#F87171', marginTop: 10 }}>
                                * Unfortunately the Custom Profile URL you've chosen is already in use. Please try a
                                different combination. Good luck!
                            </p>
                        ) : null}
                        <div className="r-center">
                            <Button
                                style={{ width: 106, marginTop: 40 }}
                                className="r-primary_btn r-primary_largeBtn"
                                onClick={() => editUserLink()}
                                disabled={!userLinkEdit}
                                loading={loading}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                }
            />
            <FormModal
                title="Calendar Integration"
                width={688}
                marBtm={10}
                isModalOpen={isCalendarModalOpen}
                handleOk={() => setIsCalendarModalOpen(false)}
                handleCancel={() => setIsCalendarModalOpen(false)}
                children={
                    <div className="r-left" style={{ marginTop: 20 }}>
                        <div className="calendar-card">
                            <p className="calendar-tit">Outlook</p>
                            <div className="r-center">
                                <img src={OutlookImg} style={{ width: 32 }} alt="" />
                            </div>
                            <div className="r-center" style={{ marginTop: 50 }}>
                                {calendarOutlook ? (
                                    <Button
                                        className="r-primary_btn medium_btn"
                                        style={{ width: 100 }}
                                        onClick={() => unlink('outlook')}
                                        loading={outlookLoading}
                                    >
                                        Disconnect
                                    </Button>
                                ) : (
                                    <Button
                                        className="r-primary_btn medium_btn"
                                        style={{ width: 100 }}
                                        onClick={() => handleOutLookLogin()}
                                        loading={outlookLoading}
                                    >
                                        Connect
                                    </Button>
                                )}
                            </div>
                        </div>
                        <div className="calendar-card">
                            <p className="calendar-tit">Google</p>
                            <div className="r-center">
                                <img src={GoogleImg} style={{ width: 32 }} alt="" />
                            </div>
                            <div className="r-center" style={{ marginTop: 50 }}>
                                {calendarGoogle ? (
                                    <Button
                                        className="r-primary_btn r-primaryNoBg_btn medium_btn"
                                        style={{ width: 120 }}
                                        loading={googleLoading}
                                        onClick={() => unlink('google')}
                                    >
                                        Disconnect
                                    </Button>
                                ) : (
                                    <Button
                                        className="r-primary_btn medium_btn"
                                        style={{ width: 120 }}
                                        loading={googleLoading}
                                        onClick={() => handleGoogleLogin()}
                                    >
                                        Connect
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                }
            />
            <AlertModal
                message={
                    <div>
                        You have successfully linked your LinkedIn account.<br></br>
                        Do you need to replace your current profile information with information on your LinkedIn
                        account?
                    </div>
                }
                cancelText="Cancel"
                confirmText="Confirm"
                isModalOpen={isModalOpen}
                handleOk={() => {
                    setIsModalOpen(false);
                }}
                handleCancel={() => {
                    setIsModalOpen(false);
                }}
            />
        </div>
    );
}

export default Profile;
