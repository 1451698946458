import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AutoComplete, Button, DatePicker, DatePickerProps, Form, FormProps, Input, message, Select, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import UploadImg from '@/components/upload/upload-img';
import RCheckBox from '@/components/checkbox';
import UploadVideo from '@/components/upload/upload-video';
import PortFolioList from './components/portFolioList';
import OpeationPortFolioDrawer from '@/components/portfolio-drawer/opeationPortfolio';
import RTabs from './components/tabs';
import FormModal from '@/components/modal/form-modal';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { defaultInfo } from '@/typings/allType';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import UploadFile from '@/components/upload/upload-File';
import api from '@/api/login';
import debounce from 'lodash/debounce';
import JobSkillAutoComplate from '@/components/autoComplate-inp/jobSkillAutoComplate';

const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
};
const { TextArea } = Input;
function SkillInformation() {
    const messageApi = useGlobalMessage();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const formRef = useRef<any>(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [languageVal, setLanguageVal] = useState('');
    const [state, setState] = useState({
        jobRole: '',
        jobTitleInfo: {} as defaultInfo,
        skillList: [] as defaultInfo[],
        softSkillList: [] as defaultInfo[],
        languageList: [] as string[],
        aboutMe: '',
        myCv: { name: '', url: '' },
        videoList: [
            {
                title: 'Self Introduction',
                desc: "In this video, try to stay relaxed and introduce yourself by talking about where you're from, and perhaps some life experiences. ",
                url: '',
                cover: '',
            },
            {
                title: 'Key Skills & Experiences',
                desc: "In this video, try to talk about how you've applied some of the skills you have, and the most valuable experience you've gained at a work place.",
                url: '',
                cover: '',
            },
            {
                title: 'Key Personal Strength',
                desc: 'Tell us in the video, what your strengths are and how it sets you apart from the rest.',
                url: '',
                cover: '',
            },
        ],
        portFolios: [] as defaultInfo[],
        accountCertificateList: [] as defaultInfo[],
    });
    const [accountCertificateInfo, setAccountCertificateInfo] = useState<defaultInfo>({
        editIndex: -1,
        certificateName: '',
        certificateUrl: '',
        validPeriodFrom: '',
        validPeriodTo: '',
    });
    const enter = e => {
        if (e.keyCode === 13) {
            e.preventDefault(); // 阻止默认提交行为
            e.stopPropagation(); // 阻止事件冒泡
        }
        if (state.languageList.length >= 10) {
            messageApi.error('Add up to 10 proficient languages.');
            return;
        }
        if (e.keyCode === 13) {
            const val = e.target.value;
            if (!val) return;
            if (state.languageList.findIndex(v => v === val) !== -1) {
                messageApi.error('You have added this language.');
                return;
            }
            state.languageList.push(val);
            e.target.value = '';
            setLanguageVal('');
            setState({ ...state, languageList: state.languageList });
        }
    };
    const onFinish: FormProps['onFinish'] = values => {
        console.log('Success:', values);
        console.log(state);
        if (!state.skillList.length || !state.softSkillList.length) {
            messageApi.error('Please add at least one Hard skill and one Soft skill.');
            return;
        }
        if (state.videoList.findIndex(v => !v.url) !== -1) {
            messageApi.error('Please upload 3 video files.');
            return;
        }
        if (!state.myCv.url) {
            messageApi.error('Please upload your cv.');
            return;
        }
        setLoading(true);
        const skillList = state.skillList.map(item => {
            return {
                skillId: '',
                skillName: item.name,
                skillType: 'hardSkills',
                yearsOfExperience: item.time,
            };
        });
        const softskillList = state.softSkillList.map(item => {
            return {
                skillId: '',
                skillName: item.name,
                skillType: 'softSkills',
                yearsOfExperience: item.time,
            };
        });
        const pat = {
            aboutMe: state.aboutMe,
            accountCertificateVOList: state.accountCertificateList.map(item => {
                return Object.assign(item, {
                    validPeriodFrom: dayjs(item.validPeriodFrom, 'MMM YYYY').format('MMM YYYY'),
                    validPeriodTo: dayjs(item.validPeriodTo, 'MMM YYYY').format('MMM YYYY'),
                });
            }),
            accountProjectVOList: state.portFolios.map(item => {
                return {
                    accountProjectFileVOList: item.portfolioItemList.map((val, i) => {
                        return {
                            fileText: val.type === 'text' ? val.text : val.url,
                            fileType: val.type,
                            projectFileId: '',
                            sortNumbers: i + 1,
                        };
                    }),
                    coverImageUrl: item.cover,
                    externalProjectUrl: item.url,
                    projectName: item.projectName,
                };
            }),
            accountSkillVOList: [...skillList, ...softskillList],
            cvFileName: state.myCv.name,
            cvFileUrl: state.myCv.url,
            jobRole: state.jobRole,
            jobRoleCode: state.jobTitleInfo.code,
            keyPersonalStrengthUrl: state.videoList[2].url,
            keySkillsExperiencesUrl: state.videoList[1].url,
            languageProficientIn: state.languageList.join(','),
            selfIntroductionUrl: state.videoList[0].url,
        };
        console.log('====================================');
        console.log(pat);
        console.log('====================================');
        try {
            const skillInfo = JSON.stringify(state);
            window.sessionStorage.setItem('skillInfo', skillInfo);
            api.setPersionSkillInformation(pat, res => {
                setLoading(false);
                if (res.code === 200) {
                    navigate('/education-information');
                } else {
                    messageApi.error(res.message);
                }
            });
        } catch (error) {
            setLoading(false);
        }
    };

    const onFinishFailed: FormProps['onFinishFailed'] = errorInfo => {
        console.log('Failed:', errorInfo);
        // setIsModalOpen(true);
        console.log(state);
        // navigate('/education-information');
    };
    // job title
    const [autocomplateLoading, setAutocomplateLoading] = useState(false);
    const [jobTitle, setJobTitle] = useState('');
    const [jobOptions, setJobOptions] = useState<any[]>([]);
    const getJobOption = search => {
        if (!search) {
            setJobOptions([]);
            return;
        }
        setAutocomplateLoading(true);
        api.getJobRoleList({ keyWord: search }, res => {
            setAutocomplateLoading(false);
            if (res.code === 200) {
                const results = res.data.map((item: any) => ({
                    value: item.title,
                    code: item.code,
                    label: item.title,
                }));
                setJobOptions(results);
            } else {
                setJobOptions([]);
                messageApi.error(res.message);
            }
        });
    };
    const searchList: any = useCallback(
        debounce(params => {
            getJobOption(params);
            console.log('search');
        }, 1000),
        []
    );
    // skill search
    const [skillOptions, setSkillOptions] = useState<{ value: string }[]>([]);
    const getSkillOption = params => {
        setAutocomplateLoading(true);
        api.getSkillList({ keyWord: params.search, code: params.code }, res => {
            setAutocomplateLoading(false);
            if (res.code === 200) {
                const results = res.data.map((item: any) => ({ value: item.title, label: item.title }));
                setSkillOptions(results);
            } else {
                setSkillOptions([]);
                messageApi.error(res.message);
            }
        });
    };
    const searchSkillList: any = useCallback(
        debounce(params => {
            if (!params.search) {
                setSkillOptions([]);
                return;
            }
            getSkillOption(params);
            console.log('search');
        }, 1000),
        []
    );
    const [portFolioEditInfo, setPortFolioEditInfo] = useState({
        index: -1,
        info: {} as defaultInfo,
    });
    const editPortFolio = (val: any, i: number) => {
        setPortFolioEditInfo({ index: i, info: val });
        setOpenDrawer(true);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        const skillInfo = window.sessionStorage.getItem('skillInfo');
        if (skillInfo) {
            const info = JSON.parse(skillInfo);
            const initialValues = { ...info };
            setState({ ...initialValues });
            form.setFieldsValue(initialValues);
            if (info.jobTitleInfo && info.jobTitleInfo?.code) {
                getSkillOption({ search: '', code: info.jobTitleInfo?.code });
            }
        }
    }, []);
    return (
        <div className="login-main information-main">
            <div className="nav-top r-left">
                <img className="left-logo" src={require('@/assets/img/talenaut_logo-color.png')} alt="" />
            </div>
            <div className="login-formMin r-center">
                <div className="informationCenter-box">
                    <div className="info-tit">Personal Information</div>
                    <p className="info-tip">
                        Please complete your personal information to search for new job opportunities.
                    </p>
                    <div className="pri-tit">Personal Introduction</div>
                    <div className="information-formBox">
                        <Form
                            name="basic-skill-form"
                            layout="vertical"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            validateTrigger="onBlur"
                            form={form}
                            ref={formRef}
                            onKeyDown={event => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                }
                            }}
                        >
                            <Form.Item label="Job Role" name="jobRole" rules={[{ required: true, message: ' ' }]}>
                                <AutoComplete
                                    options={jobOptions}
                                    className="r-autoComplate r-autoComplate-bg r-select"
                                    style={{ width: '100%', height: 44 }}
                                    value={jobTitle}
                                    suffixIcon={<i className="down-right-icon"></i>}
                                    onSelect={val => {
                                        const jobTitleInfo: any = jobOptions.find(v => v.label === val);
                                        setJobTitle(jobTitleInfo.label);
                                        setState({
                                            ...state,
                                            jobRole: jobTitleInfo.label,
                                            jobTitleInfo: { ...jobTitleInfo },
                                            skillList: [],
                                            softSkillList: [],
                                        });
                                        form.setFieldsValue({ jobRole: jobTitleInfo.label });
                                        getSkillOption({ search: '', code: jobTitleInfo.code });
                                    }}
                                    onSearch={val => {
                                        searchList(val);
                                    }}
                                    placeholder=""
                                    notFoundContent={
                                        <>
                                            {autocomplateLoading && (
                                                <div className="r-center">
                                                    <Spin />
                                                </div>
                                            )}
                                            {!autocomplateLoading && <div>No data</div>}
                                        </>
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="What language are you proficient in?" name="language">
                                <Input
                                    className="r-input"
                                    placeholder="Start Typing..."
                                    value={languageVal}
                                    onInput={(e: any) => {
                                        setLanguageVal(e.target.value);
                                    }}
                                    onKeyUp={e => enter(e)}
                                />
                                <RTabs
                                    tabLists={state.languageList}
                                    onDelTab={(i: number) => {
                                        state.languageList.splice(i, 1);
                                        setState({ ...state });
                                    }}
                                ></RTabs>
                            </Form.Item>
                            <Form.Item
                                label={
                                    <div className="r-left" style={{ width: 832 }}>
                                        About Me
                                        <span className="textare-countTip marLeft">
                                            {state.aboutMe.length} / 500 characters
                                        </span>
                                    </div>
                                }
                                name="aboutMe"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your about.',
                                    },
                                ]}
                            >
                                <TextArea
                                    className={`r-inputTextArea r-input`}
                                    maxLength={500}
                                    autoComplete="off"
                                    autoSize={false}
                                    value={state.aboutMe}
                                    onInput={(e: any) => {
                                        const val = e.target.value;
                                        setState({ ...state, aboutMe: val });
                                        form.setFieldsValue({ aboutMe: val });
                                    }}
                                    placeholder=""
                                />
                            </Form.Item>
                            <p className="info-line"></p>
                            {/* skill list */}
                            <div>
                                <div className="pri-tit">Skills</div>
                                <p className="skill-typeBox">Hard Skills</p>
                                {state.skillList.map((item, i) => {
                                    return (
                                        <div className="r-left" key={`skillBox-${i}`}>
                                            <Form.Item label="Skill Name" className="w640">
                                                <JobSkillAutoComplate
                                                    skillOptions={skillOptions}
                                                    selectOptions={state.skillList}
                                                    value={item.name}
                                                    index={i}
                                                    setInfo={val => {
                                                        state.skillList[i].name = val;
                                                        setState({ ...state, skillList: [...state.skillList] });
                                                    }}
                                                    placeholder="Start typing..."
                                                    isFinshClear={false}
                                                    height={44}
                                                    suffixIcon={<i></i>}
                                                    classStr="r-autoComplate r-autoComplate-bg"
                                                />
                                                {/* <AutoComplete
                                                    options={skillOptions}
                                                    className="r-autoComplate r-autoComplate-bg r-select"
                                                    style={{ width: '100%', height: 44 }}
                                                    value={item.name}
                                                    onChange={val => {
                                                        state.skillList[i].name = val;
                                                        setState({ ...state, skillList: state.skillList });
                                                    }}
                                                    onBlur={() => {
                                                        setSkillOptions([]);
                                                    }}
                                                    onSelect={val => {
                                                        // const skillInfo: any = jobOptions.find(v => v.value === val);
                                                        const isHave = JSON.parse(
                                                            JSON.stringify(state.skillList)
                                                        ).findIndex(
                                                            (v, index) => v.name && v.name === val && i !== index
                                                        );
                                                        if (isHave !== -1) {
                                                            messageApi.error('You have added this skill.');
                                                            state.skillList[i].name = '';
                                                            setState({ ...state, skillList: state.skillList });
                                                            return;
                                                        }
                                                        state.skillList[i].name = val;
                                                        setState({ ...state, skillList: state.skillList });
                                                        setSkillOptions([]);
                                                    }}
                                                    onSearch={val => {
                                                        searchSkillList({ search: val, code: state.jobTitleInfo.code });
                                                    }}
                                                    placeholder=""
                                                    notFoundContent={
                                                        <>
                                                            {autocomplateLoading && (
                                                                <div className="r-center">
                                                                    <Spin />
                                                                </div>
                                                            )}
                                                            {!autocomplateLoading && <div>No data</div>}
                                                        </>
                                                    }
                                                /> */}
                                            </Form.Item>
                                            <span className="space20"></span>
                                            <Form.Item label="Years of Experience">
                                                <div className="r-left">
                                                    <Input
                                                        className="r-input"
                                                        value={item.time}
                                                        maxLength={50}
                                                        onInput={(e: any) => {
                                                            let inputValue = e.target.value;
                                                            inputValue = inputValue
                                                                .replace(/^0+/, '')
                                                                .replace(/[^\d]/g, '');
                                                            state.skillList[i].time = inputValue;
                                                            setState({ ...state, skillList: state.skillList });
                                                        }}
                                                        placeholder=""
                                                    />
                                                    <span className="space20"></span>
                                                    <i
                                                        className="delR-icon"
                                                        onClick={() => {
                                                            state.skillList.splice(i, 1);
                                                            setState({ ...state, skillList: state.skillList });
                                                        }}
                                                    ></i>
                                                </div>
                                            </Form.Item>
                                        </div>
                                    );
                                })}
                                <div
                                    className={`form-addBtn r-center ${!state.jobRole ? 'disabled' : ''}`}
                                    onClick={() => {
                                        if (!state.jobRole) return;
                                        if (state.skillList.length >= 10) {
                                            messageApi.error('You can add a maximum of 10 hard skills.');
                                            return;
                                        }
                                        state.skillList.push({ name: '', time: '' });
                                        setState({ ...state, skillList: state.skillList });
                                    }}
                                >
                                    + Add Skill
                                </div>
                                <p className="skill-typeBox">Soft Skills</p>
                                {state.softSkillList.map((item, i) => {
                                    return (
                                        <div className="r-left" key={`skillBox-${i}`}>
                                            <Form.Item label="Skill Name" className="w640">
                                                <JobSkillAutoComplate
                                                    skillOptions={skillOptions}
                                                    selectOptions={state.softSkillList}
                                                    value={item.name}
                                                    index={i}
                                                    setInfo={val => {
                                                        state.softSkillList[i].name = val;
                                                        setState({
                                                            ...state,
                                                            softSkillList: [...state.softSkillList],
                                                        });
                                                    }}
                                                    placeholder="Start typing..."
                                                    isFinshClear={false}
                                                    height={44}
                                                    suffixIcon={<i></i>}
                                                    classStr="r-autoComplate r-autoComplate-bg"
                                                />
                                                {/* <AutoComplete
                                                    options={skillOptions}
                                                    className="r-autoComplate r-autoComplate-bg r-select"
                                                    style={{ width: '100%', height: 44 }}
                                                    value={item.name}
                                                    onChange={val => {
                                                        state.softSkillList[i].name = val;
                                                        setState({ ...state, softSkillList: state.softSkillList });
                                                    }}
                                                    onBlur={() => {
                                                        setSkillOptions([]);
                                                    }}
                                                    onSelect={val => {
                                                        const isHave = JSON.parse(
                                                            JSON.stringify(state.softSkillList)
                                                        ).findIndex(
                                                            (v, index) => v.name && v.name === val && i !== index
                                                        );
                                                        if (isHave !== -1) {
                                                            messageApi.error('You have added this skill.');
                                                            state.softSkillList[i].name = '';
                                                            setState({ ...state, softSkillList: state.softSkillList });
                                                            return;
                                                        }
                                                        state.softSkillList[i].name = val;
                                                        setState({ ...state, softSkillList: state.softSkillList });
                                                        setSkillOptions([]);
                                                    }}
                                                    onSearch={val => {
                                                        searchSkillList({ search: val, code: state.jobTitleInfo.code });
                                                    }}
                                                    placeholder=""
                                                    notFoundContent={
                                                        <>
                                                            {autocomplateLoading && (
                                                                <div className="r-center">
                                                                    <Spin />
                                                                </div>
                                                            )}
                                                            {!autocomplateLoading && <div>No data</div>}
                                                        </>
                                                    }
                                                /> */}
                                            </Form.Item>
                                            <span className="space20"></span>
                                            <Form.Item label="Years of Experience">
                                                <div className="r-left">
                                                    <Input
                                                        className="r-input"
                                                        value={item.time}
                                                        maxLength={50}
                                                        onInput={(e: any) => {
                                                            let inputValue = e.target.value;
                                                            inputValue = inputValue.replace(/[^0-9]/g, '');
                                                            state.softSkillList[i].time = inputValue;
                                                            setState({ ...state, softSkillList: state.softSkillList });
                                                        }}
                                                        placeholder=""
                                                    />
                                                    <span className="space20"></span>
                                                    <i
                                                        className="delR-icon"
                                                        onClick={() => {
                                                            state.softSkillList.splice(i, 1);
                                                            setState({ ...state, softSkillList: state.softSkillList });
                                                        }}
                                                    ></i>
                                                </div>
                                            </Form.Item>
                                        </div>
                                    );
                                })}
                                <div
                                    className={`form-addBtn r-center ${!state.jobRole ? 'disabled' : ''}`}
                                    onClick={() => {
                                        if (!state.jobRole) return;
                                        if (state.softSkillList.length >= 10) {
                                            messageApi.error('You can add a maximum of 10 hard skills.');
                                            return;
                                        }
                                        state.softSkillList.push({ name: '', time: '' });
                                        setState({ ...state, softSkillList: state.softSkillList });
                                    }}
                                >
                                    + Add Skill
                                </div>
                            </div>

                            <p className="info-line"></p>
                            {/* video list */}
                            <div className="r-left">
                                {state.videoList.map((item, i) => {
                                    return (
                                        <div className="video-card" key={`videoCard-${i}`}>
                                            <div className="pri-tit">{item.title}</div>
                                            <p className="video-tip">{item.desc}</p>
                                            <UploadVideo
                                                setVideoInfo={(val, thumbnail) => {
                                                    state.videoList[i].url = val;
                                                    state.videoList[i].cover = thumbnail;
                                                    setState({ ...state, videoList: [...state.videoList] });
                                                }}
                                                index={i}
                                                id={`videoInput-${i}`}
                                                videoInfo={state.videoList[i].url}
                                                videoInfocover={state.videoList[i].cover}
                                            />
                                            {item.url ? (
                                                <div
                                                    className="remove-link"
                                                    onClick={() => {
                                                        state.videoList[i].url = '';
                                                        state.videoList[i].cover = '';
                                                        setState({ ...state, videoList: [...state.videoList] });
                                                    }}
                                                >
                                                    Remove
                                                </div>
                                            ) : (
                                                <div className="remove-link" style={{ height: 14 }}>
                                                    {' '}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            <p className="info-line"></p>
                            <div>
                                <div className="pri-tit">Files</div>
                                <Form.Item label="My CV" name="myCv">
                                    <UploadFile
                                        fileInfo={state.myCv}
                                        setFileInfo={val => {
                                            setState({ ...state, myCv: val });
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            {/*  portfolio  */}
                            <div>
                                <Form.Item label="Portfolio Files (optional)" name="portfolioFile">
                                    <div className="r-left">
                                        <PortFolioList
                                            portFolios={state.portFolios}
                                            delItemInfo={(index: number) => {
                                                state.portFolios.splice(index, 1);
                                                setState({ ...state });
                                            }}
                                            editPortFolio={editPortFolio}
                                        />
                                    </div>
                                    {state.portFolios.length >= 10 ? null : (
                                        <Button
                                            className="r-primary_btn medium_btn h32-btn"
                                            style={{ width: 170 }}
                                            onClick={() => {
                                                setPortFolioEditInfo({
                                                    index: -1,
                                                    info: {} as defaultInfo,
                                                });
                                                setOpenDrawer(true);
                                            }}
                                        >
                                            Create New Project
                                        </Button>
                                    )}
                                </Form.Item>
                            </div>
                            {/* cer  */}
                            <div>
                                <Form.Item label="Please update any certificate for certifications and assessment which you may have here. (optional)">
                                    {state.accountCertificateList.map((item, i) => {
                                        return (
                                            <div className="r-left cerinfo-card" key={`cerinfo-card-${i}`}>
                                                {item.certificateName ?? '-'}
                                                <i
                                                    className="edit-icon marLeft"
                                                    onClick={() => {
                                                        setAccountCertificateInfo({
                                                            certificateName: item.certificateName,
                                                            certificateUrl: item.certificateUrl,
                                                            validPeriodFrom: dayjs(item.validPeriodFrom, 'MMM YYYY'),
                                                            validPeriodTo: dayjs(item.validPeriodTo, 'MMM YYYY'),
                                                            editIndex: i,
                                                        });
                                                        setIsFormModalOpen(true);
                                                    }}
                                                ></i>
                                                <i
                                                    className="del-white-icon"
                                                    style={{ marginLeft: 8 }}
                                                    onClick={() => {
                                                        state.accountCertificateList.splice(i, 1);
                                                        setState({ ...state });
                                                    }}
                                                ></i>
                                            </div>
                                        );
                                    })}
                                    {state.accountCertificateList.length < 10 ? (
                                        <Button
                                            className="r-primary_btn medium_btn h32-btn"
                                            style={{ width: 93 }}
                                            onClick={() => setIsFormModalOpen(true)}
                                        >
                                            Update
                                        </Button>
                                    ) : null}
                                </Form.Item>
                            </div>
                            <Form.Item style={{ margin: '30px 0 0 0' }}>
                                <div className="r-left">
                                    <div className="r-center back-btn" onClick={() => navigate(-1)}>
                                        Back
                                    </div>
                                    <Button
                                        className="r-primary_btn r-primary_largeBtn"
                                        style={{ width: 208 }}
                                        loading={loading}
                                        htmlType="submit"
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
            <OpeationPortFolioDrawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                portFolioEditInfo={portFolioEditInfo}
                setPortFolioInfo={val => {
                    console.log(val);
                    if (portFolioEditInfo.index !== -1) {
                        state.portFolios.splice(portFolioEditInfo.index, 1, val);
                    } else {
                        state.portFolios.push(val);
                    }
                    setState({ ...state });
                    setOpenDrawer(false);
                    setPortFolioEditInfo({ index: -1, info: {} });
                }}
            />

            <FormModal
                title="Certifications And Assessment"
                width={380}
                marBtm={10}
                isModalOpen={isFormModalOpen}
                handleOk={() => setIsFormModalOpen(false)}
                handleCancel={() => setIsFormModalOpen(false)}
                children={
                    <div className="curModal-box" key={'curModal-box'}>
                        <p className="form-tip">Certificate Name</p>
                        <Input
                            className="r-input"
                            maxLength={100}
                            value={accountCertificateInfo.certificateName}
                            onInput={(e: any) =>
                                setAccountCertificateInfo({
                                    ...accountCertificateInfo,
                                    certificateName: e.target.value,
                                })
                            }
                            placeholder=""
                        />
                        <p className="form-tip">Certificate URL</p>
                        <Input
                            className="r-input"
                            placeholder=""
                            maxLength={500}
                            value={accountCertificateInfo.certificateUrl}
                            onInput={(e: any) =>
                                setAccountCertificateInfo({
                                    ...accountCertificateInfo,
                                    certificateUrl: e.target.value,
                                })
                            }
                            prefix={<span>https://</span>}
                            suffix={
                                window.utils.isWebSite(`https://${accountCertificateInfo.certificateUrl}`) ? (
                                    <i className="url-succ-icon"></i>
                                ) : null
                            }
                        />
                        <p className="form-tip">Valid Period</p>
                        <div className="r-left">
                            <DatePicker
                                className={`r-datePicker flex1`}
                                placeholder="From"
                                value={accountCertificateInfo.validPeriodFrom}
                                onChange={(date, dateString) => {
                                    setAccountCertificateInfo({
                                        ...accountCertificateInfo,
                                        validPeriodFrom: date,
                                    });
                                }}
                                format="MMM YYYY"
                                picker="month"
                                suffixIcon={<i className="sel_time_icon"></i>}
                                // disabledDate={disabledDate}
                            />
                            <DatePicker
                                style={{ marginLeft: 12 }}
                                className={`r-datePicker flex1`}
                                placeholder="To"
                                value={accountCertificateInfo.validPeriodTo}
                                onChange={(date, dateString) => {
                                    setAccountCertificateInfo({
                                        ...accountCertificateInfo,
                                        validPeriodTo: date,
                                    });
                                }}
                                format="MMM YYYY"
                                picker="month"
                                suffixIcon={<i className="sel_time_icon"></i>}
                                // disabledDate={disabledDate}
                            />
                        </div>
                        <div className="r-center">
                            <Button
                                style={{ width: 106, marginTop: 40 }}
                                className="r-primary_btn r-primary_largeBtn"
                                disabled={
                                    !accountCertificateInfo.certificateName ||
                                    !accountCertificateInfo.validPeriodFrom ||
                                    !accountCertificateInfo.validPeriodTo
                                }
                                onClick={() => {
                                    let arr: any = [];
                                    if (accountCertificateInfo.editIndex !== -1) {
                                        state.accountCertificateList.splice(
                                            accountCertificateInfo.editIndex,
                                            1,
                                            accountCertificateInfo
                                        );
                                        arr = [...state.accountCertificateList];
                                    } else {
                                        arr = [...state.accountCertificateList, ...[accountCertificateInfo]];
                                    }
                                    setState({ ...state, accountCertificateList: arr });
                                    setIsFormModalOpen(false);
                                    setAccountCertificateInfo({
                                        certificateName: '',
                                        certificateUrl: '',
                                        validPeriodFrom: '',
                                        validPeriodTo: '',
                                        editIndex: -1,
                                    });
                                }}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default SkillInformation;
