import { useState, useEffect } from 'react';
import { Select, Button, Pagination } from 'antd';
import type { SelectProps } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import './index.scss';

function PaginationContainer(props) {
    const pageTotal = Number(props.total) < 1 ? 1 : Math.ceil(Number(props.total) / 10);
    const handleChange = (value: number) => {
        props.pageChange(Number(value));
    };
    return (
        <div className="r-end r-pagination">
            <div
                className="opeationpage_btn r-center"
                onClick={() => (props.pageNo !== 1 ? handleChange(1) : '')}
                style={{
                    marginRight: 22,
                    opacity: props.pageNo == 1 ? 0.6 : 1,
                    cursor: props.pageNo == 1 ? 'not-allowed' : 'pointer',
                }}
            >
                First
            </div>
            <Pagination
                showSizeChanger={false}
                defaultCurrent={1}
                total={props.total}
                current={props.pageNo}
                onChange={handleChange}
            />
            <div
                className="opeationpage_btn r-center"
                onClick={() => (props.pageNo >= pageTotal ? '' : handleChange(pageTotal))}
                style={{
                    marginLeft: 22,
                    opacity: props.pageNo >= pageTotal ? 0.6 : 1,
                    cursor: props.pageNo >= pageTotal ? 'not-allowed' : 'pointer',
                }}
            >
                Last
            </div>
        </div>
    );
}
export default PaginationContainer;
