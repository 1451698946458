import React, { useEffect, useState } from 'react';
import './style/index.scss';
import CalendarSchedule from '@/assets/img/Calendar-Schedule.png';
import LocationImg from '@/assets/img/location.png';
import TimeImg from '@/assets/img/Time.png';
import MoneyImg from '@/assets/img/money.png';
import TipMarkImg from '@/assets/img/tip-mark.png';
import RadioButton from '@/assets/img/RadioButton.png';
import RadioButtonWhite from '@/assets/img/RadioButton-w.png';
import { Button, Image, Spin, Tooltip } from 'antd';
import { defaultInfo } from '@/typings/allType';
import RTabs from './components/tabs';
import ApplyJob from '@/components/portfolio-drawer/applyJob';
import AlertModal from '@/components/modal/alert-modal';
import UseInitChart from './hook/useInitChart';
import api from '@/api/job';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import ReminderList from './components/reminderList';
const JobDetail = () => {
    const initChart = UseInitChart();
    const navigate = useNavigate();
    const { profileInfo } = useSelector((state: any) => state.state);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [state, setState] = useState({
        status: 1,
        from: 'home',
        applyFlaag: false,
        jobInfo: {
            screeningQuestionsPOJOList: [] as defaultInfo[],
            applicationsId: '',
            pushTimeShow: '-',
            interviewTimeShow: '-',
            jobTypeShow: '-',
            hardSkillPOJOList: [],
            interviewScorecardQuestionsPOJOList: [],
            jobsPerksPOJOList: [] as defaultInfo[],
            softSkillPOJOList: [] as defaultInfo[],
            match: 0,
            matchScore: 0,
            statusShow: '',
            applicationsPOJO: {
                applicationsStatus: '',
                screeningQuestionsListPOJO: [] as defaultInfo[],
            },
            appliedTimeShow: '',
            jobsPOJO: {
                jobStepStatus: 'Archive',
                jobTitle: '',
                jobDescription: '',
                employerName: '',
                aboutTheCompany: '',
                location: '',
                salaryFrom: '',
                salaryTo: '',
                keyResponsibilities: '',
                associatedRoles: '',
                associatedRolesList: [],
                employerLogo: '',
                remoteFlag: 0,
                gmtFrom: '',
                gmtTo: '',
            },
        },
    });
    const [jobInfo, setJobInfo] = useState({
        statusShow: 'Applied',
        appliedTimeShow: '',
        applicationsPOJO: {
            businessId: '',
            accountId: '',
            status: 'Actively Interested',
            appliedTime: 0,
            inReviewTime: 0,
            interviewScheduledTime: 0,
            offerReceivedTime: 0,
            rejectedTime: 0,
            createTime: 0,
        },
        jobsPOJO: {
            jobId: '',
            jobStepStatus: 'Archive',
        },
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const addTocCalendar = () => {
        if (profileInfo.accountInformationPOJO.calendar !== '0') {
            // window.utils.alert('error', '添加到日历');
            api.addInterview({ applicationsId: state.jobInfo.applicationsId }, res => {
                const { code, data, message } = res;
                if (code === 200) {
                    window.utils.alert('success', 'Success');
                } else {
                    window.utils.alert('error', message);
                }
            });
            return;
        }
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const [oploading, setOpLoading] = useState(false);

    const apply = val => {
        setOpenDrawer(false);
        getApplication({ ...jobInfo, applicationsId: val.applicationsId, from: 'application' });
        // setState({ ...state, from: 'application' });
    };
    const getApplication = info => {
        setLoading(true);
        api.getApplicationJobInfo({ applicationsId: info.applicationsId }, res => {
            const { code, data, message } = res;
            setLoading(false);
            if (code === 200) {
                data.pushTimeShow = data.jobsPOJO.publishTime
                    ? window.utils.foramtTimeDMY(data.jobsPOJO.publishTime * 1000)
                    : '-';
                data.appliedTimeShow = data.applicationsPOJO.appliedTime
                    ? window.utils.foramtTimeDMY(data.applicationsPOJO.appliedTime * 1000)
                    : '-';
                data.interviewTimeShow = data.applicationsPOJO.interviewTime
                    ? `${window.utils.foramtTimeDMY(data.applicationsPOJO.interviewStartTime * 1000)} ${dayjs(
                          data.applicationsPOJO.interviewStartTime * 1000
                      ).format('hh:mm a')} - ${dayjs(data.applicationsPOJO.interviewEndTime * 1000).format('hh:mm a')}`
                    : '';
                data.statusShow =
                    data.applicationsPOJO.applicationsStatus === 'offerReceived'
                        ? 'Offer Received'
                        : data.applicationsPOJO.applicationsStatus === 'rejected'
                        ? 'Rejected'
                        : data.applicationsPOJO.applicationsStatus === 'interviewScheduled'
                        ? 'Interview Scheduled'
                        : data.applicationsPOJO.applicationsStatus === 'inReview' ||
                          data.applicationsPOJO.applicationsStatus === 'screening'
                        ? 'In Review'
                        : 'Applied';
                data.jobTypeShow =
                    data.jobsPOJO.jobType === '1'
                        ? 'Full-Time'
                        : data.jobsPOJO.jobType === '2'
                        ? 'Part-Time'
                        : data.jobsPOJO.jobType === '3'
                        ? 'Contractor'
                        : 'Full-Time';
                if (data.jobsPOJO.associatedRoles) {
                    data.jobsPOJO.associatedRolesList = data.jobsPOJO.associatedRoles.split('-/-');
                } else {
                    data.jobsPOJO.associatedRolesList = [];
                }
                if (data.jobsPOJO.salaryFrom) {
                    data.jobsPOJO.salaryFrom = window.utils.formatAmount(data.jobsPOJO.salaryFrom);
                    data.jobsPOJO.salaryTo = data.jobsPOJO.salaryTo
                        ? window.utils.formatAmount(data.jobsPOJO.salaryTo)
                        : '';
                }
                setState({ ...state, applyFlaag: false, jobInfo: { ...data }, from: info.from });
                window.sessionStorage.setItem('jobInfo', JSON.stringify({ ...data, from: 'application' }));
                const match = data.matchScore || 0;
                setTimeout(() => {
                    const userActivityTimeList = [
                        { value: match, name: 'match', percentage: match },
                        { value: 100, name: 'full', percentage: 100 },
                    ] as defaultInfo[];
                    initChart('round-chart', userActivityTimeList);
                }, 300);
            } else {
                window.utils.alert('error', message);
            }
        });
    };
    const [loading, setLoading] = useState(false);
    const getJob = () => {
        const info = JSON.parse(window.sessionStorage.getItem('jobInfo') || "{jobsPOJO:{jobId:''}}");
        if (info.jobsPOJO.jobId) {
            setJobInfo({ ...info });
            setLoading(true);
            if (info.from !== 'home') {
                getApplication(info);
                return;
            }
            api.getJobInfo({ jobsId: info.jobsPOJO.jobId }, res => {
                const { code, data, message } = res;
                setLoading(false);
                if (code === 200) {
                    data.pushTimeShow = data.jobsPOJO.publishTime
                        ? window.utils.foramtTimeDMY(data.jobsPOJO.publishTime * 1000)
                        : '-';
                    data.jobTypeShow =
                        data.jobsPOJO.jobType === '1'
                            ? 'Full-Time'
                            : data.jobsPOJO.jobType === '2'
                            ? 'Part-Time'
                            : data.jobsPOJO.jobType === '3'
                            ? 'Contractor'
                            : 'Full-Time';
                    if (data.jobsPOJO.associatedRoles) {
                        data.jobsPOJO.associatedRolesList = data.jobsPOJO.associatedRoles.split(',');
                    } else {
                        data.jobsPOJO.associatedRolesList = [];
                    }
                    if (data.jobsPOJO.salaryFrom) {
                        data.jobsPOJO.salaryFrom = window.utils.formatAmount(data.jobsPOJO.salaryFrom);
                        data.jobsPOJO.salaryTo = data.jobsPOJO.salaryTo
                            ? window.utils.formatAmount(data.jobsPOJO.salaryTo)
                            : '';
                    }
                    setState({ ...state, applyFlaag: false, jobInfo: { ...data }, from: info.from });
                    const match = data.matchScore || 0;
                    setTimeout(() => {
                        const userActivityTimeList = [
                            { value: match, name: 'match', percentage: match },
                            { value: 100, name: 'full', percentage: 100 },
                        ] as defaultInfo[];
                        initChart('round-chart', userActivityTimeList);
                    }, 300);
                } else {
                    window.utils.alert('error', message);
                }
            });
        }
    };
    useEffect(() => {
        getJob();
    }, []);
    const { dataBaseInfo } = useSelector((state: any) => state.state);
    useEffect(() => {
        if (dataBaseInfo?.accountId) {
            const info = JSON.parse(window.sessionStorage.getItem('jobInfo') || "{jobsPOJO:{jobId:''}}");
            if (info.jobsPOJO.jobId === dataBaseInfo.jobsId) {
                getJob();
            }
        }
    }, [dataBaseInfo]);
    return (
        <div className="home-mainBox jobDetail-mainBox">
            <ReminderList />
            {loading ? (
                <>
                    <div className="r-center no-message-tabBox">
                        <Spin style={{ position: 'static' }}></Spin>
                    </div>
                </>
            ) : (
                <div className="page-contentBox r-top">
                    <div className="job-leftInfoBox">
                        {state.from !== 'home' ? (
                            <div className="jobMsg-card" style={{ padding: 16, marginBottom: 20 }}>
                                <span
                                    className={`status-box r-center ${
                                        state.jobInfo.applicationsPOJO.applicationsStatus === 'offerReceived'
                                            ? 'offer-status'
                                            : state.jobInfo.applicationsPOJO.applicationsStatus === 'rejected'
                                            ? 'reject-status'
                                            : ''
                                    }`}
                                >
                                    {state.jobInfo.statusShow || 'Applied'}
                                </span>
                                {state.jobInfo.appliedTimeShow ? (
                                    <div className="r-top tipText-box" style={{ margin: '12px 0' }}>
                                        <img src={TimeImg} className="job-leftIcon" style={{ marginTop: 4 }} alt="" />
                                        <div>
                                            Application Date
                                            <p className="jobMin-tip op5" style={{ marginTop: 4 }}>
                                                {state.jobInfo.appliedTimeShow || '-'}
                                            </p>
                                        </div>
                                    </div>
                                ) : null}
                                {state.jobInfo.interviewTimeShow ? (
                                    <>
                                        <div className="r-top tipText-box">
                                            <img
                                                src={TimeImg}
                                                className="job-leftIcon"
                                                style={{ marginTop: 4 }}
                                                alt=""
                                            />
                                            <div>
                                                Interview Date/Time
                                                <p className="jobMin-tip op5" style={{ marginTop: 4 }}>
                                                    {state.jobInfo.interviewTimeShow}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="r-center" style={{ marginTop: 12 }}>
                                            <Button className="r-primary_btn flex1" onClick={() => addTocCalendar()}>
                                                Add to Calendar
                                            </Button>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        ) : null}
                        <div className="jobMsg-card" style={{ padding: 16 }}>
                            <Image
                                className="comp-icon"
                                preview={false}
                                style={{ minWidth: 94 }}
                                src={state.jobInfo.jobsPOJO.employerLogo ? state.jobInfo.jobsPOJO.employerLogo : ''}
                                fallback={'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg'}
                            />
                            <p className="job-tit">{state.jobInfo.jobsPOJO.jobTitle || '-'}</p>
                            <p className="jobMin-tip" style={{ margin: '8px 0' }}>
                                {state.jobInfo.jobsPOJO.employerName || '-'}
                            </p>
                            <p className="jobMin-tip op5">Published on {state.jobInfo.pushTimeShow || '-'}</p>
                            <div className="r-left tipText-box" style={{ margin: '16px 0 12px 0' }}>
                                <img src={LocationImg} className="job-leftIcon" alt="" />
                                {!state.jobInfo.jobsPOJO.remoteFlag
                                    ? state.jobInfo.jobsPOJO.location || 'Manchester, UK'
                                    : 'Remote'}
                                {/* `${state.jobInfo.jobsPOJO.gmtFrom} - ${state.jobInfo.jobsPOJO.gmtTo}` */}
                            </div>
                            <div className="r-left tipText-box">
                                <img src={TimeImg} className="job-leftIcon" alt="" />
                                {state.jobInfo.jobTypeShow || '-'}
                            </div>
                            {state.jobInfo.jobsPOJO.salaryFrom ? (
                                <div className="r-left tipText-box" style={{ marginTop: 12 }}>
                                    <img src={MoneyImg} className="job-leftIcon" alt="" />£
                                    {state.jobInfo.jobsPOJO.salaryFrom}-{state.jobInfo.jobsPOJO.salaryTo}
                                </div>
                            ) : null}
                        </div>
                        <div className="jobMsg-card mart20">
                            <p className="jobRelevancy-tit primary-color">JOB RELEVANCY</p>
                            <div className="chart-box">
                                <div id="round-chart" style={{ marginBottom: 0 }}></div>
                                <span className="per-tip">{state.jobInfo?.matchScore || 0}%</span>
                            </div>
                            {!state.jobInfo.applicationsId && state.jobInfo.jobsPOJO.jobStepStatus !== 'Archvie' ? (
                                <div className="r-center" style={{ marginTop: 25 }}>
                                    <Button
                                        className="r-primary_btn r-primary_largeBtn flex1"
                                        onClick={() => setOpenDrawer(true)}
                                    >
                                        Apply
                                    </Button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {state.jobInfo.jobsPOJO.jobStepStatus === 'Archvie' ? (
                        <div className="close-job r-center">
                            <div className="close-tit">This job role is closed.</div>
                        </div>
                    ) : (
                        <div className="job-rightInfoBox">
                            <div className="jobMsg-card">
                                <p className="card-titBox">{state.jobInfo.jobsPOJO.employerName || '-'}</p>
                                <p className="card-tipBox break-text">
                                    {state.jobInfo.jobsPOJO.aboutTheCompany || '-'}
                                </p>
                            </div>
                            {state.jobInfo.jobsPerksPOJOList && state.jobInfo.jobsPerksPOJOList.length ? (
                                <div className="jobMsg-card mart20" style={{ paddingBottom: 10 }}>
                                    <p className="card-titBox">Perk Description</p>
                                    {state.jobInfo.jobsPerksPOJOList.map((val, i) => {
                                        return (
                                            <div key={`perkbox-${i}`}>
                                                <p className="card-tipBox medium_btn">{val.perksTitle || ''}</p>
                                                <p className="card-tipBox break-text" style={{ margin: '10px 0' }}>
                                                    {val.perksDescription || ''}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : null}
                            <div className="jobMsg-card mart20">
                                <p className="card-titBox">Job Description</p>
                                <p className="card-tipBox break-text">{state.jobInfo.jobsPOJO.jobDescription || '-'}</p>
                            </div>
                            <div className="jobMsg-card mart20">
                                <p className="card-titBox">Key Responsibilities</p>
                                <p className="card-tipBox r-left break-text">
                                    {state.jobInfo.jobsPOJO.keyResponsibilities || '-'}
                                </p>
                                {/* <p className="card-tipBox showBe-box r-left"></p> */}
                            </div>
                            <div className="jobMsg-card mart20" style={{ paddingBottom: 16 }}>
                                <div className="card-titBox r-left">
                                    Skills
                                    <Tooltip
                                        overlayClassName="skill-tooltipBox"
                                        // placement="topLeft"
                                        title={
                                            <div className="tooltip-text">
                                                <div className="title-box">Showcase Your Skills</div>
                                                <div>
                                                    Highlight your soft skills (like teamwork and leadership) and hard
                                                    skills (like technical expertise) to stand out to employers.
                                                </div>
                                                <br />
                                                <div>Why It Matters</div>
                                                <div>
                                                    <span className="title-box">· Better Matches</span>: Talenaut’s AI
                                                    connects you with roles that align perfectly with your skills and
                                                    expertise, increasing your chances of landing the right job.
                                                </div>
                                                <div>
                                                    <span className="title-box">· Stand Out</span>: Go beyond a
                                                    traditional resume to showcase your full capabilities, potential,
                                                    and value. · Appeal to Employers: Present a well-rounded profile
                                                    that highlights both technical expertise and personal attributes,
                                                    showing how you’ll contribute to their team.
                                                </div>
                                                <div>
                                                    <span className="title-box">· Attract Opportunities</span>: Engage
                                                    hiring managers by giving them a comprehensive view of what makes
                                                    you unique.
                                                </div>
                                            </div>
                                        }
                                    >
                                        <i className="round-tipIcon"></i>
                                    </Tooltip>
                                </div>
                                <p className="card-tipBox medium_btn" style={{ marginBottom: 10 }}>
                                    Hard Skills
                                </p>
                                <div style={{ marginBottom: 12 }}>
                                    <RTabs
                                        isShowDel={false}
                                        tabLists={
                                            state.jobInfo.hardSkillPOJOList
                                                ? state.jobInfo.hardSkillPOJOList.map((v: any) => v.skillName)
                                                : []
                                        }
                                    />
                                </div>
                                <p className="card-tipBox medium_btn" style={{ marginBottom: 10 }}>
                                    Soft Skills
                                </p>
                                <div>
                                    <RTabs
                                        isShowDel={false}
                                        tabLists={
                                            state.jobInfo.softSkillPOJOList
                                                ? state.jobInfo.softSkillPOJOList.map((v: any) => v.skillName)
                                                : []
                                        }
                                    />
                                </div>
                            </div>
                            {state.jobInfo.jobsPOJO.associatedRolesList.length ? (
                                <div className="jobMsg-card mart20">
                                    <p className="card-titBox">Associated Roles</p>
                                    {state.jobInfo.jobsPOJO.associatedRolesList.map((val, i) => {
                                        return (
                                            <p className="card-tipBox showBe-box r-left" key={`Associated-role-${i}`}>
                                                {val}
                                            </p>
                                        );
                                    })}
                                </div>
                            ) : null}
                            {state.from !== 'home' ? (
                                <div className="jobMsg-card mart20">
                                    <p className="card-titBox">Screening Questions</p>
                                    {state.jobInfo.applicationsPOJO.screeningQuestionsListPOJO.map((item, i) => {
                                        return (
                                            <div key={`screening-box-${i}`}>
                                                {item.questionTypes === 'Short Answer' ? (
                                                    <div
                                                        style={{
                                                            marginBottom:
                                                                state.jobInfo.applicationsPOJO
                                                                    .screeningQuestionsListPOJO.length -
                                                                    1 ==
                                                                i
                                                                    ? 0
                                                                    : 20,
                                                        }}
                                                    >
                                                        <p
                                                            className="card-tipBox medium_btn"
                                                            style={{ marginBottom: 8 }}
                                                        >
                                                            {item.questionDescription}({item.questionTypes})
                                                        </p>
                                                        <p className="card-tipBox r-left break-text">
                                                            {item.questionsOptionsPOJOList[0]?.optionsValue || '-'}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            marginBottom:
                                                                state.jobInfo.applicationsPOJO
                                                                    .screeningQuestionsListPOJO.length -
                                                                    1 ==
                                                                i
                                                                    ? 0
                                                                    : 20,
                                                        }}
                                                    >
                                                        <p
                                                            className="card-tipBox medium_btn"
                                                            style={{ marginBottom: 8 }}
                                                        >
                                                            {item.questionDescription}({item.questionTypes})
                                                        </p>
                                                        <div>
                                                            {item.questionsOptionsPOJOList.map((val, k) => {
                                                                return (
                                                                    <div
                                                                        className="r-left seclectItem"
                                                                        key={`jobscreening-op-${i}-${k}`}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                !val.optionsValue ||
                                                                                !val.optionsValue.includes(
                                                                                    val.optionsId
                                                                                )
                                                                                    ? RadioButtonWhite
                                                                                    : RadioButton
                                                                            }
                                                                            className="selected_icon"
                                                                            alt=""
                                                                        />
                                                                        <span>{val.options}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : null}
                        </div>
                    )}
                </div>
            )}

            <ApplyJob
                open={openDrawer}
                val={state.jobInfo}
                onApply={val => apply(val)}
                onClose={() => setOpenDrawer(false)}
            />
            <AlertModal
                message="You haven't connected the calendar yet."
                cancelText="Cancel"
                confirmText="Connect Calendar"
                isModalOpen={isModalOpen}
                handleOk={() => {
                    navigate('/profile');
                }}
                handleCancel={handleCancel}
            />
        </div>
    );
};

export default JobDetail;
