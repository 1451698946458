import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectFade } from 'swiper/modules';
import CalendarSchedule from '@/assets/img/Calendar-Schedule.png';
import api from '@/api/job';
import { useEffect, useState } from 'react';
import { defaultInfo } from '@/typings/allType';

const ReminderList = () => {
    const [list, setList] = useState<defaultInfo>([]);

    const getRemiList = () => {
        api.getReminderList({}, res => {
            const { code, data, message } = res;
            if (code === 200) {
                setList(data || []);
            } else {
                window.utils.alert('error', message);
            }
        });
    };
    const close = id => {
        api.closeReminder({ applicationsId: id }, res => {
            const { code, data, message } = res;
            if (code === 200) {
                getRemiList();
            } else {
                window.utils.alert('error', message);
            }
        });
    };
    let timer: any;
    useEffect(() => {
        getRemiList();
        timer = setInterval(() => {
            getRemiList();
        }, 60000);
        return () => {
            clearInterval(timer);
        };
    }, []);
    return (
        <div className="noticebar-list-container" style={{ height: list.length ? 36 : 0 }}>
            {list.length ? (
                <Swiper
                    direction="vertical"
                    loop={true}
                    autoplay={{ delay: 5000 }}
                    speed={500}
                    modules={[Autoplay, EffectFade]}
                >
                    {list.map((item, i) => (
                        <SwiperSlide key={i} style={{ height: '36px !important' }}>
                            <div className="top-alertBox r-left">
                                <img src={CalendarSchedule} className="calendarSchedule-icon" alt="" />
                                <div className="cal-msgBox">
                                    Reminder:
                                    <span className="msg-text">{item.message || '-'}</span>
                                </div>
                                <i
                                    className="remove-white-icon marLeft"
                                    onClick={() => {
                                        close(item.applicationsId);
                                    }}
                                ></i>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            ) : null}
        </div>
    );
};

export default ReminderList;
