import React, { useEffect, useState } from 'react';
// import './style/index.scss';
import LocationImg from '@/assets/img/location.png';
import TimeImg from '@/assets/img/Time.png';
import MoneyImg from '@/assets/img/money.png';
import TipMarkImg from '@/assets/img/tip-mark.png';
import RadioButton from '@/assets/img/RadioButton.png';
import RadioButtonWhite from '@/assets/img/RadioButton-w.png';
import { Button, Image, Spin, Tooltip } from 'antd';
import { defaultInfo } from '@/typings/allType';
import RTabs from '@/views/pages/home/components/tabs';
import api from '@/api/job';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
const JobInformation = () => {
    const navigate = useNavigate();
    const [state, setState] = useState({
        status: 1,
        from: 'home',
        applyFlaag: false,
        jobInfo: {
            jobId: '',
            screeningQuestionsPOJOList: [] as defaultInfo[],
            applicationsId: '',
            pushTimeShow: '-',
            interviewTimeShow: '-',
            jobTypeShow: '-',
            hardSkillPOJOList: [],
            interviewScorecardQuestionsPOJOList: [],
            jobsPerksPOJOList: [] as defaultInfo[],
            softSkillPOJOList: [] as defaultInfo[],
            match: 0,
            statusShow: '',
            applicationsPOJO: {
                applicationsStatus: '',
                screeningQuestionsListPOJO: [] as defaultInfo[],
            },
            appliedTimeShow: '',
            jobsPOJO: {
                jobTitle: '',
                jobDescription: '',
                employerName: '',
                aboutTheCompany: '',
                location: '',
                salaryFrom: '',
                salaryTo: '',
                keyResponsibilities: '',
                associatedRoles: '',
                associatedRolesList: [],
                employerLogo: '',
                remoteFlag: 0,
                gmtFrom: '',
                gmtTo: '',
            },
        },
    });
    const [jobInfo, setJobInfo] = useState({
        statusShow: 'Applied',
        appliedTimeShow: '',
        applicationsPOJO: {
            businessId: '',
            accountId: '',
            status: 'Actively Interested',
            appliedTime: 0,
            inReviewTime: 0,
            interviewScheduledTime: 0,
            offerReceivedTime: 0,
            rejectedTime: 0,
            createTime: 0,
        },
        jobsPOJO: {
            jobId: '',
        },
    });
    const [loading, setLoading] = useState(false);
    const getJob = jobId => {
        if (jobId) {
            setLoading(true);
            api.getJobInfo({ jobsId: jobId }, res => {
                const { code, data, message } = res;
                setLoading(false);
                if (code === 200) {
                    data.pushTimeShow = data.jobsPOJO.publishTime
                        ? window.utils.foramtTimeDMY(data.jobsPOJO.publishTime * 1000)
                        : '-';
                    data.jobTypeShow =
                        data.jobsPOJO.jobType === '1'
                            ? 'Full-Time'
                            : data.jobsPOJO.jobType === '2'
                            ? 'Part-Time'
                            : data.jobsPOJO.jobType === '3'
                            ? 'Contractor'
                            : 'Full-Time';
                    if (data.jobsPOJO.associatedRoles) {
                        data.jobsPOJO.associatedRolesList = data.jobsPOJO.associatedRoles.split(',');
                    } else {
                        data.jobsPOJO.associatedRolesList = [];
                    }
                    if (data.jobsPOJO.salaryFrom) {
                        data.jobsPOJO.salaryFrom = window.utils.formatAmount(data.jobsPOJO.salaryFrom);
                        data.jobsPOJO.salaryTo = data.jobsPOJO.salaryTo
                            ? window.utils.formatAmount(data.jobsPOJO.salaryTo)
                            : '';
                    }
                    setState({ ...state, applyFlaag: false, jobInfo: { ...data } });
                } else {
                    window.utils.alert('error', message);
                }
            });
        } else {
            window.utils.alert('error', 'params error.');
        }
    };
    const location = useLocation();
    useEffect(() => {
        console.log('====================================');
        console.log(location);
        console.log('====================================');
        const params = new URLSearchParams(location.search);
        const id = params.get('id');
        // const jobId = location.jobId;
        getJob(id);
    }, []);
    return (
        <div className="home-mainBox jobDetail-mainBox">
            {loading ? (
                <>
                    <div className="r-center no-message-tabBox">
                        <Spin style={{ position: 'static' }}></Spin>
                    </div>
                </>
            ) : (
                <div className="login-main information-main">
                    <div className="nav-top r-left">
                        <img className="left-logo" src={require('@/assets/img/talenaut_logo-color.png')} alt="" />
                    </div>
                    <div className="page-contentBox r-top">
                        <div className="job-leftInfoBox">
                            <div className="jobMsg-card" style={{ padding: 16 }}>
                                <Image
                                    className="comp-icon"
                                    preview={false}
                                    style={{ minWidth: 94 }}
                                    src={state.jobInfo.jobsPOJO.employerLogo ? state.jobInfo.jobsPOJO.employerLogo : ''}
                                    fallback={'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg'}
                                />
                                <p className="job-tit">{state.jobInfo.jobsPOJO.jobTitle || '-'}</p>
                                <p className="jobMin-tip" style={{ margin: '8px 0' }}>
                                    {state.jobInfo.jobsPOJO.employerName || '-'}
                                </p>
                                <p className="jobMin-tip op5">Published on {state.jobInfo.pushTimeShow || '-'}</p>
                                <div className="r-left tipText-box" style={{ margin: '16px 0 12px 0' }}>
                                    <img src={LocationImg} className="job-leftIcon" alt="" />
                                    {!state.jobInfo.jobsPOJO.remoteFlag
                                        ? state.jobInfo.jobsPOJO.location || 'Manchester, UK'
                                        : 'Remote'}
                                    {/* `${state.jobInfo.jobsPOJO.gmtFrom} - ${state.jobInfo.jobsPOJO.gmtTo}` */}
                                </div>
                                <div className="r-left tipText-box">
                                    <img src={TimeImg} className="job-leftIcon" alt="" />
                                    {state.jobInfo.jobTypeShow || '-'}
                                </div>
                                {state.jobInfo.jobsPOJO.salaryFrom ? (
                                    <div className="r-left tipText-box" style={{ marginTop: 12 }}>
                                        <img src={MoneyImg} className="job-leftIcon" alt="" />£
                                        {state.jobInfo.jobsPOJO.salaryFrom}-{state.jobInfo.jobsPOJO.salaryTo}
                                    </div>
                                ) : null}
                            </div>
                            <div className="jobMsg-card mart20">
                                <div className="r-center">
                                    <Button
                                        className="r-primary_btn r-primary_largeBtn flex1"
                                        onClick={() => {
                                            window.sessionStorage.setItem(
                                                'jobInfo',
                                                JSON.stringify({ ...state.jobInfo, from: 'home' })
                                            );
                                            navigate(`/login?jobId=${state.jobInfo.jobId}`);
                                        }}
                                    >
                                        Apply
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="job-rightInfoBox">
                            <div className="jobMsg-card">
                                <p className="card-titBox">{state.jobInfo.jobsPOJO.employerName || '-'}</p>
                                <p className="card-tipBox break-text">
                                    {state.jobInfo.jobsPOJO.aboutTheCompany || '-'}
                                </p>
                            </div>
                            {state.jobInfo.jobsPerksPOJOList && state.jobInfo.jobsPerksPOJOList.length ? (
                                <div className="jobMsg-card mart20" style={{ paddingBottom: 10 }}>
                                    <p className="card-titBox">Perk Description</p>
                                    {state.jobInfo.jobsPerksPOJOList.map((val, i) => {
                                        return (
                                            <div key={`perkbox-${i}`}>
                                                <p className="card-tipBox medium_btn">{val.perksTitle || ''}</p>
                                                <p className="card-tipBox" style={{ margin: '10px 0' }}>
                                                    {val.perksDescription || ''}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : null}
                            <div className="jobMsg-card mart20">
                                <p className="card-titBox">Job Description</p>
                                <p className="card-tipBox break-text">{state.jobInfo.jobsPOJO.jobDescription || '-'}</p>
                            </div>
                            <div className="jobMsg-card mart20">
                                <p className="card-titBox">Key Responsibilities</p>
                                <p className="card-tipBox r-left break-text">
                                    {state.jobInfo.jobsPOJO.keyResponsibilities || '-'}
                                </p>
                                {/* <p className="card-tipBox showBe-box r-left"></p> */}
                            </div>
                            <div className="jobMsg-card mart20" style={{ paddingBottom: 16 }}>
                                <div className="card-titBox r-left">
                                    Skills
                                    <Tooltip
                                        overlayClassName="skill-tooltipBox"
                                        // placement="topLeft"
                                        title={
                                            <div className="tooltip-text">
                                                <div className="title-box">Showcase Your Skills</div>
                                                <div>
                                                    Highlight your soft skills (like teamwork and leadership) and hard
                                                    skills (like technical expertise) to stand out to employers.
                                                </div>
                                                <br />
                                                <div>Why It Matters</div>
                                                <div>
                                                    <span className="title-box">· Better Matches</span>: Talenaut’s AI
                                                    connects you with roles that align perfectly with your skills and
                                                    expertise, increasing your chances of landing the right job.
                                                </div>
                                                <div>
                                                    <span className="title-box">· Stand Out</span>: Go beyond a
                                                    traditional resume to showcase your full capabilities, potential,
                                                    and value. · Appeal to Employers: Present a well-rounded profile
                                                    that highlights both technical expertise and personal attributes,
                                                    showing how you’ll contribute to their team.
                                                </div>
                                                <div>
                                                    <span className="title-box">· Attract Opportunities</span>: Engage
                                                    hiring managers by giving them a comprehensive view of what makes
                                                    you unique.
                                                </div>
                                            </div>
                                        }
                                    >
                                        <i className="round-tipIcon"></i>
                                    </Tooltip>
                                </div>
                                <p className="card-tipBox medium_btn" style={{ marginBottom: 10 }}>
                                    Hard Skills
                                </p>
                                <div style={{ marginBottom: 12 }}>
                                    <RTabs
                                        isShowDel={false}
                                        tabLists={
                                            state.jobInfo.hardSkillPOJOList
                                                ? state.jobInfo.hardSkillPOJOList.map((v: any) => v.skillName)
                                                : []
                                        }
                                    />
                                </div>
                                <p className="card-tipBox medium_btn" style={{ marginBottom: 10 }}>
                                    Soft Skills
                                </p>
                                <div>
                                    <RTabs
                                        isShowDel={false}
                                        tabLists={
                                            state.jobInfo.softSkillPOJOList
                                                ? state.jobInfo.softSkillPOJOList.map((v: any) => v.skillName)
                                                : []
                                        }
                                    />
                                </div>
                            </div>
                            {state.jobInfo.jobsPOJO.associatedRolesList.length ? (
                                <div className="jobMsg-card mart20">
                                    <p className="card-titBox">Associated Roles</p>
                                    {state.jobInfo.jobsPOJO.associatedRolesList.map((val, i) => {
                                        return (
                                            <p className="card-tipBox showBe-box r-left" key={`Associated-role-${i}`}>
                                                {val}
                                            </p>
                                        );
                                    })}
                                </div>
                            ) : null}
                            {state.from !== 'home' ? (
                                <div className="jobMsg-card mart20">
                                    <p className="card-titBox">Screening Questions</p>
                                    {state.jobInfo.applicationsPOJO.screeningQuestionsListPOJO.map((item, i) => {
                                        return (
                                            <div key={`screening-box-${i}`}>
                                                {item.questionTypes === 'Short Answer' ? (
                                                    <div style={{ marginBottom: 20 }}>
                                                        <p
                                                            className="card-tipBox medium_btn"
                                                            style={{ marginBottom: 8 }}
                                                        >
                                                            {item.questionDescription}({item.questionTypes})
                                                        </p>
                                                        <p className="card-tipBox r-left">
                                                            {item.questionsOptionsPOJOList[0]?.optionsValue || '-'}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div style={{ marginBottom: 20 }}>
                                                        <p
                                                            className="card-tipBox medium_btn"
                                                            style={{ marginBottom: 8 }}
                                                        >
                                                            {item.questionDescription}({item.questionTypes})
                                                        </p>
                                                        <div>
                                                            {item.questionsOptionsPOJOList.map((val, k) => {
                                                                return (
                                                                    <div
                                                                        className="r-left seclectItem"
                                                                        key={`jobscreening-op-${i}-${k}`}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                !val.optionsValue ||
                                                                                !val.optionsValue.includes(
                                                                                    val.optionsId
                                                                                )
                                                                                    ? RadioButtonWhite
                                                                                    : RadioButton
                                                                            }
                                                                            className="selected_icon"
                                                                            alt=""
                                                                        />
                                                                        <span>{val.options}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default JobInformation;
