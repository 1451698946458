import React, { useEffect, useRef, useState } from 'react';
import { Button, DatePicker, Form, FormProps, Input, message, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import UploadImg from '@/components/upload/upload-img';
import RCheckBox from '@/components/checkbox';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import api from '@/api/login';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import LocationInp from '@/components/autoComplate-inp/locationInp';
import VerificationCodeDialog from '@/components/modal/verification-code-dialog';
import { useCountDown } from 'ahooks';
const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
};
function PersionInformation() {
    const navigate = useNavigate();
    const messageApi = useGlobalMessage();
    const [loading, setLoading] = useState(false);
    const [loadingEmail, setLoadingEmail] = useState(false);
    const [GMTList, setGMTList] = useState([]);
    const [loginType, setLoginType] = useState('email');
    const [state, setState] = useState({
        email: '',
        profilePhoto: '',
        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        birth: '',
        ethnicity: '',
        location: '',
        remote: false,
        gmt: '',
        preferred: 'Yes',
        isSelectFromList: true,
        preferredItem: 'He/They',
        isCustome: false,
        customer: '',
    });
    const submitInfo = () => {
        setLoading(true);
        const pat = {
            emailTwo: loginType === 'email' ? '' : state.email,
            birth: dayjs(state.birth).format('MMM YYYY'),
            ethnicity: state.ethnicity,
            firstName: state.firstName,
            gender: state.gender,
            gmtId: state.gmt,
            lastName: state.lastName,
            location: state.location,
            middleName: state.middleName,
            preferredPronounsFlag: state.preferred === 'Yes' ? 1 : 0,
            preferredPronounsCustomFlag: state.isCustome ? 1 : 0,
            preferredPronounsCustom: state.customer,
            preferredPronounsListFlag: state.isSelectFromList ? 1 : 0,
            preferredPronounsList: state.preferredItem,
            profilePhoto: state.profilePhoto,
            remoteFlag: state.remote ? 1 : 0,
        };
        const persionInfo = JSON.stringify(state);
        window.sessionStorage.setItem('persionInfo', persionInfo);
        api.setPersionInformation(pat, res => {
            setLoading(false);
            if (res.code === 200) {
                navigate('/skill-information');
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const onFinish: FormProps['onFinish'] = values => {
        console.log('Success:', values);
        if (loginType !== 'email') {
            // 三方注册
            sendSignEmail();
            return;
        }
        submitInfo();
    };
    const onFinishFailed: FormProps['onFinishFailed'] = errorInfo => {
        console.log('Failed:', errorInfo);
        // navigate('/skill-information');
    };
    const [form] = Form.useForm();
    const formRef = useRef<any>(null);
    useEffect(() => {
        api.getGmtList(res => {
            if (res.code === 200) {
                const arr = res.data.map(item => Object.assign(item, { label: item.gmt, value: item.id }));
                setGMTList(arr);
            } else {
                messageApi.error(res.message);
            }
        });
        const persionInfo = window.sessionStorage.getItem('persionInfo');
        const thirdPartyType = window.sessionStorage.getItem('thirdPartyType');
        if (thirdPartyType) {
            setLoginType(thirdPartyType || 'email');
        }
        if (persionInfo) {
            const info = JSON.parse(persionInfo);
            console.log('====================================');
            console.log(info);
            console.log('====================================');
            if (!info.firstName) return;
            info.birth = info.birth ? dayjs(info.birth) : null;
            info.preferred = info.preferred ? info.preferred : info.preferredPronounsFlag === 1 ? 'Yes' : 'No';
            const initialValues = { ...info };
            setState({ ...initialValues });
            form.setFieldsValue(initialValues);
        }
    }, []);

    // send eamil code
    const [isActive, setIsActive] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);
    const [eyeOpen, setEyeOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [seconds, setSeconds] = useState<number>();
    const [codeArr, setCodeArr] = useState(['', '', '', '', '', '']);
    const [countdown] = useCountDown({
        targetDate: seconds,
        onEnd: () => {
            setSeconds(0);
            setIsActive(false);
        },
    });
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const sendSignEmail = () => {
        if (seconds || isActive) {
            messageApi.error('The operation is too frequent. Please try again later.  60s');
            return;
        }
        setLoading(true);
        setSendLoading(true);
        api.signUpSendCode({ email: state.email }, res => {
            setSendLoading(false);
            setLoading(false);
            if (res.code === 200) {
                setIsModalOpen(true);
                setSeconds(Date.now() + 60000);
                setIsActive(true);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const verificationEmailCode = () => {
        setLoadingEmail(true);
        api.signUpCheckCode(
            {
                email: state.email,
                code: codeArr.join(''),
            },
            res => {
                const { code, data } = res;
                if (code === 200) {
                    // signAcc();
                    setIsModalOpen(false);
                    setLoadingEmail(false);
                    submitInfo();
                } else {
                    window.utils.alert('error', res.message);
                    setLoadingEmail(false);
                }
            }
        );
    };
    return (
        <div className="login-main information-main">
            <div className="nav-top r-left">
                <img className="left-logo" src={require('@/assets/img/talenaut_logo-color.png')} alt="" />
            </div>
            <div className="login-formMin r-center">
                <div className="informationCenter-box">
                    <div className="info-tit">Personal Information</div>
                    <p className="info-tip">
                        Please complete your personal information to search for new job opportunities.
                    </p>
                    <div className="pri-tit">General Information</div>
                    <div className="information-formBox">
                        <Form
                            name="basic-persionInfo"
                            layout="vertical"
                            initialValues={{ ...state }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            form={form}
                            ref={formRef}
                            autoComplete="off"
                            validateTrigger="onBlur"
                        >
                            <Form.Item
                                label="Profile Photo"
                                name="profilePhoto"
                                rules={[{ required: true, message: 'Please upload a profile image' }]}
                            >
                                <UploadImg
                                    imgUrl={state.profilePhoto}
                                    setPicInfo={(val: any) => {
                                        // setState({ ...state, profilePhoto: val.picUrl });
                                        setState(prev => ({
                                            ...prev,
                                            profilePhoto: val.picUrl,
                                        }));
                                        form.setFieldsValue({ profilePhoto: val.picUrl });
                                    }}
                                />
                            </Form.Item>
                            <div className="r-left">
                                <Form.Item
                                    className="flex1"
                                    label="First Name"
                                    name="firstName"
                                    rules={[{ required: true, message: 'Please enter a first name' }]}
                                >
                                    <Input
                                        className="r-input"
                                        maxLength={20}
                                        value={state.firstName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const str = e.target.value;
                                            const val = str.charAt(0).toUpperCase() + str.slice(1);
                                            form.setFieldsValue({ firstName: val });
                                            // setState({ ...state, firstName: val });
                                            setState(prev => ({
                                                ...prev,
                                                firstName: val,
                                            }));
                                            console.log(val);
                                        }}
                                        placeholder=""
                                    />
                                </Form.Item>
                                <span className="space20"></span>
                                <Form.Item
                                    className="flex1"
                                    label="Middle Name (optional)"
                                    name="middleName"
                                    rules={[{ required: false, message: '' }]}
                                >
                                    <Input
                                        className="r-input"
                                        maxLength={20}
                                        value={state.middleName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const str = e.target.value;
                                            const val = str.charAt(0).toUpperCase() + str.slice(1);
                                            setState({ ...state, middleName: val });
                                            form.setFieldsValue({ middleName: val });
                                        }}
                                        placeholder=""
                                    />
                                </Form.Item>
                                <span className="space20"></span>
                                <Form.Item
                                    className="flex1"
                                    label="Last Name"
                                    name="lastName"
                                    rules={[{ required: true, message: 'Please enter a last name' }]}
                                >
                                    <Input
                                        className="r-input"
                                        maxLength={20}
                                        value={state.lastName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const str = e.target.value;
                                            const val = str.charAt(0).toUpperCase() + str.slice(1);
                                            setState({ ...state, lastName: val });
                                            form.setFieldsValue({ lastName: val });
                                        }}
                                        placeholder=""
                                    />
                                </Form.Item>
                            </div>
                            <div>
                                {loginType === 'email' ? null : (
                                    <Form.Item
                                        label="Email Address"
                                        name="email"
                                        rules={[
                                            {
                                                required: loginType !== 'email',
                                                message: 'Please enter a valid email address',
                                            },
                                            {
                                                type: 'email',
                                                message: 'Please enter a valid email address',
                                            },
                                        ]}
                                    >
                                        <Input
                                            className="r-input"
                                            value={state.email}
                                            onInput={(e: any) => setState({ ...state, email: e.target.value })}
                                        />
                                    </Form.Item>
                                )}
                            </div>
                            <div className="r-left">
                                <Form.Item
                                    className="flex1"
                                    label="Gender (optional)"
                                    name="gender"
                                    rules={[{ required: false, message: '' }]}
                                >
                                    <Select
                                        className="r-select"
                                        value={state.gender}
                                        onChange={val => {
                                            form.setFieldsValue({ gender: val });
                                            setState({ ...state, gender: val });
                                        }}
                                        suffixIcon={<i className="down-right-icon"></i>}
                                        options={[
                                            { value: 'Male', label: 'Male' },
                                            { value: 'Female', label: 'Female' },
                                            { value: 'Other', label: 'Other' },
                                        ]}
                                    />
                                </Form.Item>
                                <span className="space20"></span>
                                <Form.Item
                                    className="flex1"
                                    label="Month/Year of Birth"
                                    name="birth"
                                    rules={[{ required: true, message: 'Please select your Month/Year of Birth!' }]}
                                >
                                    <DatePicker
                                        className="r-datePicker w100"
                                        placeholder=" "
                                        suffixIcon={<i className="allow_time_icon"></i>}
                                        disabledDate={disabledDate}
                                        format="MMM YYYY"
                                        value={state.birth}
                                        onChange={val => {
                                            form.setFieldsValue({ birth: val });
                                            setState({ ...state, birth: val });
                                        }}
                                        picker="month"
                                    />
                                </Form.Item>
                            </div>
                            <Form.Item
                                label="Ethnicity (optional)"
                                name="ethnicity"
                                rules={[{ required: false, message: '' }]}
                            >
                                <Select
                                    className="r-select"
                                    suffixIcon={<i className="down-right-icon"></i>}
                                    value={state.ethnicity}
                                    onChange={val => {
                                        form.setFieldsValue({ ethnicity: val });
                                        setState({ ...state, ethnicity: val });
                                    }}
                                    options={[
                                        { value: 'White', label: 'White' },
                                        { value: 'Black', label: 'Black' },
                                        { value: 'Asian', label: 'Asian' },
                                        { value: 'Mixed', label: 'Mixed' },
                                        { value: 'Other', label: 'Other' },
                                    ]}
                                />
                            </Form.Item>
                            <div className="r-left">
                                <Form.Item
                                    className="flex1"
                                    label="Current Location/City"
                                    name="location"
                                    rules={[{ required: !state.remote, message: 'Please enter your country and city' }]}
                                >
                                    {/* <Input
                                        className="r-input"
                                        maxLength={100}
                                        disabled={state.remote}
                                        value={state.location}
                                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setState({ ...state, location: e.target.value });
                                            form.setFieldsValue({ location: e.target.value });
                                        }}
                                        placeholder="Start Typing..."
                                    /> */}
                                    <LocationInp
                                        setLocation={val => {
                                            setState({ ...state, location: val.address });
                                            form.setFieldsValue({ location: val.address });
                                        }}
                                        placeholder=" "
                                        height={44}
                                        disabled={state.remote}
                                        isFinshClear={false}
                                        value={state.location}
                                        suffixIcon={<i className="down-right-icon"></i>}
                                        classStr="r-autoComplate r-autoComplate-bg"
                                    />
                                </Form.Item>
                                <span className="space20"></span>
                                <div className="r-left flex1">
                                    <div style={{ marginTop: 10 }}>
                                        <RCheckBox
                                            checkInfo={{
                                                isChecked: state.remote,
                                                label: 'Remote',
                                            }}
                                            width="auto"
                                            setCheckInfo={() => {
                                                form.setFieldsValue({});
                                                setState({ ...state, remote: !state.remote });
                                                formRef.current.validateFields(['gmt', 'location']);
                                            }}
                                        />
                                    </div>
                                    <span className="space20"></span>
                                    <Form.Item
                                        style={{ width: 305 }}
                                        label="GMT range"
                                        name="gmt"
                                        className="flex1 op5-itemLabel"
                                        rules={[{ required: state.remote, message: 'Please select a GMT time zone' }]}
                                    >
                                        <div className="r-left">
                                            <Select
                                                className="r-select"
                                                disabled={!state.remote}
                                                value={state.gmt}
                                                onChange={val => {
                                                    form.setFieldsValue({ gmt: 'val' });
                                                    setState({ ...state, gmt: val });
                                                }}
                                                suffixIcon={<i className="down-right-icon"></i>}
                                                options={GMTList}
                                            />
                                        </div>
                                    </Form.Item>
                                </div>
                            </div>
                            <Form.Item
                                label="Preferred Pronouns"
                                name="preferred"
                                rules={[{ required: true, message: '' }]}
                            >
                                <Select
                                    className="r-select"
                                    value={state.preferred}
                                    onChange={val => {
                                        form.setFieldsValue({ preferred: val });
                                        setState({ ...state, preferred: val });
                                    }}
                                    suffixIcon={<i className="down-right-icon"></i>}
                                    options={[
                                        { value: 'Yes', label: 'Yes' },
                                        { value: 'No', label: 'No' },
                                    ]}
                                />
                            </Form.Item>
                            {state.preferred === 'Yes' ? (
                                <div className="r-left">
                                    <Form.Item
                                        label={null}
                                        name="preferredItem"
                                        className="flex1"
                                        style={{ margin: 0 }}
                                        rules={[
                                            {
                                                required: state.isSelectFromList && state.preferred === 'Yes',
                                                message: 'Please enter a custom pronoun',
                                            },
                                        ]}
                                    >
                                        <div className="r-left">
                                            <RCheckBox
                                                checkInfo={{
                                                    isChecked: state.isSelectFromList,
                                                    label: 'Select from a list',
                                                }}
                                                width={122}
                                                setCheckInfo={() => {
                                                    form.setFieldsValue({ customer: '', preferredItem: 'He/They' });
                                                    setState({
                                                        ...state,
                                                        isSelectFromList: !state.isSelectFromList,
                                                        isCustome: false,
                                                        customer: '',
                                                    });
                                                    formRef.current.validateFields(['customer']);
                                                }}
                                            />
                                            <span className="space20"></span>
                                            <Select
                                                className="r-select"
                                                value={state.preferredItem}
                                                onChange={val => {
                                                    form.setFieldsValue({ preferredItem: val });
                                                    setState({ ...state, preferredItem: val });
                                                }}
                                                suffixIcon={<i className="down-right-icon"></i>}
                                                options={[
                                                    { value: 'He/They', label: 'He/They' },
                                                    { value: 'He/Him', label: 'He/Him' },
                                                    { value: 'They/He', label: 'They/He' },
                                                    { value: 'She/They', label: 'She/They' },
                                                    { value: 'She/Her', label: 'She/Her' },
                                                    { value: 'They/She', label: 'They/She' },
                                                    { value: 'They/Them', label: 'They/Them' },
                                                ]}
                                            />
                                        </div>
                                    </Form.Item>
                                    <span className="space20"></span>
                                    <div className="r-left flex1">
                                        <div>
                                            <RCheckBox
                                                checkInfo={{
                                                    isChecked: state.isCustome,
                                                    label: 'Custom',
                                                }}
                                                width={58}
                                                setCheckInfo={() => {
                                                    form.setFieldsValue({ customer: '', preferredItem: 'He/They' });
                                                    setState({
                                                        ...state,
                                                        isCustome: !state.isCustome,
                                                        isSelectFromList: false,
                                                        customer: '',
                                                    });
                                                    formRef.current.validateFields(['customer']);
                                                }}
                                            />
                                        </div>
                                        <span className="space20"></span>
                                        <Form.Item
                                            label={null}
                                            name="customer"
                                            className="flex1"
                                            style={{ margin: 0 }}
                                            rules={[
                                                {
                                                    required: state.isCustome && state.preferred === 'Yes',
                                                    message: 'Please enter a custom pronoun',
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="r-input"
                                                disabled={!state.isCustome}
                                                value={state.customer}
                                                onInput={(e: any) => {
                                                    form.setFieldsValue({ customer: e.target.value });
                                                    setState({ ...state, customer: e.target.value });
                                                }}
                                                maxLength={50}
                                                placeholder=""
                                            />
                                        </Form.Item>
                                    </div>
                                </div>
                            ) : null}
                            <Form.Item style={{ margin: '30px 0 0 0' }}>
                                <Button
                                    className="r-primary_btn r-primary_largeBtn"
                                    style={{ width: 208 }}
                                    htmlType="submit"
                                    loading={loading}
                                >
                                    Continue
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>

            <VerificationCodeDialog
                setCodeArr={setCodeArr}
                sendForgetEmail={sendSignEmail}
                seconds={countdown}
                codeArr={codeArr}
                isActive={isActive}
                loading={loadingEmail}
                verificationEmailCode={verificationEmailCode}
                isModalOpen={isModalOpen}
                handleCancel={handleCancel}
            />
        </div>
    );
}

export default PersionInformation;
