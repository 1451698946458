import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { useState } from 'react';
import { useAppDispatch } from '@/store/hooks';
import { useSelector } from 'react-redux';
import { setDataBaseInfo } from '@/store/stateSlice';
const useFireBase = () => {
    const dispatch = useAppDispatch();
    const { feedBackCount, locationFeedBackCount } = useSelector((state: any) => state.state);
    // const firebaseConfig = {
    //     apiKey: 'AIzaSyDqYthxuxU75Qoo06CFOKAwdou85Oj6US0',
    //     authDomain: 'boreal-augury-336302.firebaseapp.com',
    //     databaseURL: 'https://boreal-augury-336302-default-rtdb.firebaseio.com',
    //     projectId: 'boreal-augury-336302',
    //     storageBucket: 'boreal-augury-336302.appspot.com',
    //     messagingSenderId: '654889986277',
    //     appId: '1:654889986277:web:67b80674cd1f8b96b50c0c',
    //     measurementId: 'G-X6VLW4ZNLE',
    // };
    const firebaseConfig = {
        apiKey: 'AIzaSyDX48vQcrm1J3z2OhxzdHzW3aQITnHgZ-4',
        authDomain: 'talenaut-in-use.firebaseapp.com',
        databaseURL: 'https://talenaut-in-use-default-rtdb.europe-west1.firebasedatabase.app',
        projectId: 'talenaut-in-use',
        storageBucket: 'talenaut-in-use.appspot.com',
        messagingSenderId: '604511413297',
        appId: '1:604511413297:web:d5e80cda49b14ffdef2110',
        measurementId: 'G-WNDYD1P1X7',
    };
    const [state, setState] = useState({
        time: Date.parse(new Date() as any) / 1000,
        isLocationCountFirst: true,
        isCountFirst: true,
        facebackCountRef: '' as any,
    });
    let jobCountRef: any = null;
    const app = initializeApp(firebaseConfig);
    const database = getDatabase(app);
    const initDataBase = accountId => {
        const jobCountRefNow = ref(database, `account/${accountId}/application`);
        jobCountRef = jobCountRefNow;
        onValue(jobCountRefNow, snapshot => {
            const data = snapshot.val();
            console.log('job', data);
            setState({ ...state, isLocationCountFirst: false });
            dispatch(setDataBaseInfo(data));
        });
        // const facebackCountRef = ref(database, `/feedback/flaggedReport/count`);
        // setFacebackCountRef(facebackCountRef);
        // onValue(facebackCountRef, snapshot => {
        //     const data = snapshot.val();
        //     setState({ ...state, isCountFirst: false });
        //     const num = data && data !== null ? data : 0;
        // });
    };

    const offDataBase = () => {
        off(jobCountRef);
        jobCountRef = null;
        console.log('offline count dataBase');
    };
    return {
        initDataBase,
        offDataBase,
    };
};

export default useFireBase;
