import React from 'react';
import './index.scss';
function PortFolioList({ portFolios, delItemInfo, editPortFolio }: any) {
    return (
        <div className="portfolioItem-list">
            {portFolios.map((item, i) => {
                return (
                    <div
                        className="portfolioItem-card"
                        key={`portfolioItem-show-${i}`}
                        onClick={() => editPortFolio(item, i)}
                    >
                        <div
                            className="portfolio-bg"
                            style={{
                                backgroundImage: `url(${
                                    item.picUrlShow ? item.picUrlShow : item.cover ? item.cover : ''
                                })`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                            }}
                        ></div>
                        <div className="r-left port-name">
                            {item.projectName ?? '-'}
                            <i
                                className="delR-icon marLeft"
                                onClick={e => {
                                    e.stopPropagation();
                                    delItemInfo(i);
                                }}
                            ></i>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default PortFolioList;
