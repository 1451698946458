import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import './Chat.scss';
import { Avatar, Input, Spin, Image } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSendBird } from '@/context/SendBirdProvider';
function MessageChat() {
    const location = useLocation();
    const { listLoading, state, sendMessage, handleSelectChannel, refrshChannel } = useSendBird();
    function getQueryParam(param: string, location): string | null {
        const queryParams = new URLSearchParams(location.search);
        return queryParams.get(param);
    }
    const { profileInfo } = useSelector((state: any) => state.state);
    const [activeChanel, setActiveChanel] = useState<any>({});
    const [activeShowInfo, setActiveShowInfo] = useState<any>({
        nickname: '',
        plainProfileUrl: '',
        data: {} as any,
    });
    const currentChannel: any = useRef(null);
    const messageBox: MutableRefObject<any> = useRef(null);
    const isLoadHistory = useRef(false);
    const getMessage = members => {
        const info = members.find(v => v.userId !== profileInfo.accountId);
        if (info) {
            info.data = info.data ? JSON.parse(info.data) : {};
            setActiveShowInfo(info);
        }
    };
    useEffect(() => {
        console.log('init');
        refrshChannel();
        return () => {
            if (!state.channels.length) return;
            handleSelectChannel('');
        };
    }, []);
    useEffect(() => {
        const bottomElement = document.getElementById('bottom-element');
        if (bottomElement) {
            bottomElement.scrollIntoView({
                behavior: 'smooth', // 平滑滚动
                block: 'end', // 滚动到元素底部
            });
        }
    }, [state.messages]);
    const scrollToBottom = () => {
        if (!isLoadHistory.current) {
            messageBox.current.scrollIntoView({
                behavior: 'smooth', // 使滚动平滑过渡
                block: 'end', // 滚动到容器的底部
            });
        }
    };
    const [messageValue, setMessageValue] = useState('');
    const getTopDate = (time: number, index: number) => {
        const previousDate = index ? dayjs(state.messages[index - 1].createdAt).format('YYYY-MM-DD') : null;
        const currentDate = dayjs(time).format('YYYY-MM-DD');
        const today = dayjs().format('YYYY-MM-DD');
        const yesterday = dayjs().add(-1, 'day').format('YYYY-MM-DD');
        if (previousDate !== currentDate || !index) {
            if (currentDate !== today) {
                if (currentDate !== yesterday) {
                    return dayjs(time).format('DD MMM YYYY, h:mm a');
                } else {
                    return dayjs(time).format('DD MMM YYYY, h:mm a');
                }
            } else {
                return dayjs(time).format('h:mm a');
            }
        }
    };
    return (
        <div className="chat-mainBox">
            <div className="chat-tit">Messaging</div>
            <div className="r-top chat-box">
                <div className="chanel-list">
                    <div className="chanel-tit">Contact List</div>
                    {state.channels.length ? (
                        <>
                            {state.channels.map((val: any, i) => {
                                const info = val.members.find(v => v.userId !== profileInfo.accountId);
                                let data = { adminInfo: { position: '' } };
                                if (val.data) {
                                    data = JSON.parse(val.data);
                                }
                                return (
                                    <div
                                        key={`chanel-card-${i}`}
                                        className={`chanel-card r-left ${
                                            activeChanel?._url === val._url ? 'active' : ''
                                        }`}
                                        onClick={() => {
                                            currentChannel.current = val;
                                            setActiveChanel(val);
                                            getMessage(val.members);
                                            handleSelectChannel(val);
                                            scrollToBottom();
                                        }}
                                    >
                                        <Avatar
                                            size={32}
                                            style={{ minWidth: 32 }}
                                            className="contact-img"
                                            src={info?.plainProfileUrl}
                                            icon={<UserOutlined />}
                                        />
                                        {/* <Image
                                            width={32}
                                            height={32}
                                            style={{ minWidth: 32, borderRadius: '50%' }}
                                            className="contact-img"
                                            preview={false}
                                            src={info?.plainProfileUrl || ''}
                                            fallback={require('../../../assets/img/default-min.png')}
                                        /> */}
                                        <div>
                                            <p className="contact-name oneLineText">{info?.nickname || '-'}</p>
                                            <p className="contact-job oneLineText">{data.adminInfo.position || '-'}</p>
                                        </div>
                                        {val.unreadMessageCount ? (
                                            <i className="unRead-box">{val.unreadMessageCount || 0}</i>
                                        ) : null}
                                    </div>
                                );
                            })}
                        </>
                    ) : (
                        <div className="empty-box r-center">You don't have a contact yet</div>
                    )}
                </div>
                <div className="message-box">
                    <div className="job-topBox">
                        {!activeChanel._url ? (
                            <></>
                        ) : (
                            <>
                                <div className="r-center flex1" style={{ marginBottom: 10 }}>
                                    <Avatar
                                        size={48}
                                        className="contact-img"
                                        src={activeShowInfo?.plainProfileUrl}
                                        icon={<UserOutlined />}
                                    />
                                    {/* <Image
                                        width={48}
                                        height={48}
                                        style={{ minWidth: 48, borderRadius: '50%' }}
                                        className="contact-img"
                                        preview={false}
                                        src={activeShowInfo?.plainProfileUrl || ''}
                                        fallback={require('../../../assets/img/default-min.png')}
                                    /> */}
                                    <p className="top-name">{activeShowInfo?.nickname || '-'}</p>
                                </div>
                                {/* {activeChanel.cachedMetaData?.nowChartUser ? (
                                    <div className="r-center flex1">
                                        <i
                                            className="jobChat-icon"
                                            style={{
                                                background: `url(${
                                                    activeChanel.cachedMetaData?.nowChartUser.companyLogo || ''
                                                })`,
                                                backgroundSize: 'cover',
                                            }}
                                        ></i>
                                        <p className="topJOb-tip">
                                            {activeChanel.cachedMetaData?.nowChartUser.companyShow || ''}
                                        </p>
                                        <i className="row-lineBox"></i>
                                        <p className="topJOb-tip oneLineText" style={{ maxWidth: 200 }}>
                                            {activeChanel.cachedMetaData?.nowChartUser.jobShow || ''}
                                        </p>
                                    </div>
                                ) : null} */}
                            </>
                        )}
                    </div>
                    <div className="message-listBox">
                        <div className="message-list" ref={messageBox}>
                            {listLoading ? (
                                <>
                                    <div className="empty-box message-empty r-center">
                                        <Spin style={{ position: 'static' }} />
                                    </div>
                                </>
                            ) : state.messages.length ? (
                                <>
                                    {state.messages.map((m, i) => {
                                        return (
                                            <div key={`message-textBox-${i}`}>
                                                {getTopDate(m.createdAt, i) ? (
                                                    <p className="msg-time r-center flex1">
                                                        {getTopDate(m.createdAt, i)}
                                                    </p>
                                                ) : null}
                                                {/* <p className="msg-time r-center flex1">{getTopDate(m.createdAt, i)}</p> */}
                                                {m.sender.userId !== profileInfo.accountId ? (
                                                    // 接受者
                                                    <div className="msg-card r-top">
                                                        {/* <Image
                                                            width={48}
                                                            height={48}
                                                            style={{ minWidth: 48, borderRadius: '50%' }}
                                                            className="contact-img"
                                                            src={m.sender.plainProfileUrl || ''}
                                                            fallback={require('../../../assets/img/default-min.png')}
                                                            preview={false}
                                                        /> */}
                                                        <Avatar
                                                            size={48}
                                                            style={{ minWidth: 48 }}
                                                            className="contact-img"
                                                            src={m.sender.plainProfileUrl || ''}
                                                            icon={<UserOutlined />}
                                                        />
                                                        <div className="message-text">
                                                            {' '}
                                                            {RegExp(/\n/).test(m.message)
                                                                ? m.message.split('\n').map((t, index) => {
                                                                      return (
                                                                          <div key={'message' + index} style={{}}>
                                                                              {t}
                                                                          </div>
                                                                      );
                                                                  })
                                                                : m.message}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    // 发送者
                                                    <div className="msg-card r-end">
                                                        {m.sendingStatus === 'pending' ? (
                                                            <i className="send-loadingIcon rotate-element"></i>
                                                        ) : m.sendingStatus === 'fail' ? (
                                                            <i className="send-failIcon"></i>
                                                        ) : (
                                                            ''
                                                        )}
                                                        <div className="message-text myMessage-text">
                                                            {RegExp(/\n/).test(m.message)
                                                                ? m.message.split('\n').map((t, index) => {
                                                                      return (
                                                                          <div
                                                                              key={'message' + index}
                                                                              style={{ minHeight: '0.21rem' }}
                                                                          >
                                                                              {t}
                                                                          </div>
                                                                      );
                                                                  })
                                                                : m.message}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}
                                    <div id="bottom-element"></div>
                                </>
                            ) : (
                                <div className="empty-box message-empty r-center">You don't have a contact yet</div>
                            )}
                        </div>
                        <div className={`r-left msg-inp ${!activeChanel._url ? 'disabled-inp' : ''}`}>
                            <Input
                                className={`r-input message-inp`}
                                maxLength={500}
                                type="text"
                                autoComplete=""
                                placeholder=""
                                onInput={(e: any) => {
                                    setMessageValue(e.target.value);
                                }}
                                onFocus={() => {
                                    state.currentlyJoinedChannel && state.currentlyJoinedChannel.markAsRead();
                                }}
                                value={messageValue}
                            />
                            <i
                                className="send-icon"
                                style={{ opacity: !messageValue ? 0.5 : 1 }}
                                onClick={() => {
                                    if (!messageValue) return;
                                    sendMessage(messageValue);
                                    setMessageValue('');
                                }}
                            ></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MessageChat;
