import React from 'react';
import { Button, Modal } from 'antd';
function AlertModal({ isModalOpen, handleOk, handleCancel, message, cancelText, confirmText }: any) {
    return (
        <Modal
            className="r-modal modal_no_padding"
            maskClosable={false}
            centered={true}
            closeIcon={null}
            footer={''}
            width={380}
            title={null}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            maskProps={{
                style: {
                    backgroundColor: 'rgba(30, 34, 45, 0.8)',
                    backdropFilter: 'blur(1px)',
                },
            }}
        >
            <p className="primary-modal-tip" style={{ marginBottom: 30 }}>
                {message}
            </p>
            <div className="r-center">
                {cancelText ? (
                    <Button
                        className="r-primaryNoBg7F_btn r-primary_largeBtn"
                        style={{ marginRight: 20 }}
                        onClick={() => handleCancel()}
                    >
                        {cancelText}
                    </Button>
                ) : null}
                <Button className="r-primary_btn r-primary_largeBtn" onClick={() => handleOk()}>
                    {confirmText}
                </Button>
            </div>
        </Modal>
    );
}

export default AlertModal;
