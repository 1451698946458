import { useState } from 'react';
import axios from 'axios';
import api from '@/api/upload';
import { useGlobalMessage } from './useGlobalMessage';
export const useS3Upload = () => {
    const messageApi = useGlobalMessage();
    const [uploadProgress, setUploadProgress] = useState<number>(0);
    const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const [picUrl, setPicUrl] = useState('');
    const [picUrlShow, setPicUrlShow] = useState('');
    const uploadFileToS3 = async (fileInfo: any) => {
        setUploadProgress(0);
        setUploadSuccess(false);
        setUploadError(null);
        console.log('====================================');
        console.log(fileInfo);
        console.log('====================================');
        try {
            const params = {
                fileName: fileInfo.name,
                // groupName: 'webPic',
            };
            api.getFilePreSignedUrl(params, async res => {
                const { code, data, message } = res;
                setUploadProgress(0);
                setUploadSuccess(false);
                setUploadError(null);
                if (code === 200) {
                    const s3Url = data;
                    setPicUrl(data);
                    try {
                        const result = await axios.put(s3Url, fileInfo.file, {
                            headers: {
                                'Content-Type': fileInfo.file.type,
                            },
                            onUploadProgress: progressEvent => {
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                setUploadProgress(percentCompleted);
                            },
                        });

                        if (result.status === 200) {
                            api.getFileShowUrl({ url: s3Url }, urlRes => {
                                if (urlRes.code === 200) {
                                    setPicUrlShow(urlRes.data);
                                } else {
                                    setPicUrlShow('');
                                }
                                setUploadSuccess(true); // 上传成功
                            });
                        }
                    } catch (error) {
                        setUploadError('Upload failed, please try again.'); // 处理上传错误
                    }
                } else {
                    messageApi.error(message);
                }
            });
        } catch (error) {
            setUploadError('Upload failed, please try again.'); // 处理上传错误
        }
    };

    return { uploadFileToS3, uploadProgress, uploadSuccess, uploadError, picUrl, picUrlShow };
};
