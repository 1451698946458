import React, { useEffect, useState } from 'react';
// google calendar
import config from '@/config/config';
const GoogleCalendarAuth = () => {
    // const clientId = '566752370138-qv3bm4ajm1oohqsus1n01qa113l81den.apps.googleusercontent.com';
    const clientId = config.googleAuthKey;
    const redirectUri = `${config.localUrl}/auth-google-callback`;
    const scope = 'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar';
    const authEndpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
    const [googleCode, setGoogleCode] = useState('');
    const handleGoogleLogin = () => {
        const url = `${authEndpoint}?client_id=${clientId}&redirect_uri=${encodeURIComponent(
            redirectUri
        )}&response_type=code&scope=${encodeURIComponent(scope)}&access_type=offline&prompt=consent`;
        const popup: any = window.open(url, 'google-login', 'width=600,height=600');
        const interval = setInterval(() => {
            if (popup.closed) {
                clearInterval(interval);
            }
        }, 1000);
    };

    useEffect(() => {
        setGoogleCode('');
        const handleMessage = (event: MessageEvent) => {
            if (event.origin !== window.location.origin) return;
            const { code, error, type } = event.data;
            if (type !== 'google') return;
            if (error) {
                console.error('Authorization error:', error);
            } else if (code) {
                console.log(code);
                setGoogleCode(code);
                // loginWithThird({ thirdPartyId: code, thirdPartyType: 'linkedIn' });
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return {
        handleGoogleLogin,
        setGoogleCode,
        googleCode,
    };
};

export default GoogleCalendarAuth;
