import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RCheckBox from '@/components/checkbox';
import FormModal from '@/components/modal/form-modal';
import RDrawer from '@/components/portfolio-drawer/r-drawer';
import UploadVideo from '@/components/upload/upload-video';
import PortFolioList from '@/views/login/components/portFolioList';
import RTabs from '@/views/login/components/tabs';
import { AutoComplete, Button, Form, FormProps, Input, Select, Spin, Tooltip } from 'antd';
import DatePicker, { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import '../index.scss';
import { cloneDeep } from 'lodash';
import OpeationPortFolioDrawer from '@/components/portfolio-drawer/opeationPortfolio';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { defaultInfo } from '@/typings/allType';
import UploadFile from '@/components/upload/upload-File';
import api from '@/api/profile';
import debounce from 'lodash/debounce';
import apiLogin from '@/api/login';
import JobSkillAutoComplate from '@/components/autoComplate-inp/jobSkillAutoComplate';

const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
};
const { TextArea } = Input;
function EditProfessionalInformation({
    isProfessionalFormModalOpen,
    setIsProfessionalFormModalOpen,
    submitInfo,
    accountProjectPOJOList,
    accountCertificatePOJOList,
    relevantExperienceList,
    skillList,
    softSkillList,
    profileInfo,
}: any) {
    const navigate = useNavigate();
    const messageApi = useGlobalMessage();
    const [loading, setLoading] = useState(false);
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [form] = Form.useForm();
    const formRef = useRef<any>(null);
    const [jonForm] = Form.useForm();
    const jobFormRef = useRef<any>(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [state, setState] = useState({
        jobRole: '',
        jobTitleInfo: {} as defaultInfo,
        graduation: '',
        highestDegree: '',
        major: '',
        //
        skillList: [] as defaultInfo[],
        softSkillList: [] as defaultInfo[],
        aboutMe: '',
        myCv: { name: '', url: '' },
        videoList: [
            {
                title: 'Self Introduction',
                desc: "In this video, try to stay relaxed and introduce yourself by talking about where you're from, and perhaps some life experiences. ",
                url: '',
                cover: '',
            },
            {
                title: 'Key Skills & Experiences',
                desc: "In this video, try to talk about how you've applied some of the skills you have, and the most valuable experience you've gained at a work place.",
                url: '',
                cover: '',
            },
            {
                title: 'Key Personal Strength',
                desc: 'Tell us in the video, what your strengths are and how it sets you apart from the rest.',
                url: '',
                cover: '',
            },
        ],
        portFolios: [] as defaultInfo[],
        accountCertificateList: [] as defaultInfo[],
    });
    const [accountCertificateInfo, setAccountCertificateInfo] = useState<defaultInfo>({
        editIndex: -1,
        certificateName: '',
        certificateUrl: '',
        validPeriodFrom: '',
        validPeriodTo: '',
    });
    const [skillStr, setSkillStr] = useState('');
    const [editIndex, setEditIndex] = useState(-1);
    const [relevantExperience, setRelevantExperience] = useState<any>({
        jobRoles: '',
        jobRolesCode: '',
        occupationalType: '',
        companyName: '',
        startDate: null as any,
        endDate: null as any,
        currentEmployedHereFlag: false,
        certificateId: '',
        skillsShow: [] as string[],
        skills: '',
    });
    const [jobInfoList, setJobInfoList] = useState<defaultInfo[]>([]);
    const enter = e => {
        if (e.keyCode === 13) {
            const val = e.target.value;
            if (!val) return;
            if (relevantExperience.skillsShow.length >= 15) {
                messageApi.error('Add up to 15 skills.');
                return;
            }
            relevantExperience.skillsShow.push(val);
            setSkillStr('');
            setRelevantExperience({ ...relevantExperience });
        }
    };
    const saveJobInfo = async () => {
        try {
            if (jobInfoList.length >= 7) {
                messageApi.error('You can only add up to 7 relevant experiences');
                return;
            }
            const values = await jonForm.validateFields();
            console.log('Validation Success:', values);
            if (editIndex !== -1) {
                jobInfoList.splice(editIndex, 1, relevantExperience);
            } else {
                jobInfoList.push({ ...relevantExperience });
            }
            setJobInfoList([...jobInfoList]);
            setSkillStr('');
            const initialValues = {
                jobRoles: '',
                occupationalType: '',
                companyName: '',
                startDate: null as any,
                endDate: null as any,
                currentEmployedHereFlag: false,
                certificateId: '',
                skillsShow: [] as string[],
                skills: '',
            };
            setEditIndex(-1);
            setRelevantExperience(initialValues);
            jonForm.setFieldsValue(initialValues);
        } catch (error) {
            console.log('Validation Failed:', error);
        }
    };
    const [portFolioEditInfo, setPortFolioEditInfo] = useState({
        index: -1,
        info: {} as defaultInfo,
    });
    const editPortFolio = (val: any, i: number) => {
        setPortFolioEditInfo({ index: i, info: val });
        setOpenDrawer(true);
    };
    const validateExperience = (data: any) => {
        const errors: string[] = [];
        if (!data.jobRoles) {
            errors.push('Job Role is required.');
        }
        if (!data.occupationalType) {
            errors.push('Occupational Type is required.');
        }
        if (!data.startDate) {
            errors.push('Start Date is required.');
        }
        if (data.occupationalType === 'Company employee' && !data.companyName) {
            errors.push('Company Name is required when Occupational Type is Company employee.');
        }
        if (!data.currentEmployedHereFlag && !data.endDate) {
            errors.push('End Date is required when not currently employed.');
        }
        return {
            isValid: errors.length === 0,
            errors,
        };
    };
    const onFinish: FormProps['onFinish'] = values => {
        if (!state.skillList.length || !state.softSkillList.length) {
            messageApi.error('Please add at least one Hard skill and one Soft skill.');
            return;
        }
        if (state.videoList.findIndex(v => !v.url) !== -1) {
            messageApi.error('Please upload 3 video files.');
            return;
        }
        if (!state.myCv.url) {
            messageApi.error('Please upload your cv.');
            return;
        }
        const experienceList = jobInfoList.length
            ? jobInfoList.map(item => {
                  return Object.assign(item, {
                      skills: item.skillsShow.length ? item.skillsShow.join(',') : '',
                      startDate: item.startDate ? dayjs(new Date(item.startDate)).format('YYYY-MM') : '',
                      endDate: item.currentEmployedHereFlag ? '' : dayjs(new Date(item.endDate)).format('YYYY-MM'),
                      currentEmployedHereFlag: item.currentEmployedHereFlag ? 1 : 0,
                  });
              })
            : [];
        const { isValid } = validateExperience(relevantExperience);
        if (isValid && experienceList.length < 7) {
            const item = JSON.parse(JSON.stringify(relevantExperience));
            experienceList.push(
                Object.assign(item, {
                    skills: item.skillsShow.length ? item.skillsShow.join(',') : '',
                    startDate: dayjs(new Date(item.startDate)).format('YYYY-MM'),
                    endDate: item.currentEmployedHereFlag ? '' : dayjs(new Date(item.endDate)).format('YYYY-MM'),
                    currentEmployedHereFlag: item.currentEmployedHereFlag ? 1 : 0,
                })
            );
        }
        const skillList = state.skillList.map(item => {
            return {
                skillName: item.name,
                skillType: 'hardSkills',
                yearsOfExperience: item.time,
            };
        });
        const softskillList = state.softSkillList.map(item => {
            return {
                skillName: item.name,
                skillType: 'softSkills',
                yearsOfExperience: item.time,
            };
        });
        const pat = {
            accountInformationThreeVO: {
                accountExperienceVOList: [...experienceList],
                graduation: state.graduation,
                highestDegree: state.highestDegree,
                major: state.major,
            },
            accountInformationTwoVO: {
                aboutMe: state.aboutMe,
                accountCertificateVOList: state.accountCertificateList.map(item => {
                    return Object.assign(item, {
                        validPeriodFrom: dayjs(item.validPeriodFrom, 'MMM YYYY').format('MMM YYYY'),
                        validPeriodTo: dayjs(item.validPeriodTo, 'MMM YYYY').format('MMM YYYY'),
                    });
                }),
                accountProjectVOList: state.portFolios.map(item => {
                    return {
                        accountProjectFileVOList: item.portfolioItemList.map((val, i) => {
                            return {
                                fileText: val.type === 'text' ? val.text : val.url,
                                fileType: val.type,
                                projectFileId: '',
                                sortNumbers: i + 1,
                            };
                        }),
                        coverImageUrl: item.cover,
                        externalProjectUrl: item.url,
                        projectName: item.projectName,
                    };
                }),
                accountSkillVOList: [...skillList, ...softskillList],
                cvFileName: state.myCv.name,
                cvFileUrl: state.myCv.url,
                keyPersonalStrengthUrl: state.videoList[2].url,
                keySkillsExperiencesUrl: state.videoList[1].url,
                selfIntroductionUrl: state.videoList[0].url,
                jobRole: state.jobRole,
                jobRoleCode: state.jobTitleInfo.code,
            },
        };
        console.log(pat);
        setLoading(true);
        api.setProfileInfoAll(pat, res => {
            setLoading(false);
            if (res.code === 200) {
                submitInfo();
            } else {
                messageApi.error(res.message);
            }
        });
    };
    // job tit
    const [autocomplateLoading, setAutocomplateLoading] = useState(false);
    const [jobTitle, setJobTitle] = useState('');
    const [jobOptions, setJobOptions] = useState<any[]>([]);
    const getJobOption = search => {
        if (!search) {
            setJobOptions([]);
            return;
        }
        setAutocomplateLoading(true);
        apiLogin.getJobRoleList({ keyWord: search }, res => {
            setAutocomplateLoading(false);
            if (res.code === 200) {
                const results = res.data.map((item: any) => ({
                    value: item.title,
                    code: item.code,
                    label: item.title,
                }));
                setJobOptions(results);
            } else {
                setJobOptions([]);
                messageApi.error(res.message);
            }
        });
    };
    const searchList: any = useCallback(
        debounce(params => {
            getJobOption(params);
            console.log('search');
        }, 1000),
        []
    );
    // skill search
    const [skill, setSkill] = useState('');
    const [skillOptions, setSkillOptions] = useState<any[]>([]);
    const [skillOptionsNew, setSkillOptionsNew] = useState<any[]>([]);
    const getSkillOptionNew = params => {
        setAutocomplateLoading(true);
        apiLogin.getSkillList({ keyWord: params.search, code: params.code || '15-1241.00' }, res => {
            setAutocomplateLoading(false);
            if (res.code === 200) {
                const results = res.data.map((item: any) => ({ value: item.title, label: item.title }));
                setSkillOptionsNew(results);
            } else {
                setSkillOptionsNew([]);
                messageApi.error(res.message);
            }
        });
    };
    const getSkillOption = params => {
        setAutocomplateLoading(true);
        apiLogin.getSkillList({ keyWord: params.search, code: params.code || '15-1241.00' }, res => {
            setAutocomplateLoading(false);
            if (res.code === 200) {
                const results = res.data.map((item: any) => ({ value: item.title, label: item.title }));
                setSkillOptions(results);
            } else {
                setSkillOptions([]);
                messageApi.error(res.message);
            }
        });
    };
    const searchSkillList: any = useCallback(
        debounce(params => {
            // if (!params.search) {
            //     setSkillOptions([]);
            //     return;
            // }
            getSkillOption(params);
            console.log('search');
        }, 1000),
        []
    );
    useEffect(() => {
        if (profileInfo) {
            const initialValues = {
                jobRole: profileInfo.jobRole,
                jobRoleCode: profileInfo.jobRoleCode,
                jobTitleInfo: {
                    code: profileInfo.jobRoleCode,
                },
                graduation: profileInfo.graduation,
                highestDegree: profileInfo.highestDegree,
                major: profileInfo.major,
                //
                skillList: skillList.map(item => {
                    return Object.assign(item, {
                        name: item.skillName,
                        time: item.yearsOfExperience,
                    });
                }),
                softSkillList: softSkillList.map(item => {
                    return Object.assign(item, {
                        name: item.skillName,
                        time: item.yearsOfExperience,
                    });
                }),
                aboutMe: profileInfo.aboutMe,
                myCv: { name: profileInfo.cvFileName, url: profileInfo.cvFileUrl },
                videoList: [
                    {
                        title: 'Self Introduction',
                        desc: "In this video, try to stay relaxed and introduce yourself by talking about where you're from, and perhaps some life experiences. ",
                        url: profileInfo.selfIntroductionUrl,
                        cover: profileInfo.selfIntroductionUrlImage,
                    },
                    {
                        title: 'Key Skills & Experiences',
                        desc: "In this video, try to talk about how you've applied some of the skills you have, and the most valuable experience you've gained at a work place.",
                        url: profileInfo.keySkillsExperiencesUrl,
                        cover: profileInfo.keySkillsExperiencesUrlImage,
                    },
                    {
                        title: 'Key Personal Strength',
                        desc: 'Tell us in the video, what your strengths are and how it sets you apart from the rest.',
                        url: profileInfo.keyPersonalStrengthUrl,
                        cover: profileInfo.selfIntroductionUrlImage,
                    },
                ],
                portFolios: accountProjectPOJOList.length
                    ? accountProjectPOJOList.map(item => {
                          return {
                              cover: item.coverImageUrl,
                              url: item.externalProjectUrl,
                              projectName: item.projectName,
                              portfolioItemList: item.accountProjectFilePOJOList
                                  ? item.accountProjectFilePOJOList.map((val, i) => {
                                        return {
                                            text: val.fileType === 'text' ? val.fileText : '',
                                            url: val.fileType !== 'text' ? val.fileText : '',
                                            type: val.fileType,
                                            sortNumbers: i + 1,
                                        };
                                    })
                                  : [],
                          };
                      })
                    : [],
                accountCertificateList: accountCertificatePOJOList ? accountCertificatePOJOList : [],
            };
            const jobList = relevantExperienceList
                ? relevantExperienceList.map(item => {
                      return Object.assign(item, {
                          currentEmployedHereFlag: item.currentEmployedHereFlag,
                          skillsShow: item.skills ? item.skills.split(',') : ([] as string[]),
                          skills: item.skills,
                          startDate: dayjs(new Date(item.startDate)),
                          endDate: item.endDate ? dayjs(new Date(item.endDate)) : null,
                      });
                  })
                : [];
            setJobInfoList([...jobList]);
            console.log(initialValues);
            setState({ ...initialValues });
            form.setFieldsValue(initialValues);
            // getSkillOptionNew({ search: '', code: profileInfo.jobRoleCode||'15-1241.00' });
            getSkillOption({ search: '', code: profileInfo.jobRoleCode || '15-1241.00' });
        }
        if (!isProfessionalFormModalOpen) {
            const initialValues = {
                jobRoles: '',
                occupationalType: '',
                companyName: '',
                startDate: null as any,
                endDate: null as any,
                currentEmployedHereFlag: false,
                certificateId: '',
                skillsShow: [] as string[],
                skills: '',
            };
            setSkillStr('');
            setRelevantExperience({ ...initialValues });
            jonForm.setFieldsValue(initialValues);
        }
    }, [isProfessionalFormModalOpen]);
    return (
        <div>
            <RDrawer
                open={isProfessionalFormModalOpen}
                onClose={() => setIsProfessionalFormModalOpen(false)}
                children={
                    <div className="curModal-box-new curModal-box" key={'curModal-box'}>
                        <div>
                            <div className="editInfo-tit">Edit Professional information</div>
                            <div className="information-formBox">
                                <Form
                                    name="basic"
                                    layout="vertical"
                                    initialValues={{ remember: true }}
                                    onFinish={onFinish}
                                    autoComplete="off"
                                    validateTrigger="onBlur"
                                    form={form}
                                    ref={formRef}
                                    // onKeyDown={event => {
                                    //     if (event.key === 'Enter') {
                                    //         event.preventDefault();
                                    //     }
                                    // }}
                                >
                                    <Form.Item
                                        label="Job Role"
                                        name="jobRole"
                                        style={{ marginTop: 30 }}
                                        rules={[{ required: true, message: ' ' }]}
                                    >
                                        <AutoComplete
                                            options={jobOptions}
                                            className="r-autoComplate r-autoComplate-bg r-select"
                                            style={{ width: '100%', height: 44 }}
                                            value={jobTitle}
                                            onBlur={() => setJobOptions([])}
                                            suffixIcon={<i className="down-right-icon"></i>}
                                            onSelect={val => {
                                                const jobTitleInfo: any = jobOptions.find(v => v.label === val);
                                                setJobTitle(jobTitleInfo.label);
                                                setState({
                                                    ...state,
                                                    jobRole: jobTitleInfo.label,
                                                    jobTitleInfo: { ...jobTitleInfo },
                                                    skillList: [],
                                                    softSkillList: [],
                                                });
                                                form.setFieldsValue({ jobRole: jobTitleInfo.label });
                                                searchSkillList({ search: '', code: jobTitleInfo.code });
                                            }}
                                            onSearch={val => {
                                                searchList(val);
                                            }}
                                            placeholder=""
                                            notFoundContent={
                                                <>
                                                    {autocomplateLoading && (
                                                        <div className="r-center">
                                                            <Spin />
                                                        </div>
                                                    )}
                                                    {!autocomplateLoading && <div>No data</div>}
                                                </>
                                            }
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label={
                                            <div className="r-left" style={{ width: 832 }}>
                                                About Me
                                                <span className="textare-countTip marLeft">
                                                    {state.aboutMe.length} / 500 characters
                                                </span>
                                            </div>
                                        }
                                        name="aboutMe"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your about.',
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            className={`r-inputTextArea r-input`}
                                            maxLength={500}
                                            autoSize={false}
                                            value={state.aboutMe}
                                            onChange={(e: any) => {
                                                const val = e.target.value;
                                                setState({ ...state, aboutMe: val });
                                                form.setFieldsValue({ aboutMe: val });
                                            }}
                                            placeholder=""
                                        />
                                    </Form.Item>
                                    <p className="info-line" style={{ margin: '20px 0' }}></p>
                                    <div className="pri-tit">Education</div>
                                    <Form.Item
                                        className="flex1"
                                        label="What university did you graduate from?"
                                        name="graduation"
                                        rules={[{ required: true, message: ' ' }]}
                                    >
                                        <Input
                                            className="r-input"
                                            placeholder=""
                                            maxLength={200}
                                            value={state.graduation}
                                            onInput={(e: any) => {
                                                setState({ ...state, graduation: e.target.value });
                                                form.setFieldsValue({ graduation: e.target.value });
                                            }}
                                        />
                                    </Form.Item>
                                    <div className="r-left">
                                        <Form.Item
                                            className="flex1"
                                            label="Highest Degree"
                                            rules={[{ required: true, message: '' }]}
                                            name={'highestDegree'}
                                        >
                                            <Select
                                                className="r-select"
                                                value={state.highestDegree}
                                                onChange={val => {
                                                    setState({ ...state, highestDegree: val });
                                                    form.setFieldsValue({ highestDegree: val });
                                                }}
                                                suffixIcon={<i className="down-right-icon"></i>}
                                                options={[
                                                    { label: 'Bachelor’s Degree', value: 'Bachelor’s Degree' },
                                                    { label: 'Master’s Degree', value: 'Master’s Degree' },
                                                    { label: 'Doctorate', value: 'Doctorate' },
                                                    { label: 'Certifications', value: 'Certifications' },
                                                ]}
                                            />
                                        </Form.Item>
                                        <span className="space20"></span>
                                        <Form.Item
                                            className="flex1"
                                            label="Major"
                                            name={'major'}
                                            rules={[{ required: true, message: ' ' }]}
                                        >
                                            <Input
                                                className="r-input"
                                                placeholder=""
                                                maxLength={200}
                                                value={state.major}
                                                onInput={(e: any) => {
                                                    setState({ ...state, major: e.target.value });
                                                    form.setFieldsValue({ major: e.target.value });
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <p className="info-line" style={{ margin: '0 0 20px 0' }}></p>
                                    <div className="pri-tit">Relevant Experience</div>
                                    <div className="experience-cardBox">
                                        <Form
                                            name="basic-experience-form"
                                            layout="vertical"
                                            initialValues={{ remember: true }}
                                            autoComplete="off"
                                            form={jonForm}
                                            ref={jobFormRef}
                                            onKeyDown={event => {
                                                if (event.key === 'Enter') {
                                                    event.preventDefault();
                                                }
                                            }}
                                        >
                                            <div className="r-left">
                                                <Form.Item
                                                    className="flex1"
                                                    label="Job Roles"
                                                    name={'jobRoles'}
                                                    rules={[{ required: true, message: 'Please enter a job role' }]}
                                                >
                                                    <AutoComplete
                                                        options={jobOptions}
                                                        className="r-autoComplate"
                                                        style={{ width: '100%', height: 44 }}
                                                        value={relevantExperience.jobRoles}
                                                        suffixIcon={<i className="down-right-icon"></i>}
                                                        onBlur={() => setJobOptions([])}
                                                        onSelect={val => {
                                                            const jobTitleInfo: any = jobOptions.find(
                                                                v => v.label === val
                                                            );
                                                            jonForm.setFieldsValue({ jobRoles: jobTitleInfo.label });
                                                            setSkillOptionsNew([]);
                                                            setRelevantExperience({
                                                                ...relevantExperience,
                                                                jobRoles: jobTitleInfo.label,
                                                                jobRolesCode: jobTitleInfo.code,
                                                                jobTitleInfo: { ...jobTitleInfo },
                                                                skills: '',
                                                                skillsShow: [],
                                                            });
                                                            jonForm.setFieldsValue({ jobRoles: jobTitleInfo.label });
                                                            getSkillOptionNew({ search: '', code: jobTitleInfo.code });
                                                        }}
                                                        onSearch={val => {
                                                            searchList(val);
                                                        }}
                                                        placeholder=""
                                                        notFoundContent={
                                                            <>
                                                                {autocomplateLoading && (
                                                                    <div className="r-center">
                                                                        <Spin />
                                                                    </div>
                                                                )}
                                                                {!autocomplateLoading && <div>No data</div>}
                                                            </>
                                                        }
                                                    />
                                                </Form.Item>
                                                <span className="space20"></span>
                                                <Form.Item
                                                    className="flex1"
                                                    label="Occupational Type"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please select a occupational type',
                                                        },
                                                    ]}
                                                    name={'occupationalType'}
                                                >
                                                    <Select
                                                        className="r-select white-bg"
                                                        suffixIcon={<i className="down-right-icon"></i>}
                                                        value={relevantExperience.occupationalType}
                                                        onChange={(e: any) => {
                                                            setRelevantExperience({
                                                                ...relevantExperience,
                                                                occupationalType: e,
                                                            });
                                                            jonForm.setFieldsValue({ occupationalType: e });
                                                        }}
                                                        options={[
                                                            { label: 'Company employee', value: 'Company employee' },
                                                            { label: 'Freelancer', value: 'Freelancer' },
                                                        ]}
                                                    />
                                                </Form.Item>
                                            </div>
                                            {relevantExperience.occupationalType === 'Company employee' ? (
                                                <Form.Item
                                                    className="flex1"
                                                    label="Company Name"
                                                    name={'companyName'}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter the company name',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className="r-input white-bg"
                                                        placeholder=""
                                                        maxLength={200}
                                                        value={relevantExperience.companyName}
                                                        onInput={(e: any) => {
                                                            setRelevantExperience({
                                                                ...relevantExperience,
                                                                companyName: e.target.value,
                                                            });
                                                            jonForm.setFieldsValue({ companyName: e.target.value });
                                                        }}
                                                    />
                                                </Form.Item>
                                            ) : null}
                                            <div className="r-left">
                                                <Form.Item
                                                    className="flex1"
                                                    label="Start Date"
                                                    name={'startDate'}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please select a start date',
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        className={`r-datePicker white-bg`}
                                                        placeholder=""
                                                        format={'MMM YYYY'}
                                                        value={relevantExperience.startDate || dayjs(new Date())}
                                                        onChange={val => {
                                                            setRelevantExperience({
                                                                ...relevantExperience,
                                                                startDate: val,
                                                            });
                                                            jonForm.setFieldsValue({ startDate: val });
                                                        }}
                                                        picker="month"
                                                        suffixIcon={<i className="sel_time_icon"></i>}
                                                        disabledDate={disabledDate}
                                                    />
                                                </Form.Item>
                                                <span className="space20"></span>
                                                <Form.Item
                                                    className="flex1"
                                                    label="End Date"
                                                    name={'endDate'}
                                                    rules={[
                                                        {
                                                            required: !relevantExperience.currentEmployedHereFlag,
                                                            message: 'Please select a end date',
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        className={`r-datePicker white-bg`}
                                                        placeholder=""
                                                        format={'MMM YYYY'}
                                                        disabled={relevantExperience.currentEmployedHereFlag}
                                                        value={relevantExperience.endDate || dayjs(new Date())}
                                                        onChange={val => {
                                                            setRelevantExperience({
                                                                ...relevantExperience,
                                                                endDate: val,
                                                            });
                                                            jonForm.setFieldsValue({ endDate: val });
                                                        }}
                                                        picker="month"
                                                        suffixIcon={<i className="sel_time_icon"></i>}
                                                        disabledDate={disabledDate}
                                                    />
                                                </Form.Item>
                                                <span className="space20"></span>
                                                <Form.Item label={<div></div>}>
                                                    <RCheckBox
                                                        checkInfo={{
                                                            isChecked: relevantExperience.currentEmployedHereFlag,
                                                            label: 'Currently Employed Here',
                                                        }}
                                                        width={'100%'}
                                                        setCheckInfo={() => {
                                                            setRelevantExperience({
                                                                ...relevantExperience,
                                                                currentEmployedHereFlag:
                                                                    !relevantExperience.currentEmployedHereFlag,
                                                                // endDate: dayjs(new Date()),
                                                            });
                                                            jonForm.setFieldsValue({ endDate: '' });
                                                        }}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <Form.Item style={{ marginBottom: 0 }} label="Skills required for this job">
                                                <Select
                                                    options={skillOptionsNew}
                                                    className="r-autoComplate"
                                                    style={{ width: '100%', height: 44 }}
                                                    value={skill}
                                                    showSearch
                                                    suffixIcon={<></>}
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '')
                                                            .toLowerCase()
                                                            .includes(input.toLowerCase())
                                                    }
                                                    disabled={!relevantExperience.jobRoles}
                                                    onChange={val => {
                                                        setSkill(val);
                                                    }}
                                                    onSelect={val => {
                                                        if (relevantExperience.skillsShow.length >= 15) {
                                                            messageApi.error('Add up to 15 skills.');
                                                            return;
                                                        }
                                                        if (relevantExperience.skillsShow.length >= 15) {
                                                            messageApi.error('Add up to 15 skills.');
                                                            return;
                                                        }
                                                        const isHave = JSON.parse(
                                                            JSON.stringify(relevantExperience.skillsShow)
                                                        ).findIndex((v, index) => v === val);
                                                        if (isHave !== -1) {
                                                            setSkill('');
                                                            messageApi.error('You have added this skill.');
                                                            return;
                                                        }
                                                        relevantExperience.skillsShow.push(val);
                                                        setRelevantExperience({ ...relevantExperience });
                                                        setSkill('');
                                                    }}
                                                    placeholder=""
                                                    notFoundContent={
                                                        <>
                                                            {autocomplateLoading && (
                                                                <div className="r-center">
                                                                    <Spin />
                                                                </div>
                                                            )}
                                                            {!autocomplateLoading && <div>No data</div>}
                                                        </>
                                                    }
                                                />
                                                <RTabs
                                                    tabLists={relevantExperience.skillsShow}
                                                    onDelTab={(i: number) => {
                                                        relevantExperience.skillsShow.splice(i, 1);
                                                        setRelevantExperience({ ...relevantExperience });
                                                    }}
                                                />
                                            </Form.Item>
                                        </Form>
                                    </div>
                                    <div className="r-end" style={{ margin: '16px 0' }}>
                                        <Button
                                            className="r-primary_btn r-primaryNoBg_btn"
                                            style={{ width: 248 }}
                                            onClick={() => saveJobInfo()}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                    {jobInfoList.map((item: any, i) => {
                                        return (
                                            <div className="cerinfo-card" key={`join-show-card-${i}`}>
                                                <div className="r-left">
                                                    {item.jobRoles ?? '-'}
                                                    <i
                                                        className="edit-icon marLeft"
                                                        onClick={() => {
                                                            getSkillOptionNew({
                                                                search: '',
                                                                code: item.jobRolesCode || item.jobTitleInfo?.code,
                                                            });
                                                            setEditIndex(i);
                                                            setRelevantExperience({
                                                                ...item,
                                                                jobTitle: item.jobRole || '',
                                                            });
                                                            jonForm.setFieldsValue({ ...item });
                                                        }}
                                                    ></i>
                                                    <i
                                                        className="del-white-icon"
                                                        style={{ marginLeft: 8 }}
                                                        onClick={() => {
                                                            jobInfoList.splice(i, 1);
                                                            setJobInfoList([...jobInfoList]);
                                                        }}
                                                    ></i>
                                                </div>
                                                <p className="exp-tip">{item.occupationalType}</p>
                                                <p className="exp-size11">
                                                    {dayjs(item.startDate).format('MMM YYYY')} -{' '}
                                                    {item.currentEmployedHereFlag
                                                        ? 'Currently Employed Here'
                                                        : dayjs(item.endDate).format('MMM YYYY')}
                                                </p>
                                                {item.skillsShow.length ? (
                                                    <p className="exp-size12">Skills: {item.skillsShow.join(', ')}</p>
                                                ) : null}
                                            </div>
                                        );
                                    })}
                                    <p className="info-line" style={{ margin: '20px 0' }}></p>
                                    {/* skill list */}
                                    <div>
                                        <div className="pri-tit r-left">
                                            Skills
                                            <Tooltip
                                                overlayClassName="skill-tooltipBox"
                                                // placement="topLeft"
                                                title={
                                                    <div className="tooltip-text">
                                                        <div className="title-box">Showcase Your Skills</div>
                                                        <div>
                                                            Highlight your soft skills (like teamwork and leadership)
                                                            and hard skills (like technical expertise) to stand out to
                                                            employers.
                                                        </div>
                                                        <br />
                                                        <div>Why It Matters</div>
                                                        <div>
                                                            <span className="title-box">· Better Matches</span>:
                                                            Talenaut’s AI connects you with roles that align perfectly
                                                            with your skills and expertise, increasing your chances of
                                                            landing the right job.
                                                        </div>
                                                        <div>
                                                            <span className="title-box">· Stand Out</span>: Go beyond a
                                                            traditional resume to showcase your full capabilities,
                                                            potential, and value. · Appeal to Employers: Present a
                                                            well-rounded profile that highlights both technical
                                                            expertise and personal attributes, showing how you’ll
                                                            contribute to their team.
                                                        </div>
                                                        <div>
                                                            <span className="title-box">· Attract Opportunities</span>:
                                                            Engage hiring managers by giving them a comprehensive view
                                                            of what makes you unique.
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <i className="round-tipIcon"></i>
                                            </Tooltip>
                                        </div>
                                        <p className="skill-typeBox">Hard Skills</p>
                                        {state.skillList.map((item, i) => {
                                            return (
                                                <div className="r-left" key={`skillBox-${i}`}>
                                                    <Form.Item label="Skill Name" className="w640">
                                                        <JobSkillAutoComplate
                                                            skillOptions={skillOptions}
                                                            selectOptions={state.skillList}
                                                            value={item.name}
                                                            index={i}
                                                            setInfo={val => {
                                                                state.skillList[i].name = val;
                                                                setState({ ...state, skillList: [...state.skillList] });
                                                            }}
                                                            placeholder="Start typing..."
                                                            isFinshClear={false}
                                                            height={44}
                                                            suffixIcon={<i></i>}
                                                            classStr="r-autoComplate r-autoComplate-bg"
                                                        />
                                                    </Form.Item>
                                                    <span className="space20"></span>
                                                    <Form.Item label="Years of Experience">
                                                        <div className="r-left">
                                                            <Input
                                                                className="r-input"
                                                                value={item.time}
                                                                maxLength={50}
                                                                onInput={(e: any) => {
                                                                    let inputValue = e.target.value;
                                                                    inputValue = inputValue
                                                                        .replace(/^0+/, '')
                                                                        .replace(/[^\d]/g, '');
                                                                    state.skillList[i].time = inputValue;
                                                                    setState({ ...state, skillList: state.skillList });
                                                                }}
                                                                placeholder=""
                                                            />
                                                            <span className="space20"></span>
                                                            <i
                                                                className="delR-icon"
                                                                onClick={() => {
                                                                    state.skillList.splice(i, 1);
                                                                    setState({ ...state, skillList: state.skillList });
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                            );
                                        })}
                                        <div
                                            className={`form-addBtn r-center ${!state.jobRole ? 'disabled' : ''}`}
                                            onClick={() => {
                                                if (!state.jobRole) return;
                                                if (state.skillList.length >= 10) {
                                                    messageApi.error('You can add a maximum of 10 hard skills.');
                                                    return;
                                                }
                                                state.skillList.push({ name: '', time: '' });
                                                setState({ ...state, skillList: state.skillList });
                                            }}
                                        >
                                            + Add Skill
                                        </div>
                                        <p className="skill-typeBox">Soft Skills</p>
                                        {state.softSkillList.map((item, i) => {
                                            return (
                                                <div className="r-left" key={`skillBox-${i}`}>
                                                    <Form.Item label="Skill Name" className="w640">
                                                        <JobSkillAutoComplate
                                                            skillOptions={skillOptions}
                                                            selectOptions={state.softSkillList}
                                                            value={item.name}
                                                            index={i}
                                                            setInfo={val => {
                                                                state.softSkillList[i].name = val;
                                                                setState({
                                                                    ...state,
                                                                    softSkillList: [...state.softSkillList],
                                                                });
                                                            }}
                                                            placeholder="Start typing..."
                                                            isFinshClear={false}
                                                            height={44}
                                                            suffixIcon={<i></i>}
                                                            classStr="r-autoComplate r-autoComplate-bg"
                                                        />
                                                    </Form.Item>
                                                    <span className="space20"></span>
                                                    <Form.Item label="Years of Experience">
                                                        <div className="r-left">
                                                            <Input
                                                                className="r-input"
                                                                value={item.time}
                                                                maxLength={50}
                                                                onInput={(e: any) => {
                                                                    let inputValue = e.target.value;
                                                                    inputValue = inputValue.replace(/[^0-9]/g, '');
                                                                    state.softSkillList[i].time = inputValue;
                                                                    setState({
                                                                        ...state,
                                                                        softSkillList: state.softSkillList,
                                                                    });
                                                                }}
                                                                placeholder=""
                                                            />
                                                            <span className="space20"></span>
                                                            <i
                                                                className="delR-icon"
                                                                onClick={() => {
                                                                    state.softSkillList.splice(i, 1);
                                                                    setState({
                                                                        ...state,
                                                                        softSkillList: state.softSkillList,
                                                                    });
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </Form.Item>
                                                </div>
                                            );
                                        })}
                                        <div
                                            className={`form-addBtn r-center ${!state.jobRole ? 'disabled' : ''}`}
                                            onClick={() => {
                                                if (!state.jobRole) return;
                                                if (state.softSkillList.length >= 10) {
                                                    messageApi.error('You can add a maximum of 10 hard skills.');
                                                    return;
                                                }
                                                state.softSkillList.push({ name: '', time: '' });
                                                setState({ ...state, softSkillList: state.softSkillList });
                                            }}
                                        >
                                            + Add Skill
                                        </div>
                                    </div>

                                    <p className="info-line"></p>
                                    {/* video list */}
                                    <div className="r-left">
                                        {state.videoList.map((item, i) => {
                                            return (
                                                <div className="video-card" key={`videoCard-${i}`}>
                                                    <div className="pri-tit">{item.title}</div>
                                                    <p className="video-tip">{item.desc}</p>
                                                    <UploadVideo
                                                        setVideoInfo={(val, thumbnail) => {
                                                            state.videoList[i].url = val;
                                                            state.videoList[i].cover = thumbnail;
                                                            setState({ ...state, videoList: [...state.videoList] });
                                                        }}
                                                        index={i}
                                                        id={`videoInput-${i}`}
                                                        videoInfo={state.videoList[i].url}
                                                        videoInfocover={state.videoList[i].cover}
                                                    />
                                                    {item.url ? (
                                                        <div
                                                            className="remove-link"
                                                            onClick={() => {
                                                                state.videoList[i].url = '';
                                                                state.videoList[i].cover = '';
                                                                setState({ ...state, videoList: [...state.videoList] });
                                                            }}
                                                        >
                                                            Remove
                                                        </div>
                                                    ) : (
                                                        <div className="remove-link" style={{ height: 14 }}>
                                                            {' '}
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <p className="info-line"></p>
                                    <div>
                                        <div className="pri-tit">Files</div>
                                        <Form.Item label="My CV" name="myCv">
                                            <UploadFile
                                                fileInfo={state.myCv}
                                                setFileInfo={val => {
                                                    setState({ ...state, myCv: val });
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    {/*  portfolio  */}
                                    <div>
                                        <Form.Item label="Portfolio Files (optional)" name="portfolioFile">
                                            <div className="r-left">
                                                <PortFolioList
                                                    portFolios={state.portFolios}
                                                    delItemInfo={(index: number) => {
                                                        state.portFolios.splice(index, 1);
                                                        setState({ ...state });
                                                    }}
                                                    editPortFolio={editPortFolio}
                                                />
                                            </div>
                                            {state.portFolios.length >= 10 ? null : (
                                                <Button
                                                    className="r-primary_btn medium_btn h32-btn"
                                                    style={{ width: 170 }}
                                                    onClick={() => {
                                                        setPortFolioEditInfo({
                                                            index: -1,
                                                            info: {} as defaultInfo,
                                                        });
                                                        setOpenDrawer(true);
                                                    }}
                                                >
                                                    Create New Project
                                                </Button>
                                            )}
                                        </Form.Item>
                                    </div>
                                    {/* cer  */}
                                    <div>
                                        <Form.Item label="Please update any certificate for certifications and assessment which you may have here. (optional)">
                                            {state.accountCertificateList.map((item, i) => {
                                                return (
                                                    <div className="r-left cerinfo-card" key={`cerinfo-card-${i}`}>
                                                        {item.certificateName ?? '-'}
                                                        <i
                                                            className="edit-icon marLeft"
                                                            onClick={() => {
                                                                setAccountCertificateInfo({
                                                                    certificateName: item.certificateName,
                                                                    certificateUrl: item.certificateUrl,
                                                                    validPeriodFrom: dayjs(
                                                                        item.validPeriodFrom,
                                                                        'MMM YYYY'
                                                                    ),
                                                                    validPeriodTo: dayjs(
                                                                        item.validPeriodTo,
                                                                        'MMM YYYY'
                                                                    ),
                                                                    editIndex: i,
                                                                });
                                                                setIsFormModalOpen(true);
                                                            }}
                                                        ></i>
                                                        <i
                                                            className="del-white-icon"
                                                            style={{ marginLeft: 8 }}
                                                            onClick={() => {
                                                                state.accountCertificateList.splice(i, 1);
                                                                setState({ ...state });
                                                            }}
                                                        ></i>
                                                    </div>
                                                );
                                            })}
                                            {state.accountCertificateList.length < 10 ? (
                                                <Button
                                                    className="r-primary_btn medium_btn h32-btn"
                                                    style={{ width: 93 }}
                                                    onClick={() => setIsFormModalOpen(true)}
                                                >
                                                    Update
                                                </Button>
                                            ) : null}
                                        </Form.Item>
                                    </div>
                                    <Form.Item style={{ margin: '30px 0 0 0' }}>
                                        <div className="r-end">
                                            <Button
                                                className="r-primary_btn r-primary_largeBtn"
                                                style={{ width: 208 }}
                                                htmlType="submit"
                                                loading={loading}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                }
            />

            <OpeationPortFolioDrawer
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                portFolioEditInfo={portFolioEditInfo}
                setPortFolioInfo={val => {
                    console.log(val);
                    if (portFolioEditInfo.index !== -1) {
                        state.portFolios.splice(portFolioEditInfo.index, 1, val);
                    } else {
                        state.portFolios.push(val);
                    }
                    setState({ ...state });
                    setOpenDrawer(false);
                    setPortFolioEditInfo({ index: -1, info: {} });
                }}
            />

            <FormModal
                title="Certifications And Assessment"
                width={380}
                marBtm={10}
                isModalOpen={isFormModalOpen}
                handleOk={() => setIsFormModalOpen(false)}
                handleCancel={() => setIsFormModalOpen(false)}
                children={
                    <div className="curModal-box" key={'curModal-box'}>
                        <p className="form-tip">Certificate Name</p>
                        <Input
                            className="r-input"
                            maxLength={100}
                            value={accountCertificateInfo.certificateName}
                            onInput={(e: any) =>
                                setAccountCertificateInfo({
                                    ...accountCertificateInfo,
                                    certificateName: e.target.value,
                                })
                            }
                            placeholder=""
                        />
                        <p className="form-tip">Certificate URL</p>
                        <Input
                            className="r-input"
                            placeholder=""
                            maxLength={500}
                            value={accountCertificateInfo.certificateUrl}
                            onInput={(e: any) =>
                                setAccountCertificateInfo({
                                    ...accountCertificateInfo,
                                    certificateUrl: e.target.value,
                                })
                            }
                            prefix={<span>https://</span>}
                            suffix={
                                window.utils.isWebSite(`https://${accountCertificateInfo.certificateUrl}`) ? (
                                    <i className="url-succ-icon"></i>
                                ) : null
                            }
                        />
                        <p className="form-tip">Valid Period</p>
                        <div className="r-left">
                            <DatePicker
                                className={`r-datePicker flex1`}
                                placeholder="From"
                                value={accountCertificateInfo.validPeriodFrom}
                                onChange={(date, dateString) => {
                                    setAccountCertificateInfo({
                                        ...accountCertificateInfo,
                                        validPeriodFrom: date,
                                    });
                                }}
                                format="MMM YYYY"
                                picker="month"
                                suffixIcon={<i className="sel_time_icon"></i>}
                                // disabledDate={disabledDate}
                            />
                            <DatePicker
                                style={{ marginLeft: 12 }}
                                className={`r-datePicker flex1`}
                                placeholder="To"
                                value={accountCertificateInfo.validPeriodTo}
                                onChange={(date, dateString) => {
                                    setAccountCertificateInfo({
                                        ...accountCertificateInfo,
                                        validPeriodTo: date,
                                    });
                                }}
                                format="MMM YYYY"
                                picker="month"
                                suffixIcon={<i className="sel_time_icon"></i>}
                                // disabledDate={disabledDate}
                            />
                        </div>
                        <div className="r-center">
                            <Button
                                style={{ width: 106, marginTop: 40 }}
                                className="r-primary_btn r-primary_largeBtn"
                                disabled={
                                    !accountCertificateInfo.certificateName ||
                                    !accountCertificateInfo.validPeriodFrom ||
                                    !accountCertificateInfo.validPeriodTo
                                }
                                onClick={() => {
                                    let arr: any = [];
                                    if (accountCertificateInfo.editIndex !== -1) {
                                        state.accountCertificateList.splice(
                                            accountCertificateInfo.editIndex,
                                            1,
                                            accountCertificateInfo
                                        );
                                        arr = [...state.accountCertificateList];
                                    } else {
                                        arr = [...state.accountCertificateList, ...[accountCertificateInfo]];
                                    }
                                    setState({ ...state, accountCertificateList: arr });
                                    setIsFormModalOpen(false);
                                    setAccountCertificateInfo({
                                        certificateName: '',
                                        certificateUrl: '',
                                        validPeriodFrom: dayjs(new Date()),
                                        validPeriodTo: dayjs(new Date()),
                                        editIndex: -1,
                                    });
                                }}
                            >
                                Confirm
                            </Button>
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default EditProfessionalInformation;
