import React from 'react';
import './index.scss';
function RTabs({ tabLists, onDelTab }) {
    return (
        <div className="r-left" style={{ marginTop: tabLists.length ? 10 : 0, flexWrap: 'wrap' }}>
            {tabLists.map((label, i) => {
                return (
                    <div className="tabs-item r-left" key={`tabsitem-${i}`}>
                        {label}
                        <i className="tabs-del" onClick={() => onDelTab(i)}></i>
                    </div>
                );
            })}
        </div>
    );
}

export default RTabs;
