/* eslint-disable */
import React, { createContext, useContext, useState, useEffect, useRef, useMemo } from 'react';
import SendbirdChat from '@sendbird/chat';
import {
    GroupChannelModule,
    GroupChannelFilter,
    GroupChannelListOrder,
    MessageFilter,
    MessageCollectionInitPolicy,
} from '@sendbird/chat/groupChannel';
import { message } from 'antd';
/* eslint-disable */
const SendBirdContext = createContext<any>(null);
let sb;
export const SendBirdProvider = ({ children }) => {
    const [messageStatus, setMessageStatus] = useState(''); // 消息发送状态
    const [channelLoading, setChannelLoading] = useState(false); // 创建群组
    const sbRef: any = useRef(null);
    const [state, updateState] = useState<any>({
        currentlyJoinedChannel: null,
        messages: [],
        channels: [],
        messageToUpdate: null,
        messageCollection: null as any,
        loading: false,
        error: false,
        count: 0,
        sb: null,
    });
    const sortedChannelsArr = useMemo(() => {
        return [...state.channels].sort((a, b) => {
            const aTime = a.lastMessage?.createdAt || 0;
            const bTime = b.lastMessage?.createdAt || 0;
            return bTime - aTime;
        });
    }, [state.channels]);
    const [listLoading, setListLoading] = useState(false);
    const stateRef = useRef<any>();
    stateRef.current = state;
    // 初始化并连接用户
    const initializeSendBird = async (userId, accessToken, sendbirdId) => {
        const sb1 = await SendbirdChat.init({
            appId: sendbirdId || '8B052E06-09F8-427F-A8DC-90382EC8A874',
            modules: [new GroupChannelModule()],
        });

        await sb1.connect(userId, accessToken);
        console.log(sb1);
        sbRef.current = sb1;
        sb = sb1;
        const [channels, error] = await loadChannels(sb1);
        if (error) {
            console.log(error);
        }
        console.log('====================================');
        console.log('更新频道', channels);
        console.log('====================================');
        updateState({ ...state, channels: channels, loading: false });
        // handleCreateChannel();
        getUnRead();
    };
    const refrshChannel = async () => {
        if (!sb) return;
        const [channels, error] = await loadChannels(sb);
        if (error) {
            console.log(error);
        }
        updateState({ ...state, channels: channels, loading: false });
        getUnRead();
    };
    const loadChannels = async sb => {
        const groupChannelFilter = new GroupChannelFilter();
        groupChannelFilter.includeEmpty = true;
        const collection = sb.groupChannel.createGroupChannelCollection({
            filter: groupChannelFilter,
            order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
        });
        collection.setGroupChannelCollectionHandler({
            onChannelsAdded: (context, channels) => {
                const updatedChannels = [...channels, ...stateRef.current.channels];
                updateState({ ...stateRef.current, channels: updatedChannels, applicationUsers: [] });
                console.log('channel add', channels);
            },
            onChannelsDeleted: (context, channels) => {
                const updatedChannels = stateRef.current.channels.filter(channel => {
                    return !channels.includes(channel.url);
                });
                updateState({ ...stateRef.current, channels: updatedChannels });
            },
            onChannelsUpdated: (context, channels) => {
                const updatedChannels = stateRef.current.channels.map(channel => {
                    const updatedChannel = channels.find(incomingChannel => incomingChannel.url === channel.url);
                    if (updatedChannel) {
                        return updatedChannel;
                    } else {
                        return channel;
                    }
                });
                console.log('channel update', channels);
                updateState({ ...stateRef.current, channels: updatedChannels });
                getUnRead();
            },
        });
        const channels = await collection.loadMore();
        return [channels, null];
    };
    const [channelInfo, setChannelInfo] = useState<any>({});
    const handleCreateChannel = async params => {
        setChannelLoading(true);
        try {
            const groupChannelParams: any = {
                ...params,
                isDistinct: true,
            };
            setChannelInfo(JSON.parse(params.data));
            const groupChannel = await sb.groupChannel.createChannel(groupChannelParams);
            console.log('创建成功', groupChannel);
            handleSelectChannel(groupChannel);
            // await groupChannel.updateMetaData({ ...channelInfo }, true);
            setChannelLoading(false);
        } catch (error) {
            console.log('创建失败', error);
            setChannelLoading(false);
        }
    };
    // useEffect(() => {
    //     if (state.currentlyJoinedChannel) {
    //         state.currentlyJoinedChannel.updateMetaData({ ...channelInfo }, true);
    //     }
    // }, [state.currentlyJoinedChannel]);
    const handleSelectChannel = async val => {
        console.log('====================================');
        console.log(val);
        console.log('====================================');
        // setSelectedChannel(val);
        if (state.messageCollection && state.messageCollection.dispose) {
            state.messageCollection?.dispose();
        }
        if (!val) {
            // 离开页面
            updateState({ ...stateRef.current, messages: [], messageCollection: null, currentlyJoinedChannel: null });
            setListLoading(false);
            getUnRead();
            return;
        }
        if (state.currentlyJoinedChannel?.url === val._url) {
            state.currentlyJoinedChannel?.markAsRead();
            return null;
        }
        console.log('触发');
        setListLoading(true);
        // updateState({ ...state, loading: true });
        const channel = state.channels.find(channel => channel._url === val._url) || val;
        channel?.markAsRead();

        // const data = await channel.getMetaData(['data']);
        // console.log('meta-data-----', data);
        const onCacheResult = (err, messages) => {
            updateState({
                ...stateRef.current,
                currentlyJoinedChannel: channel,
                messages: messages.reverse(),
                loading: false,
            });
        };
        const onApiResult = (err, messages) => {
            setListLoading(false);
            updateState({
                ...stateRef.current,
                currentlyJoinedChannel: channel,
                messages: messages.reverse(),
                loading: false,
            });
        };
        const collection = loadMessages(channel, onCacheResult, onApiResult);
        updateState({ ...state, messageCollection: collection });
    };
    const loadMessages = (channel, onCacheResult, onApiResult) => {
        const messageFilter = new MessageFilter();

        const collection = channel.createMessageCollection({
            filter: messageFilter,
            startingPoint: Date.now(),
            limit: 100,
        });

        collection.setMessageCollectionHandler({
            onMessagesAdded: (context, channel, messages) => {
                const updatedMessages = [...stateRef.current.messages, ...messages];
                updateState({ ...stateRef.current, messages: updatedMessages });
                getUnRead();
            },
            onMessagesUpdated: (context: any, channel: any, messages: any) => {
                console.log(context.source, 'update message:', messages);
                setUpdateMessages(messages);
            },
            onChannelUpdated: (context, channel) => {},
            onChannelDeleted: (context, channelUrl) => {},
            onHugeGapDetected: () => {},
        });
        collection
            .initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
            .onCacheResult(onCacheResult)
            .onApiResult(onApiResult);
        return collection;
    };
    const [updateMessages, setUpdateMessages] = useState([]);
    const updateMessage = () => {
        const isEndTimer = setInterval(() => {
            console.log('News is being updated');
            const stateMessages = JSON.parse(JSON.stringify(state.messages));
            updateMessages.forEach((i: any, index: number) => {
                const indexOfExisting = stateMessages.findIndex((message: any) => {
                    return i.reqId === message.reqId;
                });
                if (indexOfExisting !== -1) {
                    stateMessages[indexOfExisting] = i;
                    clearInterval(isEndTimer);
                    updateState({ ...stateRef.current, messages: stateMessages });
                }
            });
        }, 1000);
    };
    useEffect(() => {
        if (updateMessages.length) {
            updateMessage();
        }
    }, [updateMessages]);
    // useEffect(() => {
    //     getUnRead();
    // }, [state.channels]);
    const getUnRead = async () => {
        if (!sb || !sb.groupChannel) return;
        const count = await sb.groupChannel.getTotalUnreadMessageCount();
        updateState({ ...stateRef.current, count });
    };
    const sendMessage = async val => {
        const userMessageParams = {
            message: val,
        };
        state.currentlyJoinedChannel
            .sendUserMessage(userMessageParams)
            .onSucceeded(message => {
                console.log('发送成功', message);
            })
            .onFailed(error => {
                console.log(error);
                console.log('failed');
            });
    };
    const destroySendBird = () => {
        if (sb) {
            sb.disconnect(error => {
                if (error) {
                    console.error('Error disconnecting SendBird:', error);
                } else {
                    console.log('SendBird disconnected');
                }
            });
            sb = null; // 释放资源
        }
    };
    return (
        <SendBirdContext.Provider
            value={{
                messageStatus,
                initializeSendBird,
                handleSelectChannel,
                sendMessage,
                handleCreateChannel,
                state,
                listLoading,
                destroySendBird,
                channelLoading,
                refrshChannel,
                sortedChannelsArr,
            }}
        >
            {children}
        </SendBirdContext.Provider>
    );
};

// 自定义 Hook 用来访问 SendBird 上下文
export const useSendBird = () => {
    return useContext(SendBirdContext);
};
