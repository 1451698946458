import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import api from '@/api/feedback';
import { setProfileInfo } from '@/store/stateSlice';
import { useAppDispatch } from '@/store/hooks';

interface FeedbackContextType {
    feedbackCount: number;
    refreshFeedbackCount: () => void;
}

const FeedbackContext = createContext<FeedbackContextType | undefined>(undefined);

export const FeedbackProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [feedbackCount, setFeedbackCount] = useState<number>(0);
    const dispatch = useAppDispatch();

    const getFeedCount = React.useCallback(() => {
        const token = sessionStorage.getItem('admin-satoken');
        if (!token) return; // 没有 token 时不执行请求

        api.getUnReadCount(res => {
            const { data, code, message } = res;
            if (code === 200) {
                setFeedbackCount(data);
            } else {
                window.format.alert('error', message);
            }
        });

        api.getProfileInfo({}, res => {
            const { code, data, message } = res;
            if (code === 200) {
                dispatch(setProfileInfo(data));
            } else {
                window.utils.alert('error', message);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getFeedCount(); // 初始化调用

        const interval = setInterval(() => {
            getFeedCount();
        }, 60000);

        const handleStorageChange = (e: StorageEvent) => {
            if (e.key === 'admin-satoken' && sessionStorage.getItem('admin-satoken')) {
                getFeedCount();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            clearInterval(interval); // 清理定时器
            window.removeEventListener('storage', handleStorageChange); // 移除监听器
        };
    }, [getFeedCount]);

    return (
        <FeedbackContext.Provider value={{ feedbackCount, refreshFeedbackCount: getFeedCount }}>
            {children}
        </FeedbackContext.Provider>
    );
};

export const useFeedback = () => {
    const context = useContext(FeedbackContext);
    if (!context) {
        throw new Error('useFeedback must be used within a FeedbackProvider');
    }
    return context;
};
