import axios from '../utils/index';
import { Pat } from '@/typings/request';
const profileApi = {
    getProfileInfo(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/account/profile-info`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getPublicProfileInfo(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/account/profile-info-public/${data.userId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getProfileActionlog(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/account/profile-actionLog/${data.pageNo}/${data.pageSize}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    setProfileLink(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/account/profile-personalName`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    editThirdLoginEamil(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/profile-email-edit`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    sendThirdLoginEamilCode(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/profile-email-send-code`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    checkThirdLoginEamilCode(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/profile-email-check`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    setProfileInfoAll(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/account/profile-all-information`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    connectCalendarGoogle(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/calendarGoogle-connect`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    disconnectCalendarGoogle(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/account/calendarGoogle-disconnect`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    connectCalendarOutlook(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/calendarOutlook-connect`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    disconnectCalendarOutlook(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/account/calendarOutlook-disconnect`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },

    connectLinkedin(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/linkedin-connect`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    disconnectLinkedin(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/account/linkedin-disconnect`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};

export default profileApi;
