import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './index.scss';
import KeepAlive, { useAliveController } from 'react-activation';
import usePrevious from '@/hook/usePrevious';
import { Popover, Image } from 'antd';
import Notifications from '@/assets/img/Notifications.png';
import CaretDownIcon from '@/assets/img/arrow-down.png';
import { useDispatch, useSelector } from 'react-redux';
import { useFeedback } from '@/context/notificationContext';
import AlertModal from '@/components/modal/alert-modal';
import { useSendBird } from '@/context/SendBirdProvider';
import useFireBase from '@/hook/useFairBase';
import { setUserStatus, setUserStatusTip } from '@/store/stateSlice';
const LayoutMain = props => {
    const location = useLocation();
    const navigate = useNavigate();
    const { userStatus, userStatusTip } = useSelector((state: any) => state.state);
    const dispatch = useDispatch();
    const { feedbackCount, refreshFeedbackCount } = useFeedback();
    const { profileInfo } = useSelector((state: any) => state.state);
    const { initDataBase, offDataBase } = useFireBase();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const prevLocation: any = usePrevious(location);
    const { clear } = useAliveController();
    const commonplace = useSelector((state: any) => state.commonplace);
    useEffect(() => {
        filterRoute();
    }, [location]);
    useEffect(() => {
        filterRoute();
        initDataBase(profileInfo.accountId);
        return () => {
            offDataBase();
        };
    }, []);
    const filterRoute = () => {
        const currentPath = props.routes.filter(i => {
            return location.pathname.includes(i.path) && i.isShowOnNav;
        });
        if (currentPath[0] && currentPath[0].path) setCurrent(currentPath[0].name);
    };
    const [current, setCurrent] = useState('');
    const checkNav = e => {
        console.log(e.name);
        clear();
        setCurrent(e.name);
        navigate(e.path);
    };

    const logout = () => {
        setIsModalOpen(true);
    };
    const confirmLogout = () => {
        navigate('/login');
        window.sessionStorage.clear();
    };
    const { initializeSendBird, state, destroySendBird } = useSendBird();
    const { adminId, sendbirdId, sendbirdAccessToken } = useSelector((state: any) => state.state.chartInfo);
    useEffect(() => {
        refreshFeedbackCount();
        const userId = window.sessionStorage.getItem('accountId') || adminId;
        console.log(userId);
        const accessToken = sendbirdAccessToken;
        initializeSendBird(userId, accessToken, sendbirdId);
        return () => {
            console.log('====================================');
            console.log('离开');
            console.log('====================================');
            destroySendBird();
        };
    }, []);
    // 禁用弹窗
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const statusTip = {
        2: `Your account has been suspended. Please contact admin@talenaut.com`,
        4: `Your account has been deleted. Please contact admin@talenaut.com`,
    };
    const handleOk = () => {
        setIsModalOpen1(false);
        dispatch(setUserStatus(1));
        navigate('/login');
    };
    useEffect(() => {
        if (userStatus !== 1) {
            setIsModalOpen1(true);
        }
    }, [userStatus]);
    return (
        <>
            <div className="layout-container">
                <div className="header_nav r-left">
                    <img src={require('@/assets/img/Logo-nav.png')} className="navTop-icon" alt="" />
                    <div className="r-left">
                        {props.routes.map((item, i) => {
                            return item.isShowOnNav ? (
                                <div
                                    key={`navTop-${i}`}
                                    className={`navItem ${current === item.name ? 'active' : ''}`}
                                    onClick={() => checkNav(item)}
                                >
                                    {item.name}
                                    {item.name === 'Message' && state.count ? (
                                        <span className="message-count">{state.count > 99 ? '99+' : state.count}</span>
                                    ) : null}
                                </div>
                            ) : null;
                        })}
                    </div>
                    <div
                        className="Notifications-box"
                        onClick={() => {
                            navigate('/notification');
                            setCurrent('');
                        }}
                    >
                        <img src={Notifications} className="Notifications-icon" alt="" />
                        {feedbackCount ? (
                            <span className="notification-num">{feedbackCount > 99 ? '99+' : feedbackCount}</span>
                        ) : null}
                    </div>
                    <div
                        onClick={() => {
                            navigate('/profile');
                            setCurrent('');
                        }}
                    >
                        <Image
                            className="ad-name"
                            preview={false}
                            style={{ minWidth: 40 }}
                            src={
                                profileInfo.accountInformationPOJO.profilePhoto
                                    ? profileInfo.accountInformationPOJO.profilePhoto
                                    : ''
                            }
                            fallback={'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg'}
                        />
                    </div>
                    <Popover
                        placement="bottomRight"
                        title={''}
                        trigger="hover"
                        rootClassName="navTop-pop"
                        style={{ width: 150 }}
                        content={
                            <div className="tableOpeation-box job-opBox">
                                <div className={`tb_item`} onClick={() => logout()}>
                                    Logout
                                </div>
                            </div>
                        }
                        arrow={false}
                    >
                        <div className="r-left logout-popTip">
                            <img src={CaretDownIcon} className="CaretDown-icon" alt="" />
                        </div>
                    </Popover>
                </div>
                <div className="content-layout">
                    <Routes>
                        {props.routes.map((route: any, index: any) => {
                            return route.KeepAlive ? (
                                <Route
                                    path={route.path}
                                    key={index}
                                    element={
                                        <KeepAlive cacheKey={route.path} saveScrollPosition="screen" name={route.name}>
                                            <route.component
                                                prevLocation={prevLocation}
                                                routes={route.childrens ? route.childrens : []}
                                            />
                                        </KeepAlive>
                                    }
                                ></Route>
                            ) : (
                                <Route
                                    path={route.path}
                                    key={index}
                                    element={
                                        <route.component
                                            prevLocation={prevLocation}
                                            routes={route.childrens ? route.childrens : []}
                                        />
                                    }
                                ></Route>
                            );
                        })}
                    </Routes>
                </div>
            </div>
            <AlertModal
                message="Are you sure you want to log out of the current account?"
                cancelText="Cancel"
                confirmText="Confirm Logout"
                isModalOpen={isModalOpen}
                handleOk={() => {
                    confirmLogout();
                }}
                handleCancel={() => setIsModalOpen(false)}
            />

            {/* del modal */}
            <AlertModal
                message={userStatusTip || ''}
                cancelText=""
                confirmText="Continue"
                isModalOpen={isModalOpen1}
                handleOk={handleOk}
            />
        </>
    );
};

export default LayoutMain;
