import axios from '../utils/index';
import { Pat, HttpResponse } from '@/typings/request';
const uploadApi = {
    uploadFile(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/public/upload/file`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    uploadMultiFile(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/admin/upload/multi-file`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getFilePreSignedUrl(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/public/upload/get-preSigned-for-upload`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getFileShowUrl(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/public/upload/get-preSigned`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};
export default uploadApi;
