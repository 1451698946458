import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import { useMount } from 'ahooks';

interface CodeInputProps {
    setCodeArr: (codes: string[]) => void;
}

const CodeInput: React.FC<CodeInputProps> = ({ setCodeArr }) => {
    const [codeArrNew, setCodeArrNew] = useState(['', '', '', '', '', '']); // 6位验证码数组
    const inputRefs = useRef<HTMLInputElement[]>([]); // 使用 ref 管理每个输入框

    // 清空输入框值并聚焦到第一个输入框
    // useEffect(() => {
    //     setCodeArrNew(['', '', '', '', '', '']); // 清空状态
    //     setCodeArr(['', '', '', '', '', '']); // 通知父组件
    //     inputRefs.current[0]?.focus(); // 聚焦第一个输入框
    // }, []);
    useMount(() => {
        setCodeArrNew(['', '', '', '', '', '']); // 清空状态
        setCodeArr(['', '', '', '', '', '']); // 通知父组件
        inputRefs.current[0]?.focus(); // 聚焦第一个输入框
    });
    // 处理输入框变化
    const handleChange = (value: string, index: number) => {
        const newCodeArr = [...codeArrNew];
        newCodeArr[index] = value.replace(/\D/g, ''); // 仅保留数字
        setCodeArrNew(newCodeArr);
        setCodeArr(newCodeArr);

        // 自动跳转到下一个输入框
        if (value && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    // 处理键盘操作
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !codeArrNew[index] && index > 0) {
            inputRefs.current[index - 1]?.focus(); // 删除时跳回前一个输入框
        }
    };

    // 初始化所有输入框
    const handleFocus = (index: number) => {
        inputRefs.current[index]?.select(); // 聚焦时选中内容
    };

    return (
        <div className="numInputs">
            {codeArrNew.map((value, index) => (
                <input
                    key={`otp-input-${index}`}
                    ref={el => (inputRefs.current[index] = el!)} // 存储输入框的引用
                    maxLength={1} // 限制单字符输入
                    value={value} // 绑定值
                    onChange={e => handleChange(e.target.value, index)} // 更新值
                    onKeyDown={e => handleKeyDown(e, index)} // 处理键盘事件
                    onFocus={() => handleFocus(index)} // 聚焦时选中内容
                    type="tel" // 数字键盘
                    autoComplete="off" // 禁用自动填充
                />
            ))}
        </div>
    );
};

export default CodeInput;
