const env = process.env.REACT_APP_NODE_ENV;
const config = {
    localUrl:
        env === 'development'
            ? 'http://localhost:3000'
            : env === 'test'
            ? 'http://192.168.50.101/talenaut-candidate'
            : 'https://candidate.talenaut.com',
    googleAuthKey: '604511413297-f340od3fdtsns3damb73s6q610kommu2.apps.googleusercontent.com',
    linkinedAuthKey: '78w81o8t60w0q9',
    outlookAuthKey: '61989628-4057-434f-ab13-0e2cee4fbe75',
};
export default config;
