import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AutoComplete, Button, DatePicker, Form, FormProps, Input, message, Select, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import UploadImg from '@/components/upload/upload-img';
import RCheckBox from '@/components/checkbox';
import { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import RTabs from './components/tabs';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { defaultInfo } from '@/typings/allType';
import api from '@/api/login';
import debounce from 'lodash/debounce';
const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
};
interface RelevantExperience {
    jobRoles: string;
    jobRolesCode: string;
    occupationalType: string;
    companyName: string;
    startDate: any;
    endDate: any;
    currentEmployedHereFlag: boolean;
    skillsShow: string[];
    skills: string;
}
function EducationInformation() {
    const navigate = useNavigate();
    const messageApi = useGlobalMessage();
    const [form] = Form.useForm();
    const formRef = useRef<any>(null);
    const [jonForm] = Form.useForm();
    const jobFormRef = useRef<any>(null);
    const [loading, setLoading] = useState(false);
    const [skillStr, setSkillStr] = useState('');
    const [state, setState] = useState({
        graduation: '',
        highestDegree: '',
        major: '',
    });
    const [editIndex, setEditIndex] = useState(-1);
    const [relevantExperience, setRelevantExperience] = useState({
        jobRoles: '',
        jobRolesCode: '',
        jobTitleInfo: {} as defaultInfo,
        occupationalType: '',
        companyName: '',
        startDate: null as any,
        endDate: null as any,
        currentEmployedHereFlag: false,
        certificateId: '',
        skillsShow: [] as string[],
        skills: '',
    });
    const [jobInfoList, setJobInfoList] = useState<defaultInfo[]>([]);
    const enter = e => {
        if (e.keyCode === 13) {
            const val = e.target.value;
            if (!val) return;
            if (relevantExperience.skillsShow.length >= 15) {
                messageApi.error('Add up to 15 skills.');
                return;
            }
            relevantExperience.skillsShow.push(val);
            setSkillStr('');
            setRelevantExperience({ ...relevantExperience });
        }
    };
    const saveJobInfo = async () => {
        try {
            if (jobInfoList.length >= 7) {
                messageApi.error('You can only add up to 7 relevant experiences');
                return;
            }
            const values = await jonForm.validateFields();
            console.log('Validation Success:', values);
            if (editIndex !== -1) {
                jobInfoList.splice(editIndex, 1, relevantExperience);
            } else {
                jobInfoList.push({ ...relevantExperience });
            }
            setJobInfoList([...jobInfoList]);
            setSkillStr('');
            const initialValues = {
                jobRoles: '',
                jobTitleInfo: {} as defaultInfo,
                occupationalType: '',
                companyName: '',
                startDate: null,
                endDate: null,
                currentEmployedHereFlag: false,
                certificateId: '',
                skillsShow: [] as string[],
                skills: '',
                jobRolesCode: '',
            };
            setEditIndex(-1);
            setRelevantExperience(initialValues);
            jonForm.setFieldsValue(initialValues);
            setSkillOptions([]);
        } catch (error) {
            console.log('Validation Failed:', error);
        }
    };
    const validateExperience = (data: RelevantExperience) => {
        const errors: string[] = [];
        if (!data.jobRoles) {
            errors.push('Job Role is required.');
        }
        if (!data.occupationalType) {
            errors.push('Occupational Type is required.');
        }
        if (!data.startDate) {
            errors.push('Start Date is required.');
        }
        if (data.occupationalType === 'Company employee' && !data.companyName) {
            errors.push('Company Name is required when Occupational Type is Company employee.');
        }
        if (!data.currentEmployedHereFlag && !data.endDate) {
            errors.push('End Date is required when not currently employed.');
        }
        return {
            isValid: errors.length === 0,
            errors,
        };
    };
    const continueClick = async () => {
        try {
            const values = await form.validateFields();
            setLoading(true);
            const experienceList = jobInfoList.length
                ? jobInfoList.map(item => {
                      return Object.assign(item, {
                          skills: item.skillsShow.length ? item.skillsShow.join(',') : '',
                          startDate: dayjs(new Date(item.startDate)).format('YYYY-MM'),
                          endDate: item.currentEmployedHereFlag ? '' : dayjs(new Date(item.endDate)).format('YYYY-MM'),
                          currentEmployedHereFlag: item.currentEmployedHereFlag ? 1 : 0,
                      });
                  })
                : [];
            const { isValid } = validateExperience(relevantExperience);
            if (isValid && experienceList.length < 7) {
                const item = JSON.parse(JSON.stringify(relevantExperience));
                experienceList.push(
                    Object.assign(item, {
                        skills: item.skillsShow.length ? item.skillsShow.join(',') : '',
                        startDate: dayjs(new Date(item.startDate)).format('YYYY-MM'),
                        endDate: item.currentEmployedHereFlag ? '' : dayjs(new Date(item.endDate)).format('YYYY-MM'),
                        currentEmployedHereFlag: item.currentEmployedHereFlag ? 1 : 0,
                    })
                );
            }
            const pat = {
                accountExperienceVOList: [...experienceList],
                graduation: state.graduation,
                highestDegree: state.highestDegree,
                major: state.major,
            };
            console.log('====================================');
            console.log(pat);
            console.log('====================================');
            const educationInfo = JSON.stringify({ ...state, jobInfoList: jobInfoList });
            window.sessionStorage.setItem('educationInfo', educationInfo);
            api.setPersionExperience(pat, res => {
                setLoading(false);
                if (res.code === 200) {
                    navigate('/home');
                    window.sessionStorage.removeItem('educationInfo');
                    window.sessionStorage.removeItem('skillInfo');
                    window.sessionStorage.removeItem('persionInfo');
                } else {
                    messageApi.error(res.message);
                }
            });
        } catch (error) {
            console.log('Validation Failed:', error);
        }
    };
    // job title
    const [autocomplateLoading, setAutocomplateLoading] = useState(false);
    const [jobTitle, setJobTitle] = useState('');
    const [jobOptions, setJobOptions] = useState<any[]>([]);
    const getJobOption = search => {
        if (!search) {
            setJobOptions([]);
            return;
        }
        setAutocomplateLoading(true);
        api.getJobRoleList({ keyWord: search }, res => {
            setAutocomplateLoading(false);
            if (res.code === 200) {
                const results = res.data.map((item: any) => ({
                    value: item.title,
                    code: item.code,
                    label: item.title,
                }));
                setJobOptions(results);
            } else {
                setJobOptions([]);
                messageApi.error(res.message);
            }
        });
    };
    const searchList: any = useCallback(
        debounce(params => {
            getJobOption(params);
            console.log('search');
        }, 1000),
        []
    );
    // skill search
    const [skill, setSkill] = useState('');
    const [skillOptions, setSkillOptions] = useState<any[]>([]);
    const getSkillOption = params => {
        setAutocomplateLoading(true);
        api.getSkillList({ keyWord: params.search, code: params.code || '15-1241.00' }, res => {
            setAutocomplateLoading(false);
            if (res.code === 200) {
                const results = res.data.map((item: any) => ({ value: item.title, label: item.title }));
                setSkillOptions(results);
            } else {
                setSkillOptions([]);
                messageApi.error(res.message);
            }
        });
    };
    const searchSkillList: any = useCallback(
        debounce(params => {
            if (!params.search) {
                setSkillOptions([]);
                return;
            }
            getSkillOption(params);
            console.log('search');
        }, 1000),
        []
    );
    const backNav = () => {
        const educationInfo = JSON.stringify({ ...state, jobInfoList: jobInfoList });
        window.sessionStorage.setItem('educationInfo', educationInfo);
        navigate(-1);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        const educationInfo = window.sessionStorage.getItem('educationInfo');
        if (educationInfo) {
            const info = JSON.parse(educationInfo);
            const initialValues = { ...info };
            setJobInfoList(info.jobInfoList || []);
            setState({ ...initialValues });
            form.setFieldsValue(initialValues);
        }
    }, []);
    return (
        <div className="login-main information-main">
            <div className="nav-top r-left">
                <img className="left-logo" src={require('@/assets/img/talenaut_logo-color.png')} alt="" />
            </div>
            <div className="login-formMin r-center">
                <div className="informationCenter-box">
                    <div className="info-tit">Personal Information</div>
                    <p className="info-tip">
                        Please complete your personal information to search for new job opportunities.
                    </p>
                    <div className="pri-tit">Education</div>
                    <div className="information-formBox">
                        <Form
                            name="basic-school-form"
                            layout="vertical"
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            validateTrigger="onBlur"
                            form={form}
                            ref={formRef}
                            onKeyDown={event => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                }
                            }}
                        >
                            <Form.Item
                                className="flex1"
                                label="What university did you graduate from?"
                                name="graduation"
                                rules={[{ required: true, message: ' ' }]}
                            >
                                <Input
                                    className="r-input"
                                    placeholder=""
                                    maxLength={200}
                                    value={state.graduation}
                                    onInput={(e: any) => {
                                        setState({ ...state, graduation: e.target.value });
                                        form.setFieldsValue({ graduation: e.target.value });
                                    }}
                                />
                            </Form.Item>
                            <div className="r-left">
                                <Form.Item
                                    className="flex1"
                                    label="Highest Degree"
                                    rules={[{ required: true, message: '' }]}
                                    name={'highestDegree'}
                                >
                                    <Select
                                        className="r-select"
                                        value={state.highestDegree}
                                        onChange={val => {
                                            setState({ ...state, highestDegree: val });
                                            form.setFieldsValue({ highestDegree: val });
                                        }}
                                        suffixIcon={<i className="down-right-icon"></i>}
                                        options={[
                                            { label: 'Bachelor’s Degree', value: 'Bachelor’s Degree' },
                                            { label: 'Master’s Degree', value: 'Master’s Degree' },
                                            { label: 'Doctorate', value: 'Doctorate' },
                                            { label: 'Certifications', value: 'Certifications' },
                                        ]}
                                    />
                                </Form.Item>
                                <span className="space20"></span>
                                <Form.Item
                                    className="flex1"
                                    label="Major"
                                    name={'major'}
                                    rules={[{ required: true, message: ' ' }]}
                                >
                                    <Input
                                        className="r-input"
                                        placeholder=""
                                        maxLength={200}
                                        value={state.major}
                                        onInput={(e: any) => {
                                            setState({ ...state, major: e.target.value });
                                            form.setFieldsValue({ major: e.target.value });
                                        }}
                                    />
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    <div className="pri-tit">Relevant Experience</div>
                    <div className="experience-cardBox">
                        <Form
                            name="basic-experience-form"
                            layout="vertical"
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            form={jonForm}
                            ref={jobFormRef}
                            onKeyDown={event => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                }
                            }}
                        >
                            <div className="r-left">
                                <Form.Item
                                    className="flex1"
                                    label="Job Roles"
                                    name={'jobRoles'}
                                    rules={[{ required: true, message: 'Please enter a job role' }]}
                                >
                                    <AutoComplete
                                        options={jobOptions}
                                        className="r-autoComplate"
                                        style={{ width: '100%', height: 44, borderRadius: 12 }}
                                        value={jobTitle}
                                        onBlur={() => {
                                            setJobOptions([]);
                                        }}
                                        suffixIcon={<i className="down-right-icon"></i>}
                                        onSelect={val => {
                                            const jobTitleInfo: any = jobOptions.find(v => v.label === val);
                                            setJobTitle(jobTitleInfo.label);
                                            setRelevantExperience({
                                                ...relevantExperience,
                                                jobRoles: jobTitleInfo.label,
                                                jobRolesCode: jobTitleInfo.code,
                                                jobTitleInfo: { ...jobTitleInfo },
                                                skills: '',
                                                skillsShow: [],
                                            });
                                            jonForm.setFieldsValue({ jobRoles: jobTitleInfo.label });
                                            getSkillOption({ search: '', code: jobTitleInfo.code });
                                        }}
                                        onSearch={val => {
                                            searchList(val);
                                        }}
                                        placeholder=""
                                        notFoundContent={
                                            <>
                                                {autocomplateLoading && (
                                                    <div className="r-center">
                                                        <Spin />
                                                    </div>
                                                )}
                                                {!autocomplateLoading && <div>No data</div>}
                                            </>
                                        }
                                    />
                                </Form.Item>
                                <span className="space20"></span>
                                <Form.Item
                                    className="flex1"
                                    label="Occupational Type"
                                    rules={[{ required: true, message: 'Please select a occupational type' }]}
                                    name={'occupationalType'}
                                >
                                    <Select
                                        className="r-select white-bg"
                                        suffixIcon={<i className="down-right-icon"></i>}
                                        value={relevantExperience.occupationalType}
                                        onChange={(e: any) => {
                                            setRelevantExperience({ ...relevantExperience, occupationalType: e });
                                            jonForm.setFieldsValue({ occupationalType: e });
                                        }}
                                        options={[
                                            { label: 'Company employee', value: 'Company employee' },
                                            { label: 'Freelancer', value: 'Freelancer' },
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                            {relevantExperience.occupationalType === 'Company employee' ? (
                                <Form.Item
                                    className="flex1"
                                    label="Company Name"
                                    name={'companyName'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the company name',
                                        },
                                    ]}
                                >
                                    <Input
                                        className="r-input white-bg"
                                        placeholder=""
                                        maxLength={200}
                                        value={relevantExperience.companyName}
                                        onInput={(e: any) => {
                                            setRelevantExperience({
                                                ...relevantExperience,
                                                companyName: e.target.value,
                                            });
                                            jonForm.setFieldsValue({ companyName: e.target.value });
                                        }}
                                    />
                                </Form.Item>
                            ) : null}
                            <div className="r-left">
                                <Form.Item
                                    className="flex1"
                                    label="Start Date"
                                    name={'startDate'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a start date',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        className={`r-datePicker white-bg`}
                                        placeholder=""
                                        format={'MMM YYYY'}
                                        value={relevantExperience.startDate ?? dayjs(new Date())}
                                        onChange={val => {
                                            setRelevantExperience({ ...relevantExperience, startDate: val });
                                            jonForm.setFieldsValue({ startDate: val });
                                        }}
                                        picker="month"
                                        suffixIcon={<i className="sel_time_icon"></i>}
                                        disabledDate={disabledDate}
                                    />
                                </Form.Item>
                                <span className="space20"></span>
                                <Form.Item
                                    className="flex1"
                                    label="End Date"
                                    name={'endDate'}
                                    rules={[
                                        {
                                            required: !relevantExperience.currentEmployedHereFlag,
                                            message: 'Please select a end date',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        className={`r-datePicker white-bg`}
                                        placeholder=""
                                        format={'MMM YYYY'}
                                        disabled={relevantExperience.currentEmployedHereFlag}
                                        value={relevantExperience.endDate ?? dayjs(new Date())}
                                        onChange={val => {
                                            setRelevantExperience({ ...relevantExperience, endDate: val });
                                            jonForm.setFieldsValue({ endDate: val });
                                        }}
                                        picker="month"
                                        suffixIcon={<i className="sel_time_icon"></i>}
                                        disabledDate={disabledDate}
                                    />
                                </Form.Item>
                                <span className="space20"></span>
                                <Form.Item label={<div></div>}>
                                    <RCheckBox
                                        checkInfo={{
                                            isChecked: relevantExperience.currentEmployedHereFlag,
                                            label: 'Currently Employed Here',
                                        }}
                                        width={'100%'}
                                        setCheckInfo={() => {
                                            setRelevantExperience({
                                                ...relevantExperience,
                                                currentEmployedHereFlag: !relevantExperience.currentEmployedHereFlag,
                                                // endDate: dayjs(new Date()),
                                            });
                                            jonForm.setFieldsValue({ endDate: '' });
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <Form.Item style={{ marginBottom: 0 }} label="Skills required for this job">
                                <Select
                                    options={skillOptions}
                                    className="r-autoComplate"
                                    style={{ width: '100%', height: 44 }}
                                    value={skill}
                                    showSearch
                                    suffixIcon={<></>}
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    disabled={!relevantExperience.jobRoles}
                                    onChange={val => {
                                        setSkill(val);
                                    }}
                                    onSelect={val => {
                                        if (relevantExperience.skillsShow.length >= 15) {
                                            messageApi.error('Add up to 15 skills.');
                                            return;
                                        }
                                        if (relevantExperience.skillsShow.length >= 15) {
                                            messageApi.error('Add up to 15 skills.');
                                            return;
                                        }
                                        const isHave = JSON.parse(
                                            JSON.stringify(relevantExperience.skillsShow)
                                        ).findIndex((v, index) => v === val);
                                        if (isHave !== -1) {
                                            setSkill('');
                                            messageApi.error('You have added this skill.');
                                            return;
                                        }
                                        relevantExperience.skillsShow.push(val);
                                        setRelevantExperience({ ...relevantExperience });
                                        setSkill('');
                                    }}
                                    placeholder=""
                                    notFoundContent={
                                        <>
                                            {autocomplateLoading && (
                                                <div className="r-center">
                                                    <Spin />
                                                </div>
                                            )}
                                            {!autocomplateLoading && <div>No data</div>}
                                        </>
                                    }
                                />
                                <RTabs
                                    tabLists={relevantExperience.skillsShow}
                                    onDelTab={(i: number) => {
                                        relevantExperience.skillsShow.splice(i, 1);
                                        setRelevantExperience({ ...relevantExperience });
                                    }}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="r-end" style={{ margin: '16px 0' }}>
                        <Button
                            className="r-primary_btn r-primaryNoBg_btn"
                            style={{ width: 248 }}
                            onClick={() => saveJobInfo()}
                        >
                            Save
                        </Button>
                    </div>
                    {jobInfoList.map((item: any, i) => {
                        return (
                            <div className="cerinfo-card" key={`join-show-card-${i}`}>
                                <div className="r-left">
                                    {item.jobRoles}
                                    <i
                                        className="edit-icon marLeft"
                                        onClick={() => {
                                            getSkillOption({ search: '', code: item.jobTitleInfo?.code });
                                            setEditIndex(i);
                                            setRelevantExperience({ ...item });
                                            jonForm.setFieldsValue({ ...item });
                                        }}
                                    ></i>
                                    <i
                                        className="del-white-icon"
                                        style={{ marginLeft: 8 }}
                                        onClick={() => {
                                            jobInfoList.splice(i, 1);
                                            setJobInfoList([...jobInfoList]);
                                        }}
                                    ></i>
                                </div>
                                <p className="exp-tip">{item.occupationalType}</p>
                                <p className="exp-size11">
                                    {dayjs(new Date(item.startDate)).format('MMM YYYY')} -{' '}
                                    {item.currentEmployedHereFlag
                                        ? 'Currently Employed Here'
                                        : dayjs(new Date(item.endDate)).format('MMM YYYY')}
                                </p>
                                {item.skillsShow.length ? (
                                    <p className="exp-size12">Skills: {item.skillsShow.join(', ')}</p>
                                ) : null}
                            </div>
                        );
                    })}
                    <div className="r-left" style={{ marginTop: 32 }}>
                        <Button
                            className="r-primary_btn r-primary_largeBtn r-primaryNoBg7F_btn"
                            style={{ width: 208 }}
                            onClick={() => {
                                backNav();
                            }}
                        >
                            Back
                        </Button>
                        <Button
                            className={`r-primary_btn r-primary_largeBtn ${
                                !state.highestDegree || !state.major || !state.graduation ? 'disaledBox' : ''
                            }`}
                            style={{ width: 208, marginLeft: 10 }}
                            loading={loading}
                            onClick={() => continueClick()}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EducationInformation;
