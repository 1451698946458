import uploadApi from '@/api/upload';
import React, { useEffect, useState } from 'react';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { useS3Upload } from '@/hook/useS3Upload';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import VideoPlayModal from '@/components/modal/video-play-modal';
const getVideoThumbnailAndDuration = (file: File): Promise<{ thumbnail: string; duration: number }> => {
    return new Promise((resolve, reject) => {
        const videoElement = document.createElement('video');
        const url = URL.createObjectURL(file);

        videoElement.src = url;
        videoElement.addEventListener('loadedmetadata', () => {
            // 获取视频时长
            const duration = videoElement.duration;

            // 创建 canvas 来截取视频封面
            videoElement.currentTime = 2; // 选择截取1秒时的帧作为封面
            videoElement.addEventListener('seeked', () => {
                const canvas = document.createElement('canvas');
                // canvas.width = videoElement.videoWidth;
                // canvas.height = videoElement.videoHeight;
                // const ctx = canvas.getContext('2d');
                const targetWidth = 320;
                const scale = targetWidth / videoElement.videoWidth;
                canvas.width = targetWidth;
                canvas.height = videoElement.videoHeight * scale;
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                    const thumbnail = canvas.toDataURL('image/png', 0.8); // 获取封面图的base64数据
                    resolve({ thumbnail, duration });
                } else {
                    reject('Failed to get video thumbnail');
                }
                URL.revokeObjectURL(url); // 释放URL对象
            });
        });

        videoElement.onerror = () => {
            reject('Failed to load video');
        };
    });
};
function UploadVideo({ setVideoInfo, videoInfo, index, id, videoInfocover }: any) {
    const { uploadFileToS3, uploadProgress, uploadSuccess, uploadError, picUrl } = useS3Upload();
    const messageApi = useGlobalMessage();
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [thumbnail, setThumbnail] = useState<string | null>(null);
    const [avatar, setAvatar] = useState({
        url: '',
        key: '',
        base64: '',
        file: null,
        uploading: false,
        dialogVisible: false,
        warncontent: '',
    }) as any;
    const handleFileChange = (e: any) => {
        if (!e || avatar.uploading) {
            return;
        }
        const file = e.target.files[0].name.split('.');
        if (file[file.length - 1].toLowerCase() === 'mp4') {
            if (e.target.files[0].size > 500 * 1048576) {
                window.format.alert('error', 'Maximum video file size is 500mb');
                (document.getElementById(id) as any).value = null;
                return;
            }
            getVideoThumbnailAndDuration(e.target.files[0]).then(({ thumbnail, duration }) => {
                console.log(duration);
                // if (duration > 60) {
                //     messageApi.error('Video files only can 60s');
                //     return;
                // }
                setThumbnail(thumbnail);
                uploadFileToS3({ name: e.target.files[0].name, file: e.target.files[0] });
                (document.getElementById(id) as any).value = null;
                setAvatar(
                    Object.assign({}, avatar, {
                        url: '',
                        base64: '',
                        dialogVisible: false,
                        file: null,
                        uploading: true,
                    })
                );
            });
        } else {
            window.format.alert('error', 'Video files only support MP4 format');
            (document.getElementById(id) as any).value = null;
        }
    };
    useEffect(() => {
        if (uploadSuccess) {
            setAvatar(
                Object.assign({}, avatar, {
                    dialogVisible: false,
                    file: null,
                    base64: thumbnail,
                    uploading: false,
                })
            );
            setVideoInfo(picUrl, thumbnail);
        }
    }, [uploadSuccess]);
    useEffect(() => {
        if (uploadError) {
            setAvatar({
                url: '',
                key: '',
                base64: '',
                file: null,
                uploading: false,
                dialogVisible: false,
                warncontent: '',
                name: '',
            });
            messageApi.error(uploadError);
        }
    }, [uploadError]);
    useEffect(() => {
        if (!videoInfo) {
            setThumbnail('');
            setAvatar({
                url: '',
                key: '',
                base64: '',
                file: null,
                uploading: false,
                dialogVisible: false,
                warncontent: '',
                name: '',
            });
        } else {
            setAvatar({
                ...avatar,
                url: avatar.url ? avatar.url : videoInfo,
                base64: avatar.base64 ? avatar.base64 : videoInfocover,
            });
        }
    }, [videoInfo]);
    return (
        <>
            <div className="r-left uploadMain_box">
                {avatar.url || avatar.base64 ? (
                    <div
                        className="upload_imgBox upload_VideoBox r-center"
                        style={{
                            backgroundImage: `url(${avatar.base64 ? avatar.base64 : avatar.url})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                        }}
                    >
                        <i className="play-iconBtn" onClick={() => setIsVideoModalOpen(true)}></i>
                    </div>
                ) : (
                    <label
                        className="upload_imgBox upload_VideoBox"
                        htmlFor={id || 'videoInput'}
                        style={{
                            backgroundImage: `url(${avatar.base64 ? avatar.base64 : avatar.url})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                        }}
                    >
                        {avatar.base64 || avatar.url ? (
                            <></>
                        ) : (
                            <>
                                {!avatar.uploading ? (
                                    <div>
                                        <i className="uploadVideo-icon"></i>
                                        <p className="upload_tip">Upload Video</p>
                                        <p className="upload_tip" style={{ margin: 0 }}>
                                            (Max 60 Seconds)
                                        </p>
                                    </div>
                                ) : (
                                    <LoadingOutlined style={{ fontSize: 22 }} rev={undefined} />
                                )}
                            </>
                        )}
                        <input
                            type="file"
                            id={id || 'videoInput'}
                            onChange={e => handleFileChange(e)}
                            accept="video/*"
                            title=""
                        />
                    </label>
                )}
            </div>

            <VideoPlayModal
                videoUrl={avatar.url}
                isModalOpen={isVideoModalOpen}
                handleOk={() => setIsVideoModalOpen(false)}
                handleCancel={() => setIsVideoModalOpen(false)}
            />
        </>
    );
}

export default UploadVideo;
