import React from 'react';

function outlookCallback() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const error = urlParams.get('error');

    if (error) {
        window.opener.postMessage({ error, type: 'outlook' }, window.location.origin);
    } else if (code) {
        window.opener.postMessage({ code, type: 'outlook' }, window.location.origin);
    }
    window.close();
    return <div>outlookCallback-callback</div>;
}

export default outlookCallback;
