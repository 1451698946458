import { Button, Drawer, Image, Input } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import RadioButton from '@/assets/img/RadioButton.png';
import RadioButtonWhite from '@/assets/img/RadioButton-w.png';
import api from '@/api/job';
const { TextArea } = Input;
function ApplyJob({ open, onClose, val, onApply }: any) {
    const navigate = useNavigate();
    const [question, setQuestion] = useState<any>([]);
    const [oploading, setOpLoading] = useState(false);
    const confirmApply = () => {
        if (question.findIndex(v => !v.val) !== -1) {
            question.forEach(item => {
                if (!item.val) {
                    item.isError = true;
                } else {
                    item.isError = false;
                }
            });
            setQuestion([...question]);
            window.utils.alert('error', 'Please answer all screening questions.');
            return;
        }
        const pat = {
            applicationsScreeningQuestionsVOList: question.map(item => {
                return {
                    questionDescription: item.questionDescription,
                    questionTypes: item.questionTypes,
                    questionsId: item.questionsId,
                    questionsOptionsVOList: item.jobsScreeningQuestionsOptionsPOJOList.length
                        ? item.jobsScreeningQuestionsOptionsPOJOList.map(val => {
                              return {
                                  optionId: val.optionId,
                                  options: val.options,
                                  optionsValue: item.val,
                              };
                          })
                        : [
                              {
                                  optionId: '',
                                  options: '',
                                  optionsValue: item.val,
                              },
                          ],
                };
            }),
        };
        setOpLoading(true);
        api.applyJob({ jobId: val.jobsPOJO.jobId, ...pat }, res => {
            const { code, data, message } = res;
            setOpLoading(false);
            if (code === 200) {
                window.utils.alert('success', 'Success');
                onApply(data);
            } else {
                window.utils.alert('error', message);
            }
        });
    };
    useEffect(() => {
        if (open) {
            if (val.screeningQuestionsPOJOList.length) {
                const arr = val.screeningQuestionsPOJOList.map(item => {
                    return Object.assign(item, {
                        val: '',
                        isError: false,
                    });
                });
                setQuestion([...arr]);
            }
        }
    }, [open]);
    return (
        <div>
            <Drawer
                rootClassName="portFolioDrawer-box apply-drawer"
                placement={'bottom'}
                closable={false}
                keyboard={false}
                maskClosable={false}
                onClose={onClose}
                open={open}
                height="90%"
                key={'bottom'}
            >
                <i className="close-icon-drawer" onClick={() => onClose()}></i>
                <div className="r-left" style={{ marginBottom: 28 }}>
                    <span className="apply-titN">Apply</span>
                </div>
                <div className="r-left apply-infoBox">
                    <i
                        className="comp-logo"
                        style={{
                            backgroundImage: `url(${val.jobsPOJO.employerLogo})`,
                            backgroundSize: 'cover',
                        }}
                    ></i>
                    <div>
                        <p className="comp-tit">{val.jobsPOJO.jobTitle || '-'}</p>
                        <p className="comp-tip">{val.jobsPOJO.employerName || '-'}</p>
                    </div>
                </div>
                <div className="apply-Form">
                    {question.map((item, i) => {
                        return (
                            <div className="apply-cardBox" key={`apply-cardBox-${i}`}>
                                <p className="apply-formTip">
                                    {i + 1}. {item.questionDescription} ({item.questionTypes})
                                </p>
                                {item.questionTypes === 'Short Answer' ? (
                                    <TextArea
                                        className={`r-inputTextArea r-inputTextAreaNoBg r-input ${
                                            item.isError ? 'isError' : ''
                                        }`}
                                        maxLength={5000}
                                        autoComplete="off"
                                        autoSize={false}
                                        value={item.val}
                                        onInput={(e: any) => {
                                            const str = e.target.value;
                                            question[i].val = str;
                                            setQuestion([...question]);
                                        }}
                                        placeholder=""
                                    />
                                ) : (
                                    <div>
                                        {item.jobsScreeningQuestionsOptionsPOJOList.map((val, k) => {
                                            return (
                                                <div
                                                    className="r-left seclectItem"
                                                    key={`option-${i}-${k}`}
                                                    onClick={() => {
                                                        let arr: any = [];
                                                        if (item.questionTypes === 'Multiple Choice') {
                                                            if (!item.val.includes(val.optionId)) {
                                                                arr = item.val.split(',');
                                                                arr = arr.length === 1 && !arr[0] ? [] : arr;
                                                                arr.push(val.optionId);
                                                            } else {
                                                                arr = item.val.split(',');
                                                                arr.splice(
                                                                    arr.findIndex(v => v === val.optionId),
                                                                    1
                                                                );
                                                            }
                                                        } else {
                                                            arr.push(val.optionId);
                                                        }
                                                        console.log(arr);
                                                        question[i].val = arr.join(',');
                                                        setQuestion([...question]);
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            !item.val.includes(val.optionId)
                                                                ? RadioButtonWhite
                                                                : RadioButton
                                                        }
                                                        className="selected_icon"
                                                        alt=""
                                                    />
                                                    <span>{val.options}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    <div className="r-end">
                        <Button
                            className="r-primary_btn r-primary_largeBtn"
                            style={{ width: 200, marginTop: 20 }}
                            loading={oploading}
                            onClick={() => confirmApply()}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </Drawer>
        </div>
    );
}

export default ApplyJob;
