import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { useAppDispatch } from '@/store/hooks';
import api from '@/api/login';
import { setChartInfo, setProfileInfo } from '@/store/stateSlice';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { useState } from 'react';
import usePasswordEncrypt from '@/hook/usePasswordEncrypt';
function ResetSuccess() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const messageApi = useGlobalMessage();
    const [loading, setLoading] = useState(false);
    const { encryptPassword, contextHolder } = usePasswordEncrypt();
    const getSendBirdKey = async id => {
        try {
            const res = await api.getSendBirdKey();
            const { code, data, message } = res.data;
            if (code === 200) {
                dispatch(
                    setChartInfo({
                        adminId: id,
                        sendbirdId: data.APPLICATION_ID,
                        sendbirdAccessToken: data.API_TOKEN,
                    })
                );
            } else {
                window.utils.alert('error', message);
            }
        } catch (error) {
            window.utils.alert('error', error);
        }
    };
    const onFinish = () => {
        setLoading(true);
        api.login(
            {
                email: window.sessionStorage.getItem('email'),
                password: encryptPassword(window.sessionStorage.getItem('password') || ''),
            },
            loginRes => {
                setLoading(false);
                if (loginRes.code === 200) {
                    window.sessionStorage.removeItem('password');
                    window.sessionStorage.removeItem('email');
                    window.sessionStorage.setItem('admin-satoken', loginRes.data.token);
                    window.sessionStorage.setItem('accountId', loginRes.data.accountId);
                    window.sessionStorage.setItem('thirdPartyType', loginRes.data.thirdPartyType);
                    getSendBirdKey(loginRes.data.accountId);
                    if (
                        loginRes.data.accountInformationPOJO &&
                        loginRes.data.accountInformationPOJO.registerStepNumber !== 3
                    ) {
                        const step = loginRes.data.accountInformationPOJO.registerStepNumber;
                        navigate(
                            step === 0
                                ? '/persion-information'
                                : step === 1
                                ? '/skill-information'
                                : '/education-information'
                        );
                        return;
                    }
                    dispatch(setProfileInfo({ ...loginRes.data }));
                    navigate('/home');
                } else {
                    messageApi.error(loginRes.message);
                }
            }
        );
    };
    return (
        <div className="login-main">
            {contextHolder}
            <div className="nav-top r-left">
                <img className="left-logo" src={require('@/assets/img/talenaut_logo-color.png')} alt="" />
            </div>
            <div className="r-center login-formMin">
                <div className="center-box">
                    <div className="login-formBox">
                        <div className="r-center succ_tit">Reset password successfully</div>
                        <div className="r-center">
                            <img className="success_img" src={require('@/assets/img/success.png')} alt="" />
                        </div>
                        <Button style={{ margin: '60px 0 0 0' }} className="r-primary_btn" onClick={() => onFinish()}>
                            Continue
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetSuccess;
