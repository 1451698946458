import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AutoComplete, Button, DatePicker, Form, FormProps, Input, message, Select, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import UploadImg from '@/components/upload/upload-img';
import RCheckBox from '@/components/checkbox';
import RDrawer from '@/components/portfolio-drawer/r-drawer';
import RTabs from '@/views/login/components/tabs';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import api from '@/api/login';
import apiUser from '@/api/profile';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import LocationInp from '@/components/autoComplate-inp/locationInp';
import { defaultInfo } from '@/typings/allType';
import { useSelector } from 'react-redux';
import { useCountDown } from 'ahooks';
import VerificationCodeDialog from '@/components/modal/verification-code-dialog';
import FormModal from '@/components/modal/form-modal';
const disabledDate: RangePickerProps['disabledDate'] = current => {
    return current && current > dayjs().endOf('day');
};
function EditAccount({ isFormModalOpen, setIsFormModalOpen, profileInfo, GMTList, submitInfo }: any) {
    const store = useSelector((state: any) => state.state);
    const navigate = useNavigate();
    const messageApi = useGlobalMessage();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        profilePhoto: '',
        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        birth: '' as any,
        ethnicity: '',
        location: '',
        remote: false,
        gmt: '',
        preferred: 'Yes',
        isSelectFromList: true,
        preferredItem: 'He/They',
        isCustome: false,
        customer: '',
        address: '',
        languageList: [] as string[],
    });
    const [email, setEmail] = useState('');
    const [emailNew, setEmailNew] = useState('');
    const onFinish: FormProps['onFinish'] = values => {
        console.log('Success:', values);
        setLoading(true);
        const pat = {
            birth: dayjs(state.birth).format('YYYY-MM'),
            ethnicity: state.ethnicity,
            firstName: state.firstName,
            gender: state.gender,
            gmtId: state.gmt,
            lastName: state.lastName,
            location: state.address,
            middleName: state.middleName,
            preferredPronounsFlag: state.preferred === 'Yes' ? 1 : 0,
            preferredPronounsCustomFlag: state.isCustome ? 1 : 0,
            preferredPronounsCustom: state.customer,
            preferredPronounsListFlag: state.isSelectFromList ? 1 : 0,
            preferredPronounsList: state.preferredItem,
            profilePhoto: state.profilePhoto,
            remoteFlag: state.remote ? 1 : 0,
            languageProficientIn: state.languageList.join(','),
            jobRole: profileInfo.jobRole || '',
        };
        api.setPersionInformation(pat, res => {
            setLoading(false);
            if (res.code === 200) {
                submitInfo();
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const onFinishFailed: FormProps['onFinishFailed'] = errorInfo => {
        console.log('Failed:', errorInfo);
        // navigate('/skill-information');
    };
    const [form] = Form.useForm();
    const formRef = useRef<any>(null);
    useEffect(() => {
        if (profileInfo && profileInfo?.profilePhoto && isFormModalOpen) {
            const email =
                store.profileInfo.accountLoginType === 'email'
                    ? profileInfo.email
                    : profileInfo.emailTwo || 'test@gmail.com';
            setEmail(email);
            const initialValues = {
                profilePhoto: profileInfo?.profilePhoto,
                firstName: profileInfo.firstName,
                middleName: profileInfo.middleName,
                lastName: profileInfo.lastName,
                gender: profileInfo.gender,
                birth: dayjs(new Date(profileInfo.birth)),
                ethnicity: profileInfo.ethnicity,
                location: profileInfo.location,
                remote: profileInfo.remoteFlag === 1 ? true : false,
                gmt: profileInfo.gmtId,
                preferred: profileInfo.preferredPronounsFlag === 1 ? 'Yes' : 'No',
                isSelectFromList: profileInfo.preferredPronounsListFlag === 1 ? true : false,
                preferredItem: profileInfo.preferredPronounsList,
                isCustome: profileInfo.preferredPronounsCustomFlag === 1 ? true : false,
                customer: profileInfo.preferredPronounsCustom,
                address: profileInfo.location,
                languageList: profileInfo.languageProficientIn ? profileInfo.languageProficientIn.split(',') : [],
            };
            setState({ ...initialValues });
            form.setFieldsValue({ ...initialValues, email: email });
        }
    }, [isFormModalOpen]);
    const [languageVal, setLanguageVal] = useState('');
    const enter = e => {
        if (state.languageList.length >= 10) {
            messageApi.error('Add up to 10 proficient languages.');
            return;
        }
        if (e.keyCode === 13) {
            const val = e.target.value;
            if (!val) return;
            if (state.languageList.findIndex(v => v === val) !== -1) {
                messageApi.error('You have added this language.');
                return;
            }
            state.languageList.push(val);
            e.target.value = '';
            setLanguageVal('');
            setState({ ...state, languageList: state.languageList });
        }
    };

    // send eamil code
    const [isFormModalOpen1, setIsFormModalOpen1] = useState(false);
    const [loadingEmail, setLoadingEmail] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);
    const [eyeOpen, setEyeOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [seconds, setSeconds] = useState<number>();
    const [codeArr, setCodeArr] = useState(['', '', '', '', '', '']);
    const [countdown] = useCountDown({
        targetDate: seconds,
        onEnd: () => {
            setSeconds(0);
            setIsActive(false);
        },
    });
    const handleCancel = () => {
        setLoading(false);
        setLoadingEmail(false);
        setEmailNew('');
        setIsModalOpen(false);
    };
    const sendEditEmailCode = () => {
        if (!emailNew || !window.utils.isEmail(emailNew)) {
            messageApi.error('Please enter a valid email address');
            return;
        }
        if (seconds || isActive) {
            messageApi.error('The operation is too frequent. Please try again later.  60s');
            return;
        }
        setLoading(true);
        setSendLoading(true);
        apiUser.sendThirdLoginEamilCode({ email: emailNew }, res => {
            setSendLoading(false);
            setLoading(false);
            setIsFormModalOpen1(false);
            if (res.code === 200) {
                setIsModalOpen(true);
                setSeconds(Date.now() + 60000);
                setIsActive(true);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const verificationEmailCode = () => {
        setLoadingEmail(true);
        apiUser.checkThirdLoginEamilCode(
            {
                email: emailNew,
                code: codeArr.join(''),
            },
            res => {
                const { code, data } = res;
                if (code === 200 && !data) {
                    editEmail();
                } else {
                    window.utils.alert('error', res.message);
                    setLoadingEmail(false);
                }
            }
        );
    };
    const editEmail = () => {
        const pat = {
            email: emailNew,
            code: codeArr.join(''),
        };
        apiUser.editThirdLoginEamil(pat, res => {
            if (res.code === 200) {
                setIsModalOpen(false);
                setLoading(false);
                setEmail(emailNew);
                form.setFieldsValue({ email: emailNew });
                setEmailNew('');
            } else {
                setLoading(false);
                messageApi.error(res.message);
            }
        });
    };
    return (
        <div>
            <RDrawer
                open={isFormModalOpen}
                onClose={() => setIsFormModalOpen(false)}
                children={
                    <div className="curModal-box curModal-box-new" key={'curModal-box'}>
                        <div>
                            <div className="editInfo-tit">Edit Account</div>
                            <p className="editInfo-tip">
                                Please complete your personal information to search for new job opportunities.
                            </p>
                            <div className="pri-tit">General Information</div>
                            <div className="information-formBox">
                                <Form
                                    name="basic-persionInfo-editAcc"
                                    layout="vertical"
                                    initialValues={{ ...state }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    form={form}
                                    ref={formRef}
                                    autoComplete="off"
                                    validateTrigger="onBlur"
                                    onKeyDown={event => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();
                                        }
                                    }}
                                >
                                    <Form.Item
                                        label="Profile Photo"
                                        name="profilePhoto"
                                        rules={[{ required: true, message: 'Please upload photo' }]}
                                    >
                                        <UploadImg
                                            imgUrl={state?.profilePhoto}
                                            setPicInfo={(val: any) => {
                                                setState({ ...state, profilePhoto: val.picUrl });
                                                form.setFieldsValue({ profilePhoto: val.picUrl });
                                            }}
                                        />
                                    </Form.Item>
                                    <div className="r-left">
                                        <Form.Item
                                            className="flex1"
                                            label="First Name"
                                            name="firstName"
                                            rules={[{ required: true, message: 'Please enter a first name' }]}
                                        >
                                            <Input
                                                className="r-input"
                                                maxLength={20}
                                                value={state.firstName}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const str = e.target.value;
                                                    const val = str.charAt(0).toUpperCase() + str.slice(1);
                                                    setState({ ...state, firstName: val });
                                                    form.setFieldsValue({ firstName: val });
                                                }}
                                                placeholder=""
                                            />
                                        </Form.Item>
                                        <span className="space20"></span>
                                        <Form.Item
                                            className="flex1"
                                            label="Middle Name (optional)"
                                            name="middleName"
                                            rules={[{ required: false, message: '' }]}
                                        >
                                            <Input
                                                className="r-input"
                                                maxLength={20}
                                                value={state.middleName}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const str = e.target.value;
                                                    const val = str.charAt(0).toUpperCase() + str.slice(1);
                                                    setState({ ...state, middleName: val });
                                                    form.setFieldsValue({ middleName: val });
                                                }}
                                                placeholder=""
                                            />
                                        </Form.Item>
                                        <span className="space20"></span>
                                        <Form.Item
                                            className="flex1"
                                            label="Last Name"
                                            name="lastName"
                                            rules={[{ required: true, message: 'Please enter a last name' }]}
                                        >
                                            <Input
                                                className="r-input"
                                                maxLength={20}
                                                value={state.lastName}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const str = e.target.value;
                                                    const val = str.charAt(0).toUpperCase() + str.slice(1);
                                                    setState({ ...state, lastName: val });
                                                    form.setFieldsValue({ lastName: val });
                                                }}
                                                placeholder=""
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="r-left">
                                        <Form.Item
                                            label="Email Address"
                                            className="flex1"
                                            name="email"
                                            rules={[
                                                {
                                                    required: profileInfo.accountLoginType !== 'email',
                                                    message: 'Please enter a valid email address',
                                                },
                                                {
                                                    type: 'email',
                                                    message: 'Please enter a valid email address',
                                                },
                                            ]}
                                        >
                                            <Input className="r-input" value={email} disabled />
                                        </Form.Item>
                                        {store.profileInfo.accountLoginType === 'email' ? (
                                            ''
                                        ) : (
                                            <Button
                                                className="r-primaryNoBg_btn"
                                                style={{ width: 75, height: 44, margin: '6px 0 0 10px' }}
                                                onClick={() => setIsFormModalOpen1(true)}
                                            >
                                                Edit
                                            </Button>
                                        )}
                                    </div>
                                    <div className="r-left">
                                        <Form.Item
                                            className="flex1"
                                            label="Gender (optional)"
                                            name="gender"
                                            rules={[{ required: false, message: '' }]}
                                        >
                                            <Select
                                                className="r-select"
                                                value={state.gender}
                                                onChange={val => {
                                                    form.setFieldsValue({ gender: val });
                                                    setState({ ...state, gender: val });
                                                }}
                                                suffixIcon={<i className="down-right-icon"></i>}
                                                options={[
                                                    { value: 'Male', label: 'Male' },
                                                    { value: 'Female', label: 'Female' },
                                                    { value: 'Other', label: 'Other' },
                                                ]}
                                            />
                                        </Form.Item>
                                        <span className="space20"></span>
                                        <Form.Item
                                            className="flex1"
                                            label="Month/Year of Birth"
                                            name="birth"
                                            rules={[
                                                { required: true, message: 'Please select your Month/Year of Birth!' },
                                            ]}
                                        >
                                            <DatePicker
                                                className="r-datePicker w100"
                                                placeholder=" "
                                                suffixIcon={<i className="allow_time_icon"></i>}
                                                disabledDate={disabledDate}
                                                format="MMM YYYY"
                                                value={state.birth}
                                                onChange={val => {
                                                    form.setFieldsValue({ birth: val });
                                                    setState({ ...state, birth: val });
                                                }}
                                                picker="month"
                                            />
                                        </Form.Item>
                                    </div>
                                    <Form.Item
                                        label="Ethnicity (optional)"
                                        name="ethnicity"
                                        rules={[{ required: false, message: '' }]}
                                    >
                                        <Select
                                            className="r-select"
                                            suffixIcon={<i className="down-right-icon"></i>}
                                            value={state.ethnicity}
                                            onChange={val => {
                                                form.setFieldsValue({ ethnicity: val });
                                                setState({ ...state, ethnicity: val });
                                            }}
                                            options={[
                                                { value: 'White', label: 'White' },
                                                { value: 'Black', label: 'Black' },
                                                { value: 'Asian', label: 'Asian' },
                                                { value: 'Mixed', label: 'Mixed' },
                                                { value: 'Other', label: 'Other' },
                                            ]}
                                        />
                                    </Form.Item>
                                    <div className="r-left">
                                        <Form.Item
                                            className="flex1"
                                            label="Current Location/City"
                                            name="location"
                                            rules={[
                                                {
                                                    required: !state.remote,
                                                    message: 'Please enter your country and city',
                                                },
                                            ]}
                                        >
                                            <LocationInp
                                                setLocation={val => {
                                                    setState({ ...state, address: val.address });
                                                    form.setFieldsValue({ location: val.address });
                                                }}
                                                disabled={state.remote}
                                                placeholder=" "
                                                height={44}
                                                isFinshClear={false}
                                                value={state.address}
                                                suffixIcon={<i className="down-right-icon"></i>}
                                                classStr="r-autoComplate r-autoComplate-bg"
                                            />
                                        </Form.Item>
                                        <span className="space20"></span>
                                        <div className="r-left flex1">
                                            <div style={{ marginTop: 10 }}>
                                                <RCheckBox
                                                    checkInfo={{
                                                        isChecked: state.remote,
                                                        label: 'Remote',
                                                    }}
                                                    width="auto"
                                                    setCheckInfo={() => {
                                                        form.setFieldsValue({ location: '', gmt: '' });
                                                        setState({
                                                            ...state,
                                                            remote: !state.remote,
                                                            location: '',
                                                            gmt: '',
                                                        });
                                                        formRef.current.validateFields(['gmt', 'location']);
                                                    }}
                                                />
                                            </div>
                                            <span className="space20"></span>
                                            <Form.Item
                                                style={{ width: 305 }}
                                                label="GMT range"
                                                name="gmt"
                                                className="flex1 op5-itemLabel"
                                                rules={[
                                                    {
                                                        required: state.remote,
                                                        message: 'Please select a GMT time zone',
                                                    },
                                                ]}
                                            >
                                                <div className="r-left">
                                                    <Select
                                                        className="r-select"
                                                        disabled={!state.remote}
                                                        value={state.gmt ? state.gmt.toString() : ''}
                                                        onChange={val => {
                                                            form.setFieldsValue({ gmt: 'val' });
                                                            setState({ ...state, gmt: val });
                                                        }}
                                                        suffixIcon={<i className="down-right-icon"></i>}
                                                        options={GMTList}
                                                    />
                                                </div>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <Form.Item label="What language are you proficient in?" name="language">
                                        <Input
                                            className="r-input"
                                            placeholder="Start Typing..."
                                            value={languageVal}
                                            onInput={(e: any) => {
                                                setLanguageVal(e.target.value);
                                            }}
                                            onKeyUp={e => enter(e)}
                                        />
                                        <RTabs
                                            tabLists={state.languageList}
                                            onDelTab={(i: number) => {
                                                state.languageList.splice(i, 1);
                                                setState({ ...state });
                                            }}
                                        ></RTabs>
                                    </Form.Item>
                                    <Form.Item
                                        label="Preferred Pronouns"
                                        name="preferred"
                                        rules={[{ required: true, message: '' }]}
                                    >
                                        <Select
                                            className="r-select"
                                            value={state.preferred}
                                            onChange={val => {
                                                form.setFieldsValue({ preferred: val });
                                                setState({ ...state, preferred: val });
                                            }}
                                            suffixIcon={<i className="down-right-icon"></i>}
                                            options={[
                                                { value: 'Yes', label: 'Yes' },
                                                { value: 'No', label: 'No' },
                                            ]}
                                        />
                                    </Form.Item>
                                    {state.preferred === 'Yes' ? (
                                        <div className="r-left">
                                            <Form.Item
                                                label={null}
                                                name="preferredItem"
                                                className="flex1"
                                                style={{ margin: 0 }}
                                                rules={[
                                                    {
                                                        required: state.isSelectFromList && state.preferred === 'Yes',
                                                        message: 'Please enter a custom pronoun',
                                                    },
                                                ]}
                                            >
                                                <div className="r-left">
                                                    <RCheckBox
                                                        checkInfo={{
                                                            isChecked: state.isSelectFromList,
                                                            label: 'Select from a list',
                                                        }}
                                                        width={122}
                                                        setCheckInfo={() => {
                                                            form.setFieldsValue({
                                                                customer: '',
                                                                preferredItem: 'He/They',
                                                            });
                                                            setState({
                                                                ...state,
                                                                isSelectFromList: !state.isSelectFromList,
                                                                isCustome: false,
                                                                customer: '',
                                                            });
                                                            formRef.current.validateFields(['customer']);
                                                        }}
                                                    />
                                                    <span className="space20"></span>
                                                    <Select
                                                        className="r-select"
                                                        value={state.preferredItem}
                                                        onChange={val => {
                                                            form.setFieldsValue({ preferredItem: val });
                                                            setState({ ...state, preferredItem: val });
                                                        }}
                                                        suffixIcon={<i className="down-right-icon"></i>}
                                                        options={[
                                                            { value: 'He/They', label: 'He/They' },
                                                            { value: 'He/Him', label: 'He/Him' },
                                                            { value: 'They/He', label: 'They/He' },
                                                            { value: 'She/They', label: 'She/They' },
                                                            { value: 'She/Her', label: 'She/Her' },
                                                            { value: 'They/She', label: 'They/She' },
                                                            { value: 'They/Them', label: 'They/Them' },
                                                        ]}
                                                    />
                                                </div>
                                            </Form.Item>
                                            <span className="space20"></span>
                                            <div className="r-left flex1">
                                                <div>
                                                    <RCheckBox
                                                        checkInfo={{
                                                            isChecked: state.isCustome,
                                                            label: 'Custom',
                                                        }}
                                                        width={58}
                                                        setCheckInfo={() => {
                                                            form.setFieldsValue({
                                                                customer: '',
                                                                preferredItem: 'He/They',
                                                            });
                                                            setState({
                                                                ...state,
                                                                isCustome: !state.isCustome,
                                                                isSelectFromList: false,
                                                                customer: '',
                                                            });
                                                            formRef.current.validateFields(['customer']);
                                                        }}
                                                    />
                                                </div>
                                                <span className="space20"></span>
                                                <Form.Item
                                                    label={null}
                                                    name="customer"
                                                    className="flex1"
                                                    style={{ margin: 0 }}
                                                    rules={[
                                                        {
                                                            required: state.isCustome && state.preferred === 'Yes',
                                                            message: 'Please enter a custom pronoun',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className="r-input"
                                                        disabled={!state.isCustome}
                                                        value={state.customer}
                                                        onInput={(e: any) => {
                                                            form.setFieldsValue({ customer: e.target.value });
                                                            setState({ ...state, customer: e.target.value });
                                                        }}
                                                        maxLength={50}
                                                        placeholder=""
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    ) : null}
                                    <Form.Item style={{ margin: '30px 0 0 0' }}>
                                        <div className="r-end">
                                            <Button
                                                className="r-primary_btn r-primary_largeBtn"
                                                style={{ width: 208 }}
                                                htmlType="submit"
                                                loading={loading}
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                }
            />

            <VerificationCodeDialog
                setCodeArr={setCodeArr}
                sendForgetEmail={sendEditEmailCode}
                seconds={countdown}
                codeArr={codeArr}
                isActive={isActive}
                loading={loadingEmail}
                verificationEmailCode={verificationEmailCode}
                isModalOpen={isModalOpen}
                handleCancel={handleCancel}
            />

            <FormModal
                title="Edit email address"
                width={380}
                marBtm={10}
                isModalOpen={isFormModalOpen1}
                handleOk={() => setIsFormModalOpen1(false)}
                handleCancel={() => {
                    setIsFormModalOpen1(false);
                    setEmailNew('');
                }}
                children={
                    <div className="curModal-box" key={'curModal-box'}>
                        <p className="form-tip">New Email Address</p>
                        <Input
                            className="r-input"
                            value={emailNew}
                            onInput={(e: any) => {
                                setEmailNew(e.target.value);
                            }}
                            placeholder=""
                        />
                        <div className="r-center">
                            <Button
                                style={{ width: 106, marginTop: 40 }}
                                className="r-primary_btn r-primary_largeBtn"
                                loading={loading}
                                disabled={!emailNew}
                                onClick={() => sendEditEmailCode()}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                }
            />
        </div>
    );
}

export default EditAccount;
