import React, { Suspense, useState, useEffect } from 'react';
import { HashRouter, BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AxiosInterceptor } from './utils/index';
import { GoogleOAuthProvider } from '@react-oauth/google';
import auto from '@/utils/autofont';
import { MessageProvider } from '@/hook/useGlobalMessage';
import '@/assets/fonts/font.scss';
import routes from './router';
import { Spin } from 'antd';
import useLoading from './hook/useLoading';
import { SendBirdProvider } from './context/SendBirdProvider';
import { FeedbackProvider } from './context/notificationContext';
import config from '@/config/config';
const App = () => {
    const [device, setDevice] = useState('pc');
    const [showPage, setShowPage] = useState(true);
    const { mainLoading, setLoadingStatus } = useLoading();
    // const getDevice = () => {
    //     const clientWidth = document.documentElement.clientWidth;
    //     setDevice(clientWidth < 745 ? 'mb' : 'pc');
    //     dispatch(setDeviceType(clientWidth < 745 ? 'mb' : 'pc'));
    //     setTimeout(() => {
    //         setShowPage(true);
    //     }, 200);
    // };
    // window.onresize = function () {
    //     auto.autoFont();
    //     getDevice();
    // };
    // useEffect(() => {
    //     setLoadingStatus(false);
    //     auto.autoFont();
    //     getDevice();
    // }, []);
    const basename = process.env.REACT_APP_BASE_NAME === 'test' ? 'talenaut-candidate' : '';
    return (
        <SendBirdProvider>
            <MessageProvider>
                <FeedbackProvider>
                    <GoogleOAuthProvider clientId={config.googleAuthKey}>
                        <Router basename={process.env.REACT_APP_BASE_NAME}>
                            <AxiosInterceptor>
                                <Spin
                                    spinning={mainLoading}
                                    tip={''}
                                    style={{ zIndex: 9999 }}
                                    wrapperClassName={'fullLoading-box'}
                                >
                                    <Suspense>
                                        <Routes>
                                            {routes.map((route, index) => {
                                                return (
                                                    <Route
                                                        path={route.path}
                                                        key={index}
                                                        element={
                                                            <div
                                                                className={`${device}-main ${
                                                                    showPage ? 'show-page' : 'hidden-page'
                                                                }`}
                                                            >
                                                                <route.component
                                                                    routes={route.childrens ? route.childrens : []}
                                                                />
                                                            </div>
                                                        }
                                                    ></Route>
                                                );
                                            })}
                                        </Routes>
                                    </Suspense>
                                </Spin>
                            </AxiosInterceptor>
                        </Router>
                    </GoogleOAuthProvider>
                </FeedbackProvider>
            </MessageProvider>
        </SendBirdProvider>
    );
};

export default App;
