import axios from '../utils/index';
import { Pat } from '@/typings/request';
const jobApi = {
    applyJob(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/applications/applications-job/${data.jobId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getFavouriteJobList(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/favourite/favourite-job-list/${data.pageNo}/${data.pageSize}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addFavourite(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/favourite/favourite-job-add/${data.jobsId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    removeFavourite(data: Pat, callback: (T: any) => void) {
        axios
            .delete(`/v1/account/favourite/favourite-job-delete/${data.jobsId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getFavourite(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/account/favourite/favourite-get/${data.jobsId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getJobList(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/jobs-list/${data.pageNo}/${data.pageSize}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getJobInfo(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/account/jobs-info/${data.jobsId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getApplicationJobInfo(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/account/applications/applications-get/${data.applicationsId}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getApplicationJobList(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/applications/applications-list/${data.status}/${data.pageNo}/${data.pageSize}`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getLocationList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/locationiq`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getSearchLocationList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/applications/job/city-list`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getJobRoleList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/onetcenter/online/search`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getSkillList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/onetcenter/online/search/skills`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getJobApplicationList(params: Pat, callback: (T: any) => void) {
        axios
            .post(
                `/v1/account/applications/applicationInfo-list/${params.status}/${params.pageNo}/${params.pageSize}`,
                params
            )
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    addInterview(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/applications/${params.applicationsId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getReminderList(params: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/account/notification/reminder-list`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    closeReminder(params: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/account/notification/reminder-close/${params.applicationsId}`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};

export default jobApi;
