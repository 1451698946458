import axios from '../utils/index';
import { Pat } from '@/typings/request';
const loginApi = {
    getPublicKey(callback: (T: any) => void) {
        axios
            .get(`/v1/public/rsa/get-publicKey`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    async getSendBirdKey() {
        return axios.get(`/v1/public/sendbird/get-sendbird`);
    },
    getGmtList(callback: (T: any) => void) {
        axios
            .get(`/v1/public/gmt/gmt-list`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    login(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/account-login`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    loginWithThirdParty(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/account-login-thirdParty`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    logOut(callback: (T: any) => void) {
        axios
            .post(`/v1/account/account-logout`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    sendEmailCode(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/account-forgotPassword-getSessionId`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    verifyEmailCode(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/account-forgotPassword-checkSessionId`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    resetPassword(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/account-password-reset`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    signUp(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/account-signUp`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    signUpSendCode(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/account-signUp-send-email`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    signUpCheckCode(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/account-signUp-checkCode`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    setPersionInformation(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/account/profile-personalInformation`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    setPersionSkillInformation(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/account/profile-skillInformation`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    setPersionExperience(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/account/profile-experience`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },

    getJobRoleList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/onetcenter/online/search`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getSkillList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/onetcenter/online/search/skills`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getLocationList(params: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/locationiq`, params)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};

export default loginApi;
