import React, { useEffect, useState } from 'react';
import { Button, Divider, Form, FormProps, Input, message, Modal } from 'antd';
import VerificationCodeDialog from '@/components/modal/verification-code-dialog';
import { useNavigate } from 'react-router-dom';
import GoogleIcon from '@/assets/img/Google.png';
import LinInIcon from '@/assets/img/linin.png';
import { useCountDown } from 'ahooks';
import api from '@/api/login';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import usePasswordEncrypt from '@/hook/usePasswordEncrypt';
import { setChartInfo, setProfileInfo } from '@/store/stateSlice';
import { useAppDispatch } from '@/store/hooks';
import UseLinkeinAuth from '@/hook/useLinkeinAuth';
import { useGoogleLogin } from '@react-oauth/google';
import useLoading from '@/hook/useLoading';
function SignUp() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { encryptPassword, contextHolder } = usePasswordEncrypt();
    const messageApi = useGlobalMessage();
    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);
    const [eyeOpen, setEyeOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [state, setState] = useState({
        email: '',
        password: '',
        rePassword: '',
    });
    const [seconds, setSeconds] = useState<number>();
    const [codeArr, setCodeArr] = useState(['', '', '', '', '', '']);
    const [countdown] = useCountDown({
        targetDate: seconds,
        onEnd: () => {
            setSeconds(0);
            setIsActive(false);
        },
    });
    const sendSignEmail = () => {
        if (seconds || isActive) {
            messageApi.error('The operation is too frequent. Please try again later.  60s');
            return;
        }
        setSendLoading(true);
        api.signUpSendCode({ email: state.email }, res => {
            setSendLoading(false);
            if (res.code === 200) {
                setIsModalOpen(true);
                setSeconds(Date.now() + 60000);
                setIsActive(true);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const verificationEmailCode = () => {
        setLoading(true);
        api.signUpCheckCode(
            {
                email: state.email,
                code: codeArr.join(''),
            },
            res => {
                const { code, data } = res;
                if (code === 200) {
                    signAcc();
                } else {
                    window.utils.alert('error', res.message);
                    setLoading(false);
                }
            }
        );
    };
    const dispatch = useAppDispatch();
    const signAcc = () => {
        const pat = {
            code: codeArr.join(''),
            email: state.email,
            password: encryptPassword(state.password),
        };
        api.signUp(pat, loginRes => {
            setLoading(false);
            if (loginRes.code === 200) {
                setCodeArr(['', '', '', '', '', '']);
                window.sessionStorage.setItem('code', codeArr.join(''));
                window.sessionStorage.setItem('email', state.email);
                setIsModalOpen(false);
                window.sessionStorage.setItem('admin-satoken', loginRes.data.token);
                window.sessionStorage.setItem('accountId', loginRes.data.accountId);
                window.sessionStorage.setItem('thirdPartyType', 'email');
                getSendBirdKey(loginRes.data.accountId);
                if (
                    loginRes.data.accountInformationPOJO &&
                    loginRes.data.accountInformationPOJO.registerStepNumber !== 3
                ) {
                    const step = loginRes.data.accountInformationPOJO.registerStepNumber;
                    navigate(step === 0 ? '/persion-information' : step === 1 ? '/skill-information' : '');
                    return;
                }
                dispatch(setProfileInfo({ ...loginRes.data }));
                navigate('/persion-information');
            } else {
                messageApi.error(loginRes.message);
            }
        });
    };
    const getSendBirdKey = async id => {
        try {
            const res = await api.getSendBirdKey();
            const { code, data, message } = res.data;
            if (code === 200) {
                dispatch(
                    setChartInfo({
                        adminId: id,
                        sendbirdId: data.APPLICATION_ID,
                        sendbirdAccessToken: data.API_TOKEN,
                    })
                );
            } else {
                window.utils.alert('error', message);
            }
        } catch (error) {
            window.utils.alert('error', error);
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onFinish: FormProps['onFinish'] = values => {
        if (state.password !== state.rePassword) {
            message.error('The 2 passwords entered are not the same');
            setState({ ...state, password: '', rePassword: '' });
            form.setFieldsValue({ password: '', rePassword: '' });
            return;
        }
        sendSignEmail();
    };
    const validatePassword = (_: any, value: string) => {
        const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z\d]{8,12}$/;
        if (!value || !passwordPattern.test(value)) {
            return Promise.reject(
                new Error(
                    'Please enter a password of 8-12, which must contain one uppercase letter, one lowercase letter, and one number.'
                )
            );
        }
        return Promise.resolve();
    };
    const { setLoadingStatus } = useLoading();
    // linkeid 登录
    const { handleLogin, code } = UseLinkeinAuth();
    useEffect(() => {
        if (code) {
            loginWithThird({ thirdPartyId: code, thirdPartyType: 'linkedin' });
        }
    }, [code]);
    // 谷歌登录
    const loginWithThird = pat => {
        setLoadingStatus(true);
        api.loginWithThirdParty(pat, res => {
            setLoadingStatus(false);
            if (res.code === 200) {
                console.log(res);
                window.sessionStorage.setItem('persionInfo', JSON.stringify(res.data.accountInformationPOJO));
                window.sessionStorage.setItem('admin-satoken', res.data.token);
                window.sessionStorage.setItem('accountId', res.data.accountId);
                window.sessionStorage.setItem('thirdPartyType', res.data.thirdPartyType);
                getSendBirdKey(res.data.accountId);
                if (res.data.accountInformationPOJO && res.data.accountInformationPOJO.registerStepNumber !== 3) {
                    const step = res.data.accountInformationPOJO.registerStepNumber;
                    navigate(
                        step === 0
                            ? '/persion-information'
                            : step === 1
                            ? '/skill-information'
                            : '/education-information'
                    );
                    return;
                }
                dispatch(setProfileInfo({ ...res.data }));
                navigate('/home');
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const googleLogin = useGoogleLogin({
        // flow: 'auth-code',
        onSuccess: async tokenResponse => {
            console.log(tokenResponse);
            loginWithThird({ thirdPartyId: tokenResponse.access_token, thirdPartyType: 'google' });
        },
    });
    useEffect(() => {
        window.sessionStorage.clear();
    }, []);
    return (
        <div className="login-main">
            {contextHolder}
            <div className="nav-top r-left">
                <img className="left-logo" src={require('@/assets/img/talenaut_logo-color.png')} alt="" />
            </div>
            <div className="login-formMin r-center">
                <div className="center-box">
                    <img className="logo-img" src={require('@/assets/img/talenaut_logo-color.png')} alt="" />
                    <div className="login-formBox">
                        <Form
                            form={form}
                            name="basic-sign"
                            layout="vertical"
                            style={{ maxWidth: 380 }}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            autoComplete="off"
                            validateTrigger="onBlur"
                        >
                            <Form.Item
                                label="Email Address"
                                name="username"
                                rules={[
                                    { required: true, message: 'Please enter a valid email address' },
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email address',
                                    },
                                ]}
                            >
                                <Input
                                    className="r-input"
                                    value={state.email}
                                    onInput={(e: any) => setState({ ...state, email: e.target.value })}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Create A Password"
                                name="password"
                                rules={[{ validator: validatePassword }]}
                            >
                                <Input
                                    className={`r-input `}
                                    maxLength={12}
                                    autoComplete="off"
                                    placeholder={'Enter 8-12 digit password'}
                                    value={state.password}
                                    onInput={(e: any) => setState({ ...state, password: e.target.value })}
                                    type={eyeOpen ? 'text' : 'password'}
                                    suffix={
                                        eyeOpen ? (
                                            <i className="eye-open" onClick={() => setEyeOpen(false)}></i>
                                        ) : (
                                            <i className="eye-close" onClick={() => setEyeOpen(true)}></i>
                                        )
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Confirm Password"
                                name="rePassword"
                                rules={[{ validator: validatePassword }]}
                            >
                                <Input
                                    className={`r-input `}
                                    maxLength={12}
                                    autoComplete="off"
                                    placeholder={'Re-enter the 8-12 digit password'}
                                    value={state.rePassword}
                                    onInput={(e: any) => setState({ ...state, rePassword: e.target.value })}
                                    type={eyeOpen ? 'text' : 'password'}
                                    suffix={
                                        eyeOpen ? (
                                            <i className="eye-open" onClick={() => setEyeOpen(false)}></i>
                                        ) : (
                                            <i className="eye-close" onClick={() => setEyeOpen(true)}></i>
                                        )
                                    }
                                />
                            </Form.Item>
                            <Form.Item style={{ marginTop: 30 }}>
                                <Button
                                    className="r-primary_btn"
                                    htmlType="submit"
                                    loading={sendLoading}
                                    disabled={!state.email || !state.password || !state.rePassword}
                                >
                                    Sign Up
                                </Button>
                            </Form.Item>
                        </Form>
                        <Divider className="r-divider" plain>
                            OR
                        </Divider>
                        <div className="other-login">
                            <img src={LinInIcon} className="linin-icon" alt="" onClick={() => handleLogin()} />
                            <img src={GoogleIcon} className="google-icon" alt="" onClick={() => googleLogin()} />
                        </div>
                        <div className="r-center btm-tip">
                            Already have an account?
                            <div className="forgetBox" style={{ margin: '0 0 0 8px' }}>
                                <span onClick={() => navigate('/login')}>Log In</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <VerificationCodeDialog
                setCodeArr={setCodeArr}
                sendForgetEmail={sendSignEmail}
                seconds={countdown}
                codeArr={codeArr}
                isActive={isActive}
                loading={loading}
                verificationEmailCode={verificationEmailCode}
                isModalOpen={isModalOpen}
                handleCancel={handleCancel}
            />
        </div>
    );
}

export default SignUp;
