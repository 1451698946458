import React from 'react';
import SignUp from '@/views/login/signup';
import Login from '@/views/login/login';
import ResetPassword from '@/views/login/resetPassword';
import Success from '@/views/login/success';
import PersionInformation from '@/views/login/persionInformation';
import UserProfile from '@/views/login/userprofile';
import JobInformation from '@/views/login/job-information';
import Index from '@/views/pages/index';
import Home from '@/views/pages/home';
import SkillInformation from '@/views/login/skillInformation';
import EducationInformation from '@/views/login/educationInformation';
import JobDetail from '@/views/pages/home/job-detail';
import MyApplications from '@/views/pages/myApplications';
import Message from '@/views/pages/message/message';
import SavedJob from '@/views/pages/savedJob';
import ContactUs from '@/views/pages/contactUs';
import NotificationList from '@/views/pages/notification';
import Profile from '@/views/pages/userProfile';
import AuthCallback from '@/views/auth/AuthCallback';
import LinkedinCallback from '@/views/auth/linkedinCallback';
import OutlookCallback from '@/views/auth/outlookCallback';
import GoogleCallback from '@/views/auth/googleCallback';
export interface RouteConfigDeclaration {
    path: string;
    name?: string;
    exact?: boolean;
    auth?: boolean;
    isRedirect?: boolean;
    isDynamic?: boolean;
    loadingFallback?: string;
    component: any;
    childrens?: RouteConfigDeclaration[];
    isShowOnNav?: boolean;
    KeepAlive?: boolean;
    icon?: any;
    key?: number;
}
const routes: RouteConfigDeclaration[] = [
    {
        path: '',
        component: Login,
        exact: true,
        auth: false,
    },
    {
        path: '/',
        component: Login,
        exact: true,
        auth: false,
    },
    {
        path: '/login',
        component: Login,
        exact: true,
        auth: false,
    },
    {
        path: '/auth-callback',
        component: AuthCallback,
        exact: true,
        auth: false,
    },
    {
        path: '/auth-linkedin-callback',
        component: LinkedinCallback,
        exact: true,
        auth: false,
    },
    {
        path: '/auth-outlook-callback',
        component: OutlookCallback,
        exact: true,
        auth: false,
    },
    {
        path: '/auth-google-callback',
        component: GoogleCallback,
        exact: true,
        auth: false,
    },
    {
        path: '/sign-up',
        component: SignUp,
        exact: true,
        auth: false,
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        exact: true,
        auth: false,
    },
    {
        path: '/reset-success',
        component: Success,
        exact: true,
        auth: false,
    },
    {
        path: '/persion-information',
        component: PersionInformation,
        exact: true,
        auth: false,
    },
    {
        path: '/skill-information',
        component: SkillInformation,
        exact: true,
        auth: false,
    },
    {
        path: '/education-information',
        component: EducationInformation,
        exact: true,
        auth: false,
    },
    {
        path: '/userprofile',
        component: UserProfile,
        exact: true,
        auth: false,
    },
    {
        path: '/jobinformation',
        component: JobInformation,
        exact: true,
        auth: false,
    },
    {
        path: '/*',
        component: Index,
        exact: false,
        childrens: [
            {
                name: 'Home',
                path: '/home',
                component: Home,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                key: 0,
            },
            {
                name: 'JobDetail',
                path: '/job-detail',
                component: JobDetail,
                exact: true,
                isShowOnNav: false,
                KeepAlive: false,
                key: 0,
            },
            {
                name: 'My Applications',
                path: '/my-applications',
                component: MyApplications,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                key: 1,
            },
            {
                name: 'Message',
                path: '/message',
                component: Message,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                key: 2,
            },
            {
                name: 'Saved Job',
                path: '/saved-job',
                component: SavedJob,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                key: 3,
            },
            {
                name: 'Contact Us',
                path: '/contact-us',
                component: ContactUs,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
                key: 3,
            },
            {
                name: 'NotificationList',
                path: '/notification',
                component: NotificationList,
                exact: true,
                isShowOnNav: false,
                KeepAlive: false,
                key: -1,
            },
            {
                name: 'Profile',
                path: '/profile',
                component: Profile,
                exact: true,
                isShowOnNav: false,
                KeepAlive: false,
                key: -1,
            },
        ],
    },
];

export default routes;
