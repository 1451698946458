import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider, Form, FormProps, Input, message, Modal } from 'antd';
import VerificationCodeDialog from '@/components/modal/verification-code-dialog';
import { useLocation, useNavigate } from 'react-router-dom';
import GoogleIcon from '@/assets/img/Google.png';
import LinInIcon from '@/assets/img/linin.png';
import { useGoogleLogin } from '@react-oauth/google';
import UseLinkeinAuth from '@/hook/useLinkeinAuth';
import api from '@/api/login';
import JSEncrypt from 'jsencrypt';
import useLoading from '@/hook/useLoading';
import { useCountDown } from 'ahooks';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import usePasswordEncrypt from '@/hook/usePasswordEncrypt';
import { setProfileInfo, setChartInfo, setUserStatus } from '@/store/stateSlice';
import { useAppDispatch } from '@/store/hooks';
import AlertModal from '@/components/modal/alert-modal';
import { useSelector } from 'react-redux';
function Login() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { userStatus, userStatusTip } = useSelector((state: any) => state.state);
    const { encryptPassword, contextHolder } = usePasswordEncrypt();
    const messageApi = useGlobalMessage();
    const { setLoadingStatus } = useLoading();
    const [loading, setLoading] = useState(false);
    const [eyeOpen, setEyeOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [oldEmail, setOldEmail] = useState('');
    const [jobId, setJobId] = useState('');
    const [state, setState] = useState({
        email: '',
        password: '',
    });
    const [seconds, setSeconds] = useState<number>();
    const [codeArr, setCodeArr] = useState(['', '', '', '', '', '']);
    const [countdown] = useCountDown({
        targetDate: seconds,
        onEnd: () => {
            setSeconds(0);
            setIsActive(false);
        },
    });
    const sendForgetEmail = () => {
        if (seconds || isActive) return;
        api.sendEmailCode({ email: state.email }, res => {
            if (res.code === 200) {
                setOldEmail(state.email);
                setIsModalOpen(true);
                setSeconds(Date.now() + 60000);
                setIsActive(true);
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const verificationEmailCode = () => {
        console.log(codeArr);
        api.verifyEmailCode(
            {
                email: state.email,
                code: codeArr.join(''),
            },
            res => {
                const { code, data } = res;
                if (code === 200) {
                    setCodeArr(['', '', '', '', '', '']);
                    handleCancel();
                    window.sessionStorage.setItem('code', codeArr.join(''));
                    window.sessionStorage.setItem('email', state.email);
                    navigate('/reset-password');
                } else {
                    window.utils.alert('error', res.message);
                }
            }
        );
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onFinish: FormProps['onFinish'] = values => {
        setLoading(true);
        api.login({ email: state.email, password: encryptPassword(state.password) }, loginRes => {
            console.log('====================================');
            console.log(loginRes);
            console.log('====================================');
            setLoading(false);
            if (loginRes.code === 200) {
                window.sessionStorage.setItem('admin-satoken', loginRes.data.token);
                window.sessionStorage.setItem('accountId', loginRes.data.accountId);
                window.sessionStorage.setItem('thirdPartyType', loginRes.data.thirdPartyType);
                getSendBirdKey(loginRes.data.accountId);
                if (
                    loginRes.data.accountInformationPOJO &&
                    loginRes.data.accountInformationPOJO.registerStepNumber !== 3
                ) {
                    const step = loginRes.data.accountInformationPOJO.registerStepNumber;
                    navigate(
                        step === 0
                            ? '/persion-information'
                            : step === 1
                            ? '/skill-information'
                            : '/education-information'
                    );
                    return;
                }
                dispatch(setProfileInfo({ ...loginRes.data }));
                if (jobId) {
                    // 分享工作进来
                    navigate('/job-detail');
                    return;
                }
                navigate('/home');
            } else {
                messageApi.error(loginRes.message);
            }
        });
    };
    const { handleLogin, code } = UseLinkeinAuth();
    useEffect(() => {
        if (code) {
            loginWithThird({ thirdPartyId: code, thirdPartyType: 'linkedin' });
        }
    }, [code]);
    const loginWithThird = pat => {
        setLoadingStatus(true);
        api.loginWithThirdParty(pat, res => {
            setLoadingStatus(false);
            if (res.code === 200) {
                console.log(res);
                window.sessionStorage.setItem('persionInfo', JSON.stringify(res.data.accountInformationPOJO));
                window.sessionStorage.setItem('admin-satoken', res.data.token);
                window.sessionStorage.setItem('accountId', res.data.accountId);
                window.sessionStorage.setItem('thirdPartyType', res.data.thirdPartyType);
                getSendBirdKey(res.data.accountId);
                if (res.data.accountInformationPOJO && res.data.accountInformationPOJO.registerStepNumber !== 3) {
                    const step = res.data.accountInformationPOJO.registerStepNumber;
                    navigate(
                        step === 0
                            ? '/persion-information'
                            : step === 1
                            ? '/skill-information'
                            : '/education-information'
                    );
                    return;
                }
                dispatch(setProfileInfo({ ...res.data }));
                if (jobId) {
                    // 分享工作进来
                    navigate('/job-detail');
                    return;
                }
                navigate('/home');
            } else {
                messageApi.error(res.message);
            }
        });
    };
    // 谷歌登录
    const googleLogin = useGoogleLogin({
        // flow: 'auth-code',
        onSuccess: async tokenResponse => {
            console.log(tokenResponse);
            loginWithThird({ thirdPartyId: tokenResponse.access_token, thirdPartyType: 'google' });
        },
    });
    const getSendBirdKey = async id => {
        try {
            const res = await api.getSendBirdKey();
            const { code, data, message } = res.data;
            if (code === 200) {
                dispatch(
                    setChartInfo({
                        adminId: id,
                        sendbirdId: data.APPLICATION_ID,
                        sendbirdAccessToken: data.API_TOKEN,
                    })
                );
            } else {
                window.utils.alert('error', message);
            }
        } catch (error) {
            window.utils.alert('error', error);
        }
    };
    const validatePassword = (_: any, value: string) => {
        const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[A-Za-z\d]{8,12}$/;
        if (!value || !passwordPattern.test(value)) {
            return Promise.reject(
                new Error(
                    'Please enter a password of 8-12, which must contain one uppercase letter, one lowercase letter, and one number.'
                )
            );
        }
        return Promise.resolve();
    };
    const location = useLocation();
    useEffect(() => {
        setLoadingStatus(false);
        const params = new URLSearchParams(location.search);
        const id = params.get('jobId');
        if (!id) {
            window.sessionStorage.clear();
        } else {
            setJobId(id);
        }
    }, []);
    // 禁用弹窗
    const [isModalOpen1, setIsModalOpen1] = useState(false);
    const statusTip = {
        2: `Your account has been suspended. Please contact admin@talenaut.com`,
        4: `Your account has been deleted. Please contact admin@talenaut.com`,
    };
    const handleOk = () => {
        setIsModalOpen1(false);
        dispatch(setUserStatus(1));
        navigate('/login');
    };
    useEffect(() => {
        if (userStatus !== 1) {
            setIsModalOpen1(true);
        }
    }, [userStatus]);
    return (
        <div className="login-main">
            {contextHolder}
            <div className="nav-top r-left">
                <img className="left-logo" src={require('@/assets/img/talenaut_logo-color.png')} alt="" />
            </div>
            <div className="login-formMin r-center">
                <div className="center-box">
                    <img className="logo-img" src={require('@/assets/img/talenaut_logo-color.png')} alt="" />
                    <div className="login-formBox">
                        <Form
                            name="basic-login"
                            layout="vertical"
                            style={{ maxWidth: 380 }}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            autoComplete="off"
                            validateTrigger="onBlur"
                        >
                            <Form.Item
                                label="Email Address"
                                name="username"
                                rules={[
                                    { required: true, message: 'Please enter a valid email address' },
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email address',
                                    },
                                ]}
                            >
                                <Input
                                    className="r-input"
                                    value={state.email}
                                    onInput={(e: any) => setState({ ...state, email: e.target.value })}
                                />
                            </Form.Item>
                            <Form.Item label="Password" name="password" rules={[{ validator: validatePassword }]}>
                                <Input
                                    className={`r-input `}
                                    maxLength={12}
                                    autoComplete="off"
                                    placeholder={' '}
                                    value={state.password}
                                    onInput={(e: any) => setState({ ...state, password: e.target.value })}
                                    type={eyeOpen ? 'text' : 'password'}
                                    suffix={
                                        eyeOpen ? (
                                            <i className="eye-open" onClick={() => setEyeOpen(false)}></i>
                                        ) : (
                                            <i className="eye-close" onClick={() => setEyeOpen(true)}></i>
                                        )
                                    }
                                />
                            </Form.Item>
                            <Form.Item>
                                <div className="forgetBox">
                                    <span
                                        onClick={() => {
                                            if (!state.email || !window.utils.isEmail(state.email)) {
                                                messageApi.error('Please enter a valid email address');
                                                return;
                                            }
                                            if (oldEmail !== state.email || !isActive) {
                                                sendForgetEmail();
                                            } else {
                                                messageApi.error(
                                                    'The operation is too frequent. Please try again later.  60s'
                                                );
                                            }
                                        }}
                                    >
                                        Forgot password?
                                    </span>
                                </div>
                                <Button
                                    className="r-primary_btn"
                                    htmlType="submit"
                                    loading={loading}
                                    disabled={!state.email || !state.password}
                                >
                                    Log In
                                </Button>
                            </Form.Item>
                        </Form>
                        <Divider className="r-divider" plain>
                            OR
                        </Divider>
                        <div className="other-login">
                            <img src={LinInIcon} className="linin-icon" alt="" onClick={() => handleLogin()} />
                            <img src={GoogleIcon} className="google-icon" alt="" onClick={() => googleLogin()} />
                        </div>
                        <div className="r-center btm-tip">
                            Don't have an account yet?
                            <div className="forgetBox" style={{ margin: '0 0 0 8px' }}>
                                <span onClick={() => navigate('/sign-up')}>Sign Up</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <VerificationCodeDialog
                setCodeArr={setCodeArr}
                sendForgetEmail={sendForgetEmail}
                seconds={countdown}
                codeArr={codeArr}
                isActive={isActive}
                verificationEmailCode={verificationEmailCode}
                isModalOpen={isModalOpen}
                handleCancel={handleCancel}
            />
            {/* del modal */}
            <AlertModal
                message={userStatusTip || ''}
                cancelText=""
                confirmText="Continue"
                isModalOpen={isModalOpen1}
                handleOk={handleOk}
            />
        </div>
    );
}

export default Login;
