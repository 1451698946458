import { Drawer } from 'antd';
import React from 'react';

function RDrawer({ open, onClose, children }: any) {
    return (
        <div>
            <Drawer
                rootClassName="portFolioDrawer-box opeation-drawer"
                placement={'bottom'}
                closable={false}
                keyboard={false}
                maskClosable={false}
                onClose={onClose}
                open={open}
                height="91%"
                key={'bottom'}
            >
                <i className="close-icon-drawer" onClick={() => onClose()}></i>
                <div>{children}</div>
            </Drawer>
        </div>
    );
}

export default RDrawer;
