import { message } from 'antd';
import React, { createContext, useContext, useState } from 'react';

const MessageContext = createContext<any>(null);

export const MessageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const enhancedMessageApi = {
        ...messageApi,
        error: (msg: string) => {
            // 如果 msg 为空，直接不执行
            if (!msg) return;
            messageApi.error(msg);
        },
        success: (msg: string) => {
            if (!msg) return;
            messageApi.success(msg);
        },
        info: (msg: string) => {
            if (!msg) return;
            messageApi.info(msg);
        },
        warning: (msg: string) => {
            if (!msg) return;
            messageApi.warning(msg);
        },
    };
    return (
        <MessageContext.Provider value={enhancedMessageApi}>
            {contextHolder}
            {children}
        </MessageContext.Provider>
    );
};

export const useGlobalMessage = () => {
    return useContext(MessageContext);
};
