import React, { forwardRef } from 'react';
import { Button, message, Modal } from 'antd';
import CodeInput from '../code-input/index';
import { useNavigate } from 'react-router-dom';
const VerificationCodeDialog = forwardRef(
    (
        {
            isModalOpen,
            handleCancel,
            isActive,
            codeArr,
            setCodeArr,
            verificationEmailCode,
            seconds,
            sendForgetEmail,
            loading,
        }: any,
        ref
    ) => {
        const navigate = useNavigate();
        return (
            <Modal
                className="r-modal modal_no_padding"
                maskClosable={false}
                destroyOnClose
                centered={true}
                closeIcon={<i className="r-modal-close"></i>}
                footer={''}
                width={380}
                title={
                    <div className="modal-top-title" style={{ marginBottom: 30 }}>
                        Verification Code
                    </div>
                }
                open={isModalOpen}
                onCancel={handleCancel}
                maskProps={{
                    style: {
                        backgroundColor: 'rgba(0,0,0, 0.8)',
                        backdropFilter: 'blur(1px)',
                    },
                }}
            >
                <p className="mini-tip">Please enter the 6 digit Verification Code sent to your email address.</p>
                <div style={{ margin: '20px 0 30px 0' }}>
                    {isModalOpen ? <CodeInput setCodeArr={setCodeArr} key="verification-login-page" /> : null}
                </div>
                <Button
                    className="r-primary_btn"
                    disabled={codeArr.findIndex(v => !v) !== -1}
                    onClick={() => verificationEmailCode()}
                    loading={loading ? loading : false}
                >
                    Continue
                </Button>
                <div className={`reset-text ${isActive ? 'disabled' : ''}`}>
                    {isActive ? (
                        <>Resend Code ({Math.round(seconds / 1000)}s)</>
                    ) : (
                        <span onClick={() => sendForgetEmail()}>Resend</span>
                    )}
                </div>
            </Modal>
        );
    }
);

export default VerificationCodeDialog;
