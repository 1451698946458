import { useEffect, useState, useRef } from 'react';

const useInfiniteScroll = (loadMore, loading) => {
    const loaderRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && !loading) {
                loadMore();
            }
        });

        if (loaderRef.current) {
            observer.observe(loaderRef.current);
        }

        return () => {
            if (loaderRef.current) {
                observer.unobserve(loaderRef.current);
            }
        };
    }, [loaderRef, loading, loadMore]);

    return loaderRef;
};

export default useInfiniteScroll;
