import React from 'react';
import * as echarts from 'echarts';

function UseInitChart() {
    const initChart = (id: string, userActivityTimeList: any) => {
        const myChart = echarts.init(document.getElementById(id ? id : 'round-chart') as HTMLElement, '', {
            devicePixelRatio: 2,
        });
        const color = [
            {
                // 圆环颜色
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                    {
                        offset: 0,
                        color: '#f4f4fd',
                    },
                    {
                        offset: 1,
                        color: '#f4f4fd',
                    },
                ],
            },
            {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                    {
                        offset: 0,
                        color: '#9490F1',
                    },
                    {
                        offset: 1,
                        color: '#9490F1',
                    },
                ],
            },
        ];
        let seriesOption: any = [];
        let num = 0;
        seriesOption = userActivityTimeList.map((item, index) => {
            num += item.value;
            const a = {
                type: 'bar',
                name: item.name,
                value: item.value,
                data: [num === 100 ? 100 : num], // 前面的0，累计还是0
                coordinateSystem: 'polar',
                z: 10 - index,
                roundCap: true,
                color: color[index + 1],
                barGap: '-100%',
                itemStyle: {
                    normal: {
                        shadowBlur: 0,
                    },
                },
                emphasis: {
                    disabled: true,
                },
            };
            return a;
        });
        const option = {
            color: color,
            tooltip: {
                show: false,
            },
            angleAxis: {
                clockwise: true, // 刻度增长是否按顺时针，
                axisLine: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                min: 1,
                max: 100,
                startAngle: 90,
            },
            radiusAxis: {
                type: 'category',
                axisLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
            },
            polar: {
                center: ['50%', '50%'],
                radius: [55, '100%'],
            },
            toolbox: {
                show: false,
            },
            series: [...seriesOption],
        };

        (document.getElementById(id ? id : 'round-chart') as HTMLElement).removeAttribute('_echarts_instance_');
        myChart.setOption(option as any);
        window.addEventListener('resize', function () {
            myChart.resize();
        });
    };
    return initChart;
}

export default UseInitChart;
