import React, { useCallback, useEffect, useState } from 'react';
import './index.scss';
import { Button, Input, Spin } from 'antd';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import api from '@/api/job';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import ShareModal from '../home/components/shareModal';
import useInfiniteScroll from '@/hook/useInfiniteScroll';
const Index = () => {
    const navigate = useNavigate();
    const { messageApi } = useGlobalMessage();
    const [isFormModalOpen, setIsFormModalOpen] = useState(false);
    const [state, setState] = useState({
        search: '',
        pageSize: 20,
        pageNo: 1,
    });
    const [opJob, setOpJob] = useState<any>({
        jobsPOJO: {
            jobTitle: '',
            jobDescription: '',
            employerName: '',
        },
    });
    const [oploading, setOpLoading] = useState(false);
    const opeationJob = (type: number, val: any, i: number) => {
        setOpJob(val);
        if (type === 1) {
            window.sessionStorage.setItem('jobInfo', JSON.stringify({ ...val, from: 'home' }));
            navigate('/job-detail');
        } else if (type === 2) {
            jobList[i].opLoading = true;
            setJobList([...jobList]);
            api.removeFavourite({ jobsId: val.jobId }, res => {
                const { code, data, message } = res;
                setOpLoading(false);
                if (code === 200) {
                    window.utils.alert('success', 'Success');
                    jobList[i].opLoading = false;
                    setJobList([]);
                    getList({ ...state, pageNo: 1 });
                } else {
                    window.utils.alert('error', message);
                }
            });
        } else {
            setIsFormModalOpen(true);
        }
    };
    const searchList: any = useCallback(
        debounce(params => {
            setJobList([]);
            getList({ ...params });
        }, 1000),
        []
    );
    const [jobList, setJobList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const loadMoreItems = () => {
        if (loading || state.pageNo >= total) return;
        setLoading(true);
        getList({ ...state, pageNo: state.pageNo + 1 });
        setState({ ...state, pageNo: state.pageNo + 1 });
    };
    const loaderRef = useInfiniteScroll(loadMoreItems, loading);
    const [noMore, setNoMore] = useState(false);
    useEffect(() => {
        setNoMore(state.pageNo >= total);
    }, [state.pageNo]);
    const [total, setTotal] = useState(0);
    const [listLoading, setListLoading] = useState(false);
    const getList = val => {
        setListLoading(true);
        const pat = {
            pageNo: val.pageNo,
            pageSize: val.pageSize,
            keyWord: val.search,
        };
        api.getFavouriteJobList(pat, res => {
            setListLoading(false);
            const { code, data, message } = res;
            if (code === 200) {
                setLoading(false);
                setTotal(res.data.totalPage);
                if (res.data.list) {
                    setJobList(prevItems => [
                        ...prevItems,
                        ...res.data.list.map(item => {
                            return Object.assign(item, {
                                opLoading: false,
                                pushTimeShow: item.jobsPOJO.publishTime
                                    ? window.utils.foramtTimeDMY(item.jobsPOJO.publishTime * 1000)
                                    : '-',
                                jobTypeShow:
                                    item.jobsPOJO.jobType === '1'
                                        ? 'Full-Time'
                                        : item.jobsPOJO.jobType === '2'
                                        ? 'Part-Time'
                                        : item.jobsPOJO.jobType === '3'
                                        ? 'Contractor'
                                        : 'Full-Time',
                            });
                        }),
                    ]);
                } else {
                    setJobList([]);
                }
            } else {
                window.utils.alert('error', message);
            }
        });
    };
    useEffect(() => {
        getList({ ...state });
    }, []);
    return (
        <div className="savedJOb-mainBox">
            <div className="page-contentBox r-top">
                <div className="job-cardListBox flex1">
                    <Input
                        placeholder=""
                        style={{ flex: 1 }}
                        allowClear
                        value={state.search}
                        onChange={e => {
                            searchList({ ...state, search: e.target.value, pageNo: 1 });
                            setState({ ...state, search: e.target.value, pageNo: 1 });
                        }}
                        className="r-input r-input_serach"
                        prefix={<span className="search_icon"></span>}
                    />
                    <div className="job-listBox">
                        {listLoading ? (
                            <>
                                <div className="r-center no-message-tabBox">
                                    <Spin style={{ position: 'static' }}></Spin>
                                </div>
                            </>
                        ) : jobList.length ? (
                            <>
                                {jobList.map((val, i) => {
                                    return (
                                        <div key={`jobCard-item-${i}`} className="job-cardItem r-top">
                                            <div className="job-infoLeft">
                                                <p className="job-tit">{val.jobsPOJO?.jobTitle || '-'}</p>
                                                <div className="r-left mini-tipBox-job">
                                                    <i
                                                        className="jonInfo-icon"
                                                        style={{
                                                            backgroundImage: `url(${val.jobsPOJO.employerLogo})`,
                                                            backgroundSize: 'cover',
                                                        }}
                                                    ></i>
                                                    <span className="job-info-tip">
                                                        {val.jobsPOJO.employerName || '-'}
                                                    </span>
                                                    <i className="round-spaceBox">•</i>
                                                    <span className="job-info-tip">
                                                        {val.jobsPOJO.remoteFlag
                                                            ? 'Remote'
                                                            : val.jobsPOJO.location
                                                            ? val.jobsPOJO.location
                                                            : 'London, UK'}
                                                    </span>
                                                    <i className="round-spaceBox">•</i>
                                                    <span className="job-info-tip">
                                                        {val.jobTypeShow || 'Full-Time'}
                                                    </span>
                                                    <i className="round-spaceBox">•</i>
                                                    <span className="job-info-tip">{val.pushTimeShow}</span>
                                                    <i className="round-spaceBox">•</i>
                                                    <span className="job-info-tip primary-color">
                                                        Match - {val.matchScore || 0}%
                                                    </span>
                                                </div>
                                                <p className="skill-tit">Skills Required</p>
                                                <div className="skill-tip oneLineText">
                                                    {val.hardSkillPOJOList && val.hardSkillPOJOList.length
                                                        ? val.hardSkillPOJOList
                                                              .map(v => {
                                                                  return v.skillName;
                                                              })
                                                              .join(', ')
                                                        : '-'}
                                                </div>
                                            </div>
                                            <div className="job-opRight marLeft">
                                                <Button
                                                    className="r-primary_btn"
                                                    onClick={() => opeationJob(1, val, i)}
                                                >
                                                    View Details
                                                </Button>
                                                <Button
                                                    className="r-primary_btn r-primaryNoBg_btn"
                                                    loading={val.opLoading}
                                                    onClick={() => opeationJob(2, val, i)}
                                                >
                                                    Remove
                                                </Button>
                                                <Button
                                                    className="r-primary_btn r-primaryNoBg_btn"
                                                    onClick={() => opeationJob(3, val, i)}
                                                >
                                                    Share
                                                    <i className="share-icon"></i>
                                                </Button>
                                            </div>
                                        </div>
                                    );
                                })}
                                {loading && (
                                    <div className="r-center">
                                        <Spin style={{ position: 'static' }}></Spin>
                                    </div>
                                )}
                                <div ref={loaderRef} style={{ height: '20px' }} />
                            </>
                        ) : (
                            <div className="r-center no-message-tabBox">No data</div>
                        )}
                    </div>
                </div>
            </div>

            <ShareModal isFormModalOpen={isFormModalOpen} val={opJob} setIsFormModalOpen={setIsFormModalOpen} />
        </div>
    );
};

export default Index;
