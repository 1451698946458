import React, { useRef, useState } from 'react';
import { Button, Form, FormProps, Input, Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import TextArea from 'antd/es/input/TextArea';
import api from '@/api/feedback';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { useSelector } from 'react-redux';
function PersionInformation() {
    const { profileInfo } = useSelector((state: any) => state.state);
    const messageApi = useGlobalMessage();
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        firstName: profileInfo.accountInformationPOJO.firstName || '',
        middleName: profileInfo.accountInformationPOJO.middleName || '',
        lastName: profileInfo.accountInformationPOJO.lastName || '',
        email:
            profileInfo.accountLoginType === 'email'
                ? profileInfo.accountInformationPOJO.email
                : profileInfo.accountInformationPOJO.emailTwo || 'account@test.com',
        mobileNumber: '',
        feedbackType: 'Feedback',
        feedback: '',
    });
    const [form] = Form.useForm();
    const formRef = useRef<any>(null);
    const onFinish: FormProps['onFinish'] = values => {
        if (loading) return;
        setLoading(true);
        api.sendFeedBack({ ...state }, res => {
            setLoading(false);
            if (res.code === 200) {
                messageApi.success('Submit successfully');
                const initialValues = {
                    ...state,
                    feedbackType: 'Feedback',
                    feedback: '',
                };
                setState(initialValues);
                form.setFieldsValue(initialValues);
            } else {
                messageApi.error(res.message);
            }
        });
    };

    const onFinishFailed: FormProps['onFinishFailed'] = errorInfo => {
        console.log('Failed:', errorInfo);
        setIsError(true);
    };
    return (
        <div className="information-main contact-usMain">
            <div className="login-formMin r-center">
                <div className="informationCenter-box">
                    <div className="info-tit" style={{ marginBottom: 32 }}>
                        Contact Us
                    </div>
                    <div className="information-formBox">
                        <Form
                            name="basic-feedback-form"
                            layout="vertical"
                            initialValues={{ ...state }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            form={form}
                            ref={formRef}
                            autoComplete="off"
                            validateTrigger="onBlur"
                        >
                            <div className="r-left">
                                <Form.Item
                                    className="flex1"
                                    label="First Name"
                                    name="firstName"
                                    rules={[{ required: true, message: 'Please enter a first name' }]}
                                >
                                    <Input
                                        className="r-input"
                                        maxLength={20}
                                        value={state.firstName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const str = e.target.value;
                                            const val = str.charAt(0).toUpperCase() + str.slice(1);
                                            setState({ ...state, firstName: val });
                                            form.setFieldsValue({ firstName: val });
                                        }}
                                        placeholder=""
                                    />
                                </Form.Item>
                                <span className="space20"></span>
                                <Form.Item
                                    className="flex1"
                                    label="Middle Name (optional)"
                                    name="middleName"
                                    rules={[{ required: false, message: '' }]}
                                >
                                    <Input
                                        className="r-input"
                                        maxLength={20}
                                        value={state.middleName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const str = e.target.value;
                                            const val = str.charAt(0).toUpperCase() + str.slice(1);
                                            setState({ ...state, middleName: val });
                                            form.setFieldsValue({ middleName: val });
                                        }}
                                        placeholder=""
                                    />
                                </Form.Item>
                                <span className="space20"></span>
                                <Form.Item
                                    className="flex1"
                                    label="Last Name"
                                    name="lastName"
                                    rules={[{ required: true, message: 'Please enter a last name' }]}
                                >
                                    <Input
                                        className="r-input"
                                        maxLength={20}
                                        value={state.lastName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const str = e.target.value;
                                            const val = str.charAt(0).toUpperCase() + str.slice(1);
                                            setState({ ...state, lastName: val });
                                            form.setFieldsValue({ lastName: val });
                                        }}
                                        placeholder=""
                                    />
                                </Form.Item>
                            </div>
                            <div className="r-left">
                                <Form.Item
                                    className="flex1"
                                    label="Email Address"
                                    name="email"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Input className="r-input" disabled value={state.email} placeholder="" />
                                </Form.Item>
                                <span className="space20"></span>
                                <Form.Item
                                    className="flex1"
                                    label="Contact Number (optional)"
                                    name="mobileNumber"
                                    rules={[{ required: false, message: ' ' }]}
                                >
                                    <Input
                                        className="r-input"
                                        placeholder=""
                                        value={state.mobileNumber}
                                        onChange={(e: any) => {
                                            let inputValue = e.target.value;
                                            inputValue = inputValue.replace(/[^0-9]/g, '');
                                            setState({ ...state, mobileNumber: inputValue });
                                            form.setFieldsValue({ mobileNumber: inputValue });
                                        }}
                                        maxLength={30}
                                    />
                                </Form.Item>
                            </div>
                            <Form.Item
                                className="flex1"
                                label="Reason For Contact"
                                name={'feedbackType'}
                                rules={[{ required: true, message: '' }]}
                            >
                                <Select
                                    className="r-select"
                                    suffixIcon={<i className="down-right-icon"></i>}
                                    value={state.feedbackType}
                                    onChange={val => {
                                        form.setFieldsValue({ feedbackType: val });
                                        setState({ ...state, feedbackType: val });
                                    }}
                                    options={[
                                        { value: 'Feedback', label: 'Feedback' },
                                        { value: 'Other', label: 'Other' },
                                    ]}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Reason For Contact"
                                name="feedback"
                                rules={[{ required: true, message: 'Please enter content' }]}
                            >
                                <div className={`textArea-box`}>
                                    <TextArea
                                        className={`r-inputTextArea r-input`}
                                        maxLength={500}
                                        autoComplete="off"
                                        autoSize={false}
                                        value={state.feedback}
                                        onInput={(e: any) => {
                                            const inputValue = e.target.value;
                                            setState({ ...state, feedback: inputValue });
                                            form.setFieldsValue({ feedback: inputValue });
                                        }}
                                        placeholder="Start Typing..."
                                    />
                                    <span className="text-count">{state.feedback.length}/500</span>
                                </div>
                            </Form.Item>
                            <Form.Item style={{ margin: '30px 0 0 0' }}>
                                <Button
                                    className={`r-primary_btn r-primary_largeBtn ${
                                        !state.firstName || !state.lastName || !state.feedback ? 'disabledBox' : ''
                                    }`}
                                    style={{ width: 208 }}
                                    htmlType="submit"
                                    loading={loading}
                                >
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PersionInformation;
