import React, { useEffect, useState } from 'react';
import { Image, Spin } from 'antd';
import './userprofile.scss';
import UserImg1 from '@/assets/img/user1.png';
import UserImg3 from '@/assets/img/user3.png';
import UserImg4 from '@/assets/img/user4.png';
import UserImg5 from '@/assets/img/user5.png';
import Check from '@/assets/img/candidate-img/check-succ.png';
import LinkIconImg from '@/assets/img/user-link.png';
import LicenseIconImg from '@/assets/img/candidate-img/license.png';
import VideoPlayModal from '@/components/modal/video-play-modal';
import PortFolioDrawer from '@/components/portfolio-drawer';
import api from '@/api/profile';
import { cloneDeep } from 'lodash';
import useLoading from '@/hook/useLoading';
import { useGlobalMessage } from '@/hook/useGlobalMessage';
import { useLocation, useParams } from 'react-router-dom';
function Userprofile() {
    const messageApi = useGlobalMessage();
    const [loading, setLoading] = useState(false);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [state, setState] = useState({
        lininCheck: false,
        videoList: [
            { tit: 'Self Introduction', url: '', cover: '' },
            { tit: 'Key Skills and Experience ', url: '', cover: '' },
            { tit: 'Key Personal Strength', url: '', cover: '' },
        ],
        profilePhoto: '',
        email: '',
        emailTwo: '',
        mobileNumber: '',
        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        birth: '',
        ethnicity: '',
        location: '',
        remoteFlag: 1,
        preferredPronounsFlag: 1,
        preferredPronounsListFlag: 1,
        preferredPronounsList: 'He/They',
        preferredPronounsCustomFlag: 0,
        preferredPronounsCustom: '',
        gmt: '',
        gmtId: '',
        personalName: '',
        personalQrcode: '',
        jobRole: '',
        languageProficientIn: '',
        aboutMe: '',
        selfIntroductionUrl: '',
        keySkillsExperiencesUrl: '',
        keyPersonalStrengthUrl: '',
        selfIntroductionUrlImage: '',
        keySkillsExperiencesUrlImage: '',
        keyPersonalStrengthUrlImage: '',
        cvFileSize: '',
        cvFileUrl: '',
        cvFileName: '',
        graduation: '',
        highestDegree: '',
        major: '',
        yearsOfExperience: 0,
        registerStepNumber: 3,
    });
    const [videoUrl, setVideoUrl] = useState('');
    const [accountProjectPOJOList, setAccountProjectPOJOList] = useState<any>([]);
    const [projectInfo, setProjectInfo] = useState<any>({});
    const [accountCertificatePOJOList, setAccountCertificatePOJOList] = useState<any>([]);
    const [relevantExperience, setRelevantExperience] = useState<any>([]);
    const [relevantExperienceTime, setRelevantExperienceTime] = useState<string>('');
    const [skillList, setSkillList] = useState<any>([]);
    const [softSkillList, setSoftSkillList] = useState<any>([]);
    const getUserInfo = userId => {
        api.getPublicProfileInfo({ userId: userId }, res => {
            setLoading(false);
            if (res.code === 200) {
                const data = res.data;
                if (data.accountInformationPOJO && data.accountInformationPOJO !== null) {
                    const val = cloneDeep(data.accountInformationPOJO);
                    state.videoList[0].url = val.selfIntroductionUrl;
                    state.videoList[0].cover = val.selfIntroductionUrlImage;
                    state.videoList[1].url = val.keySkillsExperiencesUrl;
                    state.videoList[1].cover = val.keySkillsExperiencesUrlImage;
                    state.videoList[2].url = val.keyPersonalStrengthUrl;
                    state.videoList[2].cover = val.keyPersonalStrengthUrlImage;
                    setState({ ...state, ...val });
                }
                if (data.accountProjectPOJOList && data.accountProjectPOJOList !== null) {
                    setAccountProjectPOJOList([...data.accountProjectPOJOList]);
                }
                if (data.accountSkillPOJOList && data.accountSkillPOJOList !== null) {
                    const skillList: any = [];
                    const softSkillList: any = [];
                    data.accountSkillPOJOList.forEach(item => {
                        if (item.skillType === 'softSkills') {
                            softSkillList.push(item);
                        } else {
                            skillList.push(item);
                        }
                        setSkillList([...skillList]);
                        setSoftSkillList([...softSkillList]);
                    });
                }
                if (data.accountExperiencePOJO && data.accountExperiencePOJO !== null) {
                    const year = data.accountExperiencePOJO.yearsOfExperience;
                    const month = data.accountExperiencePOJO.monthOfExperience;
                    const time = `${year ? `${year}${year > 1 ? 'Years' : 'Year'}` : ''}${
                        month ? ` ${month}${month > 1 ? ' Months' : ' Month'}` : ''
                    }`;
                    setRelevantExperienceTime(time);
                    setRelevantExperience([...data.accountExperiencePOJO.accountExperienceInfoPOJOList]);
                }
                if (data.accountCertificatePOJOList && data.accountCertificatePOJOList !== null) {
                    const val = cloneDeep(data.accountCertificatePOJOList);
                    setAccountCertificatePOJOList(val);
                }
            } else {
                messageApi.error(res.message);
            }
        });
    };
    const location = useLocation();
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const id = params.get('id');
        // const jobId = location.jobId;
        setLoading(true);
        getUserInfo(id);
    }, []);
    return (
        <div className="login-main information-main">
            <div className="nav-top r-left">
                <img className="left-logo" src={require('@/assets/img/talenaut_logo-color.png')} alt="" />
            </div>
            {loading ? (
                <div className="r-center" style={{ padding: 40 }}>
                    <Spin style={{ position: 'static' }} />
                </div>
            ) : (
                <div className="userprofile-content-share">
                    <div className="profile-card pmarb20">
                        <div className="r-top">
                            <div>
                                <Image
                                    className="user-img"
                                    preview={false}
                                    src={state.profilePhoto ?? ''}
                                    fallback={require('@/assets/img/default-min.png')}
                                />
                            </div>
                            <div className="user-infoName">
                                {state.firstName ?? '-'} {state.middleName} {state.lastName}
                                {state.preferredPronounsFlag
                                    ? `(${
                                          state.preferredPronounsCustomFlag
                                              ? state.preferredPronounsCustom
                                              : state.preferredPronounsList
                                      })`
                                    : ''}
                                <p className="fz14" style={{ marginTop: 4 }}>
                                    {state.jobRole ? state.jobRole : '-'}
                                </p>
                            </div>
                        </div>
                        <div className="profile-iconInfo r-left">
                            <div>
                                <div className="r-left img-fbox">
                                    <img src={UserImg1} className="userImg-box" alt="" />
                                    {state.remoteFlag ? `Remote + ${state.gmt}` : state.location}
                                </div>
                                <div className="r-left img-fbox">
                                    <img src={UserImg3} className="userImg-box" alt="" />
                                    {state.gender ? state.gender : '-'}
                                </div>
                            </div>
                            <div style={{ marginLeft: 72 }}>
                                <div className="r-left img-fbox">
                                    <img src={UserImg4} className="userImg-box" alt="" />
                                    {state.languageProficientIn ? state.languageProficientIn : '-'}
                                </div>
                                <div className="r-left img-fbox">
                                    <img src={UserImg5} className="userImg-box" alt="" />
                                    {state.ethnicity ? state.ethnicity : '-'}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="candidateInfo-right">
                        <div className="candidateVideo-info">
                            <div className="video-infoTit break-text">About Me</div>
                            <div className="msg-tip break-text" style={{ marginTop: 20 }}>
                                {state.aboutMe ? state.aboutMe : '-'}
                            </div>
                        </div>
                        <div className="candidateVideo-info">
                            <div className="video-infoTit">Education</div>
                            <div className="msg-tip r-left" style={{ marginTop: 20 }}>
                                {state.graduation} - {state.major}
                            </div>
                        </div>
                        <div className="candidateVideo-info">
                            <div className="video-infoTit">Skills</div>
                            <div className="msg-tip r-left" style={{ marginTop: 20 }}>
                                <div className="bold-skillTit">Hard Skills: </div>
                                {skillList.length
                                    ? skillList.map((val, i) => (
                                          <span>{`${val.skillName}${i !== skillList.length - 1 ? ', ' : ''}`}</span>
                                      ))
                                    : '-'}
                            </div>
                            <div className="msg-tip r-left" style={{ marginTop: 20 }}>
                                <div className="bold-skillTit">Soft Skills: </div>
                                {softSkillList.length
                                    ? softSkillList.map((val, i) => (
                                          <span>{`${val.skillName}${i !== softSkillList.length - 1 ? ', ' : ''}`}</span>
                                      ))
                                    : '-'}
                            </div>
                        </div>
                        <div className="r-left">
                            {state.videoList.map((item, i) => {
                                return (
                                    <div key={`candidateVideo-profile-item$_${i}`} className="videoCard-box">
                                        <p className="video-tip">{item.tit}</p>
                                        <div
                                            className="videoCard-bg"
                                            style={{
                                                background: `url(${item.cover ? item.cover : ''})`,
                                                backgroundPosition: 'center center',
                                                backgroundSize: 'cover',
                                                backgroundColor: '#ccc',
                                            }}
                                            onClick={() => {
                                                setVideoUrl(item.url);
                                                setIsVideoModalOpen(true);
                                            }}
                                        ></div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="candidateVideo-info" style={{ padding: '24px 16px 8px 16px' }}>
                            <div className="video-infoTit r-left">
                                Relevant Experience
                                <span className="marLeft black-color">
                                    {relevantExperienceTime ? relevantExperienceTime : '-'}
                                </span>
                            </div>
                            <div className="r-left relevantExperienceList">
                                {relevantExperience.map((item, i) => {
                                    return (
                                        <div
                                            key={`relevantExperience-profile-item-${i}`}
                                            className={`relevantExperience-item ${
                                                item.occupationalType !== 'Freelancer' ? 'greenBg' : ''
                                            }`}
                                        >
                                            <div className="experie-tit r-left">
                                                {item.occupationalType !== 'Freelancer'
                                                    ? item.companyName
                                                    : 'Freelancer'}
                                                {item.occupationalType !== 'Freelancer' ? (
                                                    <img src={Check} className="jobCheck-icon" alt="" />
                                                ) : null}
                                            </div>
                                            <div className="experie-job">{item.jobRoles ? item.jobRoles : '-'}</div>
                                            <div className="experie-jobTime">
                                                {item.currentEmployedHereFlag === 1 ? (
                                                    'Currently Employed Here'
                                                ) : (
                                                    <>
                                                        {item.yearsOfExperience
                                                            ? `${item.yearsOfExperience} ${
                                                                  item.yearsOfExperience > 1 ? ' Years ' : ' Year '
                                                              }`
                                                            : ''}
                                                        {item.monthOfExperience
                                                            ? `${item.monthOfExperience} ${
                                                                  item.monthOfExperience > 1 ? ' Months' : ' month'
                                                              }`
                                                            : ''}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        {accountProjectPOJOList && accountProjectPOJOList.length ? (
                            <div className="candidateVideo-info">
                                <div className="video-infoTit">Portfolio</div>
                                <div className="r-left portList-box">
                                    {accountProjectPOJOList.map((item, i) => {
                                        return (
                                            <div
                                                className="portImg-box"
                                                key={item.projectId}
                                                style={{
                                                    border: '1px solid #ccc',
                                                    backgroundImage: `url(${item.coverImageUrl})`,
                                                    backgroundPosition: 'center center',
                                                }}
                                                onClick={() => {
                                                    setProjectInfo(item);
                                                    setOpenDrawer(true);
                                                }}
                                            ></div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : null}
                        <div className="candidateVideo-info">
                            <div className="video-infoTit">Licenses, Certifications and External Assessments</div>
                            <div>
                                {accountCertificatePOJOList.length ? (
                                    accountCertificatePOJOList.map((item, i) => {
                                        return (
                                            <div key={`licenseBox-msg_${i}`} className="r-top roleList-box">
                                                <img src={LicenseIconImg} className="roleImg" alt="" />
                                                <div>
                                                    <p className="msg-tit">
                                                        {item.certificateName ? item.certificateName : '-'}
                                                    </p>
                                                    <p className="msg-tip" style={{ marginTop: 8 }}>
                                                        {item.validPeriodFrom} - {item.validPeriodTo}
                                                    </p>
                                                </div>
                                                {item.certificateUrl ? (
                                                    <img
                                                        src={LinkIconImg}
                                                        className="roleImg marLeft"
                                                        style={{
                                                            width: 28,
                                                            height: 28,
                                                            marginRight: 0,
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() =>
                                                            window.open(`https://${item.certificateUrl}`, '_target')
                                                        }
                                                        alt=""
                                                    />
                                                ) : null}
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="profile-info-pri op5 r-center">Not yet</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <VideoPlayModal
                isModalOpen={isVideoModalOpen}
                videoUrl={videoUrl}
                handleOk={() => setIsVideoModalOpen(false)}
                handleCancel={() => setIsVideoModalOpen(false)}
            />
            <PortFolioDrawer open={openDrawer} onClose={() => setOpenDrawer(false)} projectInfo={projectInfo} />
        </div>
    );
}

export default Userprofile;
