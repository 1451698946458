import React from 'react';

function AuthCallback() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const error = urlParams.get('error');

    if (error) {
        window.opener.postMessage({ error }, window.location.origin);
    } else if (code) {
        window.opener.postMessage({ code }, window.location.origin);
    }
    window.close();
    return <div>auth-callback</div>;
}

export default AuthCallback;
