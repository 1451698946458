import axios from '../utils/index';
import { Pat } from '@/typings/request';
const feedbackApi = {
    sendFeedBack(data: Pat, callback: (T: any) => void) {
        axios
            .put(`/v1/account/account-feedback`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getUnReadCount(callback: (T: any) => void) {
        axios
            .get(`/v1/account/notification/notification-unReadCount`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    readAllCount(callback: (T: any) => void) {
        axios
            .put(`/v1/account/notification/notification-readAll`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    delNoticaion(data: Pat, callback: (T: any) => void) {
        axios
            .delete(`/v1/account/notification/notification-delete/${data.id}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getNoticationList(data: Pat, callback: (T: any) => void) {
        axios
            .post(`/v1/account/notification/notification-list/0/9999999999/${data.pageNo}/${data.pageSize}`)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
    getProfileInfo(data: Pat, callback: (T: any) => void) {
        axios
            .get(`/v1/account/profile-info`, data)
            .then(res => {
                callback(res.data);
            })
            .catch(err => {
                callback(err);
            });
    },
};

export default feedbackApi;
